import {cleanDigitsField} from "./cleanDigitsField";

function cleanPhoneNumber(number: string, removePrefix: boolean = false): string {
    if (!number) {
        return number
    }

    let clearestNumber = cleanDigitsField(number)

    if (removePrefix && clearestNumber[0] == "+") {
        clearestNumber = clearestNumber.slice(1)
    }

    if (clearestNumber[0] == "8") {
        clearestNumber = setCharAt(clearestNumber, 0, "7")
    }

    return clearestNumber
}

function setCharAt(str: string ,index: number, chr: string) {
    if(index > str.length-1) return str;
    return str.substring(0,index) + chr + str.substring(index+1);
}

export default cleanPhoneNumber
