import React, {ReactElement, useEffect, useState} from "react";
import classnames from "classnames";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {CopyToClipboard} from "react-copy-to-clipboard";

type Props = {
    children: ReactElement
    className: string,
    items: any[]
}

function MenuButton({ children, className, items}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const actionsOpen = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function makeItemView(item: any) {
        return <MenuItem
            onClick={(event) => {
                event.stopPropagation()
                handleClose()
                item.onTap()
            }}
        >
            <div className="buttonsContainer">
                {
                    item.icon
                }
                {
                    item.title
                }
            </div>
        </MenuItem>
    }

    return <div>
        <button className={className} onClick={handleClick}>
            {
                children
            }
        </button>
        <Menu
            anchorEl={anchorEl}
            open={actionsOpen}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {
                items.map((item: any) => {
                    if (!item) {
                        return
                    }
                    if (item.isCopyToClipboard == true) {
                        return <CopyToClipboard text={item.copyText}>
                            {
                                makeItemView(item)
                            }
                        </CopyToClipboard>
                    } else {
                        return makeItemView(item)
                    }
                })
            }
        </Menu>
    </div>
}

export default MenuButton