import {checkInlineEntities} from "./checkInlineEntities";
import {additionalValuesString} from "./workWithEntities";

export function addSpanTagsToBody(template: any, body: string, color: string = "#DE9B94"): string {
    template.entities.forEach((entity: any) => {
        body = addSpanTagToString(entity, body, color, template.entities)
    })
    template.system_entities.forEach((entity: any) => {
        body = addSpanTagToString(entity, body, color)
    })

    return body
}

export function addSpanTagToString(entity: any, body: string, color: string = "#DE9B94", otherEntities: any[] = []): string {
    if (!entity) {
        return body
    }
    let name = `<<${entity.keyword}>>`
    let kw = `{{${entity.keyword}}}`
    let nameProperty1 = `<span style="font-weight: bold; border: 1px solid ${color}; border-radius: 5px; page-break-inside: avoid; white-space: pre-wrap;" name="`
    let openTag = `${nameProperty1}${name}">`

    // console.log(body)
    // console.log('start', kw, nameProperty1, openTag)

    let offset = 0
    let index = body.indexOf(kw, offset)

    //console.log("body.slice(index - kw.length -2)", body.slice(index - kw.length - 2, index))
    while (index != -1) {
        if (body.slice(index - kw.length - 2, index) == `${name}">`) {
            offset = index + kw.length
            index = body.indexOf(kw, offset)
            // console.log("continue", kw, offset, index)
            continue
        }
        
        //console.log('offset', offset, 'index', index)

        let opt1Start = index - nameProperty1.length
        let opt2Start = index - openTag.length

        //console.log('opt1 indexes', opt1Start, nameProperty1.length)
        //console.log('opt2 indexes', opt2Start, openTag.length)

        let opt1 = body.substring(opt1Start, index)
        let opt2 = body.substring(opt2Start, index)

        // console.log('opt1', opt1)
        // console.log('opt2', opt2)

        if (opt1 != nameProperty1 && opt2 != openTag) {
            let prefix = body.slice(0, index)
            let keyword = body.slice(index, index+ kw.length)
            let value = entity.value?.toString() ?? ""
            let entityPostfix = entity.postfix ? entity.postfix : ""
            let valueToInsert = value.length == 0 ? keyword : (value + entityPostfix + additionalValuesString(entity))
            valueToInsert = checkInlineEntities(valueToInsert, kw, otherEntities)
            // console.log("valueToInsert", valueToInsert)
            let closeTag = "</span>"
            let suffix =  body.slice(index + kw.length)
            //console.log('prefix', prefix)
            //console.log('openTag', openTag)
            //console.log('added for keyword', keyword)
            //console.log('closeTag', closeTag)

            let space = ' '
            openTag = space + openTag
            closeTag = closeTag + space

            body = prefix + openTag + valueToInsert + closeTag + suffix
            offset = index + openTag.length + valueToInsert.length + closeTag.length
        } else {
            offset = index + kw.length
        }
        index = body.indexOf(kw, offset)
    }
    return body
}
