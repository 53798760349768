import {useRoute} from "react-router5";
import React, {useEffect, useRef, useState} from "react";
import {httpClient, nError} from "../../funcs";
import {validate, validateMany} from "../../validate";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import TemplateCell from "./TemplateCell";
import TemplatesHeader from "./TemplatesHeader";
import AddTemplatePopup from "./AddTemplatePopup";
import "pages/Styles/TemplatesCommon.sass"
import {PageLoader} from "../Contracts/ContractList/PageLoader";
import NewContractPopup from "../EditContract/NewContractPopup";
import WelcomeView from "../Profile/WelcomeView";
import {notifyAboutError} from "../../methods/notifyUser";
import {prepareForRegexp} from "../../methods/prepareForRegexp";

let TemplatesPage: any = function TemplatesPage(props: any) {
    const $router = useRoute();

    const [templates, setTemplates] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loadedAllTemplates, setLoadedAllTemplates] = useState<boolean | null>(null);
    const [pendingForLoadMoreTemplates, setPendingForLoadMoreTemplates] = useState(false);
    const [loading, setLoading] = useState(false);

    // Enable searching
    useEffect(() => {
        if (searchQuery && validate("searchQuery", searchQuery)) return;
        loadTemplates(true)
        setTemplates([])
        setLoadedAllTemplates(false)
    }, [searchQuery])

    // Enable searching
    useEffect(() => {
        loadTemplates()
    }, [])

    async function loadTemplates(dropOffset: boolean = false) {
        setPendingForLoadMoreTemplates(true);
        setLoading(true)
        try {
            const {data} = await httpClient.get(`/templates`, {
                params: {
                    offset: dropOffset ? 0 : templates.length,
                    ...(!validate("searchQuery", searchQuery)
                        ? {search_query: searchQuery}
                        : {}),
                },
            });

            if (!data.length) return setLoadedAllTemplates(true);

            let newTemplates = data.map((template: any) => ({
                ...template,
                created_at: new Date(template.created_at),
            }))
            setTemplates(
                dropOffset
                    ? newTemplates
                    : templates.concat(newTemplates)
            )
        } catch (error) {
            notifyAboutError(props, error)
            setLoadedAllTemplates(true)
        } finally {
            setPendingForLoadMoreTemplates(false);
            setLoading(false)
        }
    }


    // add new template

    const [newTemplatePopup, setNewTemplatePopup] = useState(false);
    function didAddTemplate(newTemplate: any) {
        setNewTemplatePopup(false)
        setTemplates([
            ...templates,
            {
                ...newTemplate,
                created_at: new Date(newTemplate.created_at),
            },
        ]);
        $router.router.navigate(
            "new-contract",
            {act: "new", templateId: newTemplate._id},
            {reload: true},
        );
    }

    // Enable infinite scrolling
    const [sentryRef] = useInfiniteScroll({
        loading: pendingForLoadMoreTemplates,
        hasNextPage: !loadedAllTemplates,
        onLoadMore: loadTemplates,
        disabled: !!loadedAllTemplates,
    });

    function makeList() {
        if (loading && templates.length == 0) {
            return <PageLoader />
        }

        if (loadedAllTemplates === true && templates.length == 0) {
            if (searchQuery) {
                return <div className="empty-list">
                    <div className="commonLabel">
                        По вашему запросу ничего не найдено
                    </div>
                </div>
            } else {
                return emptyPlaceholder()
            }
        }

        return <div className="contractList" key={"contractList"}>
            {templates.map((template, i) => (
                <TemplateCell
                    $store={props.$store}
                    template={template}
                    didDeleteTemplate={() => {
                        let _templates = [...templates];
                        _templates.splice(i, 1);
                        setTemplates(_templates);
                    }}
                />
            ))}
        </div>
    }
    const mobile = useMediaQuery("(max-width: 760px)");


    function emptyPlaceholder() {
        return <div className="empty-list">
            <div className="commonLabel">
                У вас пока нет ни одного шаблона :(
            </div>

            <div className="commonSubtitle" style={{marginTop: "15px"}}>
                Чтобы создать договор, вам нужно сперва добавить шаблон этого
                договора.

                Вы можете загрузить свой шаблон (в формате .docx) и настроить его, руководствуясь
                <a href="https://youtu.be/0ugr9xC0eF4" target="_blank"> нашим видео.</a>
                <br/>
                <br/>
                Если у вас возникают трудности с настройкой шаблона - можете обратиться в техподдержку, либо
                <a href="https://doki.online/template" target="_blank"> оставить заявку на настройку вашего шаблона.</a>
            </div>

            <button
                className="new withTitle"
                style={{marginTop: "15px"}}
                onClick={() =>
                    setNewTemplatePopup(true)
                }
            >
                Добавить шаблон
            </button>
        </div>
    }

    // Render
    return (
        <div className="NewContractPage_actList">
            <div style={{marginBottom:  mobile ? "97px" : "15px"}}>
                <TemplatesHeader
                    addTemplate={() => {
                        setNewTemplatePopup(true)
                    }}
                    search={(query: string) => {
                        setSearchQuery(prepareForRegexp(query))
                    }}
                    signPDF={() => {
                        $router.router.navigate('new-pdf-contract')
                    }}
                />
            </div>

            {
                mobile
                    ? makeList()
                    : <div className={"editContractConstructor"}>
                        <section style={{maxWidth: "653px"}}>
                            {
                                makeList()
                            }
                        </section>
                        <aside>
                            <div className="templateViewContainer disabledHover">
                                <WelcomeView $store={props.$store}/>
                            </div>
                        </aside>
                    </div>
            }

            <div ref={sentryRef} />

            {
                newTemplatePopup &&
                <AddTemplatePopup
                    onClose={() => {
                        setNewTemplatePopup(false)
                    }}
                    didAddTemplate={didAddTemplate}
                />
            }
        </div>
    );
};

TemplatesPage = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(TemplatesPage)

export default TemplatesPage
