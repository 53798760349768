import { IMaskInput } from 'react-imask';
import React, {MutableRefObject} from "react";

type Props = {
    value: string,
    disabled: boolean,
    onChange: Function,
    style: any,
    onBlur?: Function,
    onFocus?: Function
}

const MobileInputMask = React.forwardRef((props: Props, ref) => {
    const { value, disabled, onChange, style, onBlur, onFocus} = props
    return (
        <IMaskInput
            ref={ref}
            placeholder="Номер телефона"
            type="tel"
            style={style}
            value={value}
            disabled={disabled}
            mask={[{ mask: "+0 (000) 000-00-00" }, { mask: "+000 000 000 000" }, { mask: "+000 000 000-00-00" } ]}
            onBlur={({target: {value}}: any) => {
                if (onBlur) {
                    onBlur()
                }
            }}
            onFocus={({target: {value}}: any) => {
                if (onFocus) {
                    onFocus()
                }
            }}
            onAccept={(value, mask) => {
                onChange(value)
            }}
            dispatch={(appended: any, dynamicMasked: any) => {
                const number = (dynamicMasked.value + appended).replace(/\D/g, "");
                if (number.length <= 11 ) {
                    return dynamicMasked.compiledMasks[0];
                } else if (number.length === 12) {
                    return dynamicMasked.compiledMasks[1];
                }
                return dynamicMasked.compiledMasks[2];
            }}            
        />
    )
})

export { MobileInputMask }