import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {CircularProgress} from "@mui/material";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Popup from "../../components/popup";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {httpClient, nError} from "../../funcs";
import {connect} from "react-redux";
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import {ReactComponent as Copy} from "../Icons/size24/Copy.svg";
import Radio from "@mui/material/Radio";
import {shareTemplateLink} from "../../utils/funcs/getContractLink";

enum ShareType {
    none,
    oneTimeLink,
    public
}

let ShareTemplatePopup: any = function ShareTemplatePopup(props: any) {
    const [shareType, setShareType] = useState<number>(props.template.share_link?.share_type ?? 0)
    const [loading, setLoading] = useState(false)
    const [shareLinkId, setShareLinkId] = useState('')

    useEffect(() => {
        saveShareLink(false)
    }, [shareType])
    async function saveShareLink(close: boolean) {
        setLoading(false)
        try {
            const {data} = await httpClient.post(`/share-template`, {
                template_id: props.template._id,
                share_type: shareType
            })
            setShareLinkId(data?.share_link_id ?? '')

            if (close) {
                props.close()
            }
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
        }
    }

    function onCopy() {
        notifyUser(props, "Ссылка скопирована. Теперь можете поделиться ей с коллегами");
    }

    function makeURLView() {
        if (!shareLinkId) {
            return
        }

        const link = shareTemplateLink(shareLinkId)

        return <div className="titleContainer" style={{marginTop: "15px", gap: "20px"}}>
            <div className="commonLabel" style={{width: "100%"}}>
                <TextareaStyled
                    style={{
                        marginTop: "10px",
                    }}
                    readOnly
                    value={link}
                />
                <CopyToClipboard
                    onCopy={() => {
                        onCopy()
                    }}
                    text={link}
                >
                    <button className="new withTitle _wa" style={{marginTop: "15px"}}>
                        <div className="buttonsContainer">
                            <Copy className="icon" style={{fill: "white"}}/>
                            <span>Скопировать ссылку</span>
                        </div>
                    </button>
                </CopyToClipboard>
            </div>
        </div>
    }


    return <Popup
        maxWidth="650px"
        onClose={() => {
            props.close()
        }}
    >
        <div>
            <div className="commonTitle">Вы можете сделать свой шаблон публичными и делиться им со своими коллегами</div>
            <div>
                <div
                    className="buttonsContainer"
                    style={{marginLeft: "-10px", marginTop: "15px"}}
                >
                    <Radio
                        checked={shareType === ShareType.none}
                        onChange={() => {
                            setShareType(ShareType.none)
                        }}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <div className="commonLabel">
                        Приватный шаблон. Доступен только вам
                    </div>
                </div>
                <div
                    className="buttonsContainer"
                    style={{marginLeft: "-10px", marginTop: "15px"}}
                >

                    <Radio
                        checked={shareType === ShareType.oneTimeLink}
                        onChange={() => {
                            setShareType(ShareType.oneTimeLink)
                        }}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <div className="commonLabel">
                        Одноразовая ссылка
                    </div>
                </div>
                <div
                    className="buttonsContainer"
                    style={{marginLeft: "-10px", marginTop: "15px"}}
                >

                    <Radio
                        checked={shareType === ShareType.public}
                        onChange={() => {
                            setShareType(ShareType.public)
                        }}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <div className="commonLabel">
                        Публичный шаблон. Все кто имеет ссылку смогут добавить ваш шаблон себе
                    </div>
                </div>

                {
                    (shareType === ShareType.oneTimeLink || shareType === ShareType.public) &&
                    makeURLView()
                }

                <hr className="newHR"/>
                <button
                    className="new withTitle _wa"
                    disabled={loading}
                    style={{marginTop: "15px"}}
                    onClick={async () => {
                        await saveShareLink(true)
                    }}
                >
                    <div className="buttonsContainer">
                        {
                            loading &&
                            <CircularProgress size={"25px"} sx={{color: "#1FA3EE"}} />
                        }
                        <span>Сохранить и закрыть</span>
                    </div>
                </button>
            </div>
        </div>
    </Popup>
}

ShareTemplatePopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ShareTemplatePopup)

export default ShareTemplatePopup
