
export function cleanDigitsField(number: string): string {
    if (!number) {
        return number
    }
    let clearestNumber = ''
    const correctChars = ['+', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

    for (let char of number) {
        if (correctChars.indexOf(char) >= 0) {
            clearestNumber += char
        }
    }

    return clearestNumber
}