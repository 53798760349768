import React, {ReactElement, useEffect, useState} from 'react'

import classnames from "classnames"

import "styles/components/popup.sass"

import {ReactComponent as Close} from "pages/Icons/size24/Close.svg"
import _ from "lodash";

type Props = {
  children: ReactElement,
  onClose?: Function,
  maxWidth?: string,
  padding?: string,
  background?: string
  style?: any,
  wrapperStyle?: any,
  id?: string,
}

function Popup({ children, onClose, maxWidth, padding, background, style = {}, wrapperStyle = {}, }: Props) {

  const [isClosing, runClosingAnimation] = useState(false)
  const [mouseDownElement, setMouseDownElement] = useState<any>(null)

  const close = () => {
    if (!onClose) return
    runClosingAnimation(true)
    setTimeout(onClose, 320)
  }

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === 'Escape') {
        close()
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [])

  return <div
      className={classnames('popup', { _isClosing: isClosing })} style={{...style}}
      onMouseDown={(e) => {
        setMouseDownElement(_.get(e.target, "className"))
      }}
      onMouseUp={(e) => {
        let targetClassName = _.get(e.target, "className")
        if (targetClassName == 'popup' && targetClassName == mouseDownElement) {
          close()
        }
      }}
  >
    <div
        className="wrapper"
        style={{maxWidth: maxWidth ?? "500px", background: background, ...wrapperStyle}}
        onClick={(event) => {
          event.stopPropagation()
        }}
    >
      {
          onClose &&
          <div className="titleContainer">
            <div></div>
            <button className="new smallIconed close-button" onClick={() => close()}>
              <Close className="icon" style={{stroke: "#75809E"}} />
            </button>
          </div>
      }
      <div className="popupContainer" style={{padding: padding}}>{children}</div>
    </div>
  </div>
}

export default Popup
