import {connect} from "react-redux";
import React, {useState} from "react";
import {trackGoal} from "../../methods/trackGoal";
import notifyUser from "../../methods/notifyUser";
import {isSMSAllowedForPhoneNumber} from "../../funcs";

export function notifyUserAboutCode(props: any, channel: string) {
    if (channel == "sms") {
        notifyUser(props, 'Введите код из смс')
    }
    if (channel == "tg") {
        notifyUser(props, 'Запрос кода через телеграм', 'Чтобы получить код - поделитесь с ботом своим номером телефона.')
    }
    if (channel == "call") {
        notifyUser(props, 'Запрос кода через звонок', 'Вам поступит звонок. Его можно отклонить. Вам нужно ввести 4 последние цифры телефона, с которого вам позвонили')
    }
}

export let DidntReceiveCode: any = function EntityFromPopup(props: any) {
    function makeButton(title: string, channel: string, fromChannel: string) {
        return <button
            className="new"
            style={{display: "inline-block"}}
            onClick={() => {
                trackGoal(`register_didnt_get_code_from_${fromChannel}`)
                notifyUserAboutCode(props, channel)
                props.getCode(channel)
            }}
        >
            <div className="Body_15" style={{color: "#33B5FF"}}>
                {title}
            </div>
        </button>
    }

    if (props.channel == "sms") {
        return <div className="Body_15" style={{textAlign: "center"}}>
            Не приходит смска?&nbsp;
            {
                makeButton("Получите код через телеграм бота", "tg", "sms")
            }
            &nbsp;или&nbsp;
            {
                makeButton("получите код по телефонному звонку", "call", "sms")
            }
        </div>
    }

    if (props.channel == "tg") {
        return <div className="Body_15" style={{textAlign: "center"}}>
            Не понятно как получить код через телеграм?&nbsp;
            {
                makeButton("Получите код по телефонному звонку", "call", "tg")
            }
            {
                isSMSAllowedForPhoneNumber(props.phone_number, props.disabled_code_channels) &&
                <>
                    &nbsp;или&nbsp;
                    {
                        makeButton("получите код через смс", "sms", "tg")
                    }
                </>
            }
        </div>
    }

    if (props.channel == "call") {
        return <div className="Body_15" style={{textAlign: "center"}}>
            Не поступает входящий звонок?&nbsp;
            {
                makeButton("Получите код через телеграм бота", "tg", "call")
            }
            {
                isSMSAllowedForPhoneNumber(props.phone_number, props.disabled_code_channels) &&
                <>
                    &nbsp;или&nbsp;
                    {
                        makeButton("получите код через смс", "sms", "tg")
                    }
                </>
            }
        </div>
    }
    return <></>
}

DidntReceiveCode = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(DidntReceiveCode)
