import {connect} from "react-redux";
import React from "react";
import {FormInput, FormInputMask, FormInputPlaceholder} from "./FormInput";
import {clientLawAddress, clientLawName, clientOGRN, clientTaxId} from "../../../methods/SystemEntity";

type Props = {
    fullDataRequired: boolean,
    form: any
    updateProperty: Function
    law_name_disabled?: boolean,
    law_address_disabled?: boolean,
    ogrn_disabled?: boolean,
    tax_id_disabled?: boolean
}

let EntrepreneurForm: any = function EntrepreneurForm(props: Props) {
    return <div>
        <div className="Subheader_17" style={{marginTop: "20px"}}>
            Данные организации:
        </div>

        <FormInput
            props={{
                placeholder: "Название организации",
                disabled: props.law_name_disabled,
                value: props.form.client_law_name,
                onChange: (value: string) => props.updateProperty(clientLawName.id, value),
            }}
        />

        <FormInput
            props={{
                placeholder: "Адрес регистрации",
                disabled: props.law_address_disabled,
                value: props.form.client_law_address,
                onChange: (value: string) => props.updateProperty(clientLawAddress.id, value),
            }}
        />

        <FormInput
            props={{
                placeholder: props.fullDataRequired ? "ИНН" : "ИНН (Можно заполнить позже)",
                disabled: props.tax_id_disabled,
                value: props.form.client_tax_id,
                onChange: (value: string) => props.updateProperty(clientTaxId.id, value),
                mask: FormInputMask.taxIdIp
            }}
        />

        <FormInput
            props={{
                placeholder: props.fullDataRequired ? "ОГРН" : "ОГРН (Можно заполнить позже)",
                disabled: props.ogrn_disabled,
                value: props.form.client_ogrn,
                onChange: (value: string) => props.updateProperty(clientOGRN.id, value),
                mask: FormInputMask.ogrnIp
            }}
        />
    </div>
}

EntrepreneurForm = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(EntrepreneurForm)

export default EntrepreneurForm
