import { httpClient } from "funcs"
import React, { useEffect, useState} from "react"
import { connect } from "react-redux"

import "styles/MUI-style.sass"

import { useRoute } from "react-router5"

import {useMediaQuery} from "@mui/material";

import "pages/Styles/EditContractCommon.sass"
import "pages/Styles/EditContractEditor.sass"
import "pages/Styles/EditContractEntities.sass"
import {notifyAboutError} from "../../methods/notifyUser";
import Editor from "../../components/Editor";
import ContractFooter from "../EditContract/ContractFooter";
import {PageLoader} from "../Contracts/ContractList/PageLoader";
import {Entity, Flow} from "../Entity/Entity";
import SharedTemplateHeader from "./SharedTemplateHeader";

let SharedTemplatePage: any = function SharedTemplatePage(props: any) {
    const $router = useRoute();


    const [template, setTemplate] = useState<any>(null)
    const [loading, setLoading] = useState(false)

    const {shareLinkId} = $router.route.params;


    async function addTemplate() {
        setLoading(true)
        try {
            const {data} = await httpClient.post(`/add-shared-template`, {
                share_link_id: shareLinkId
            });

            $router.router.navigate(
                "new-contract",
                {act: "new", templateId: data._id},
                {reload: true},
            );

        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
        }
    }

    // Load template function
    async function loadTemplate() {
        setLoading(true)
        try {
            const {data: template} = await httpClient.get(`/get-template-info-by-share-link/${shareLinkId}`)

            setTemplate(template);
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
        }
    }

    // Load template
    useEffect(() => {
        loadTemplate();
    }, []);

    function getBody() {
        return template.body ?? ""
    }

    function makeEditor() {
        return <div id="editor">
            <Editor
                readonly={true}
                body={getBody()}
            />
            {
                makeFooter()
            }
        </div>
    }
    const matches = useMediaQuery("(min-width:760px)");

    function makeFooter() {
        return <div className="editContractFooterContainer">
            <ContractFooter
                template={template}
                $store={props.$store}
                showTip={true}
            />
        </div>
    }

    // Render
    return <div className="SharedTemplatePage" style={{maxWidth: "1090px"}}>
        <div className="NewContractPage_actNew">
            {
                loading && !template &&
                <PageLoader />
            }
            {
                template &&
                <div>
                    <div>
                        <SharedTemplateHeader
                            template={template}
                            addTemplate={addTemplate}
                        />
                    </div>
                    <div className="editContractConstructor readOnly">
                        <section style={{maxWidth: "653px"}}>
                            {
                                makeEditor()
                            }
                        </section>
                        <aside>
                            <div>
                                {
                                    template.entities.length > 0 &&
                                    <div className="editContractEntitiesContainer">
                                        <div className="titleContainer">
                                            <div className="editContractEntitiesTitle">
                                                Поля
                                            </div>
                                        </div>
                                        {
                                            template.entities.map((entity: any, i: number) =>
                                                <Entity
                                                    key={entity.keyword}
                                                    template={template}
                                                    entity={entity}
                                                    isSystem={false}
                                                    isEditing={false}
                                                    flow={Flow.ReadOnly}
                                                />,
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        </aside>
                    </div>
                </div>
            }
        </div>
    </div>
};

SharedTemplatePage = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(SharedTemplatePage)

export default SharedTemplatePage
