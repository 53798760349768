import {httpClient, nError} from "../funcs";
import notifyUser, {notifyAboutError} from "./notifyUser";
import {RouteContext} from "react-router5/dist/types";
import {saveToken} from "./saveToken";
import {loadSelf} from "./loadSelf";

export async function finishAuth(props: any, $router: RouteContext, accessToken: string, route: string | null = null) {
    try {
        // Save access token
        saveToken(accessToken)

        // Fetch user info
        await loadSelf(props)

        // Show success notification
        notifyUser(props, 'Авторизация успешна')

        // Navigate to profile page
        if (props.$store.redirectUrl) {
            $router.router.navigate(
                'contract',
                {
                    contractId: props.$store.redirectUrl.data.contractId
                }
            )
            props.$commitToStore({
                redirectUrl: null
            })
        } else if (route) {
            setTimeout(() => {
                $router.router.navigate(route)
            }, 0)
        } else {
            if (props.$store.inviteRoleId) {
                setTimeout(() => {
                    $router.router.navigate('profile')
                }, 201)
            }
        }
    }
    catch (error) {
        notifyAboutError(props, error)
    }
}