import icons from "../icons.svg"

type Props = {
  className?: string,
  icon: string,
  viewBox?: string,
  fill?: string
}

const Icon = (props: Props) => (
  <svg viewBox={props.viewBox || "0 0 24 24"} className={props.className} fill={props.fill}>
    <use xlinkHref={`${icons}#${props.icon}`} />
  </svg>
)

export default Icon
