import {checkInlineEntities} from "./checkInlineEntities";
import {additionalValuesString} from "./workWithEntities";
import {getPostfixForEntity} from "./utils";

export function addValueToSpanTags(template: any, body: string): string {
    template.entities.forEach((entity: any) => {
        if (entity.type == 'Фото' && entity.value && !entity.value.includes("<img")) {
            return
        }
        body = _addValueToSpanTags(entity, body, template.entities, )
    })
    template.system_entities.forEach((entity: any) => {
        if (entity.type == 'Фото' && entity.value && !entity.value.includes("<img")) {
            return
        }
        body = _addValueToSpanTags(entity, body)
    })

    return body
}

function _remove(body: string, from: number, to: number): string {
    return body.substring(0, from) + body.substring(to)
}

function _insert(body: string, value: string, from: number): string {
    return body.substring(0, from) + value + body.substring(from)
}

function _addValueToSpanTags(entity: any, body: string, otherEntities: any[] = []): string {
    let value = entity.value
    let keyword = entity.keyword
    let postfix = entity.postfix ? getPostfixForEntity(entity) : ''

    let name = `<<${keyword}>>`
    let kw = `{{${keyword}}}`

    // console.log(body)
    // console.log('_addValueToSpanTags start', name, value.length, value)

    let offset = 0
    let index = body.indexOf(name, offset)

    while (index != -1) {
        offset = index + name.length
        let openTagEnd = body.indexOf(">", offset) + 1
        let closeTagStart = findCloseTagStart(body, offset)
        if (closeTagStart == -1) {
            break
        }
        let valueToDelete = body.slice(openTagEnd, closeTagStart)

        // console.log('openTagEnd', openTagEnd)
        // console.log('closeTagStart', closeTagStart)
        // console.log('valueToDelete', valueToDelete)

        body = _remove(body, openTagEnd, closeTagStart)
        // console.log('_remove', body)
        value = value ?? ""
        value = checkInlineEntities(value, kw, otherEntities)
        // console.log('value', value)
        body = _insert(body, value.length == 0 ? kw : value + postfix + additionalValuesString(entity), openTagEnd)
        // console.log('_insert', body)

        offset = openTagEnd
        index = body.indexOf(name, offset)
    }
    return body
}


function findCloseTagStart(body: string, offset: number): number {
    let nextOpenTag = body.indexOf("<span", offset)
    let nextCloseTag = body.indexOf("</span>", offset)

    // console.log("findCloseTagStart", nextOpenTag, nextCloseTag)
    while (nextOpenTag < nextCloseTag) {
        if (nextOpenTag == -1 || nextCloseTag == -1) {
            break
        }
        nextOpenTag = body.indexOf("<span", nextOpenTag + 1)
        nextCloseTag = body.indexOf("</span>", nextCloseTag + 1)
        // console.log("findCloseTagStart new ", nextOpenTag, nextCloseTag)
    }

    return nextCloseTag
}


export function clearValueInSpanTags(body: string, entity: any): string {
    // console.log("clearValueInSpanTags", entity.keyword)
    return _addValueToSpanTags(entity, body)
}