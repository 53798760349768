import {connect} from "react-redux";
import "pages/Styles/EditContractCommon.sass"
import React, {useState} from "react";
import {clientRoles, ChooseAccountTypeFrom} from "../Auth/UserForms/ChooseAccountTypeFrom";
import {PassportFormContainer} from "../Auth/UserForms/PassportFormContainer";
import CustomerForm from "../Auth/UserForms/CustomerForm";
import {FormInputPlaceholder} from "../Auth/UserForms/FormInput";
import EntrepreneurForm from "../Auth/UserForms/EntrepreneurForm";
import BankDetailsForm from "../Auth/UserForms/BankDetailsForm";
import ContactsForm from "../Auth/UserForms/ContactsForm";
import {defaultUserFormForContractCreating, defaultUserFormForSigning} from "../Auth/RegisterDefaultValues";
import {updateEntityInEditor, updateEntityInEditorBySystemEntityId} from "../../methods/updateEntityInEditor";
import {contractSystemEntity} from "../../methods/systemEntityValueByKeyword";
import {
    clientEmail,
    clientFIO,
    clientFirstName,
    clientLastName,
    clientMiddleName, clientPhoneNumber, clientRole,
    getSystemEntityById
} from "../../methods/SystemEntity";
import _ from "lodash";

let FillClientDataView: any = function FillClientDataPopup(props: any) {
    const form = {...defaultUserFormForContractCreating(props.contract)}

    function makeForm(){
        if (form.client_role == clientRoles[0]) {
            return makeEntrepreneurForm()
        }
        if (form.client_role == clientRoles[1]) {
            return makeCustomerForm()
        }

        return null
    }
    
    function updateEntity(entityId: string, value: string) {
        let entity = contractSystemEntity(props.contract, entityId) ?? getSystemEntityById(entityId)
        if (entity) {
            props.addEntities(true, [{...entity, value: value}])
            updateEntityInEditorBySystemEntityId(entityId, value)
        }
    }

    function updateFIO(id: string, value: string) {
        let fio = ""
        switch (id) {
            case clientLastName.id:
                fio = [value, form.client_first_name, form.client_middle_name].filter((str) => str !== '').join(' ')
                break
            case clientFirstName.id:
                fio = [form.client_last_name, value, form.client_middle_name].filter((str) => str !== '').join(' ')
                break
            case clientMiddleName.id:
                fio = [form.client_last_name, form.client_first_name, value].filter((str) => str !== '').join(' ')
                break
            default:
                break
        }
        let clientFirstNameEntity = contractSystemEntity(props.contract, id) ?? getSystemEntityById(id)
        let clientFIOEntity = contractSystemEntity(props.contract, clientFIO.id) ?? getSystemEntityById(clientFIO.id)
        props.addEntities(true, [
            {...clientFirstNameEntity, value: value},
            {...clientFIOEntity, value: fio}
        ])
        updateEntityInEditorBySystemEntityId(id, value)
        updateEntityInEditorBySystemEntityId(clientFIO.id, fio)
    }

    function makeCustomerForm() {
        // console.log("form", form.client_first_name)
        return <div>
            <CustomerForm
                lastName={form.client_last_name}
                onChangeLastName={(value: string) => {
                    updateFIO(clientLastName.id, value)
                }}

                firstName={form.client_first_name}
                onChangeFirstName={(value: string) => {
                    updateFIO(clientFirstName.id, value)
                }}

                middleName={form.client_middle_name}
                onChangeMiddleName={(value: string) => {
                    updateFIO(clientMiddleName.id, value)
                }}
            />

            <PassportFormContainer
                contract={props.contract}
                registerForm={form}
                updateRegisterForm={(entityId: string, value: string) => {
                    updateEntity(entityId, value)
                }}
                fillByAdmin={true}
            />
        </div>
    }

    function makeEntrepreneurForm() {
        return <div>
            <EntrepreneurForm
                fullDataRequired={true}
                form={form}
                updateProperty={(id: string, value: string) => updateEntity(id, value)}
            />

            {
                bankDetailsForm()
            }
        </div>
    }

    function bankDetailsForm() {
        return <BankDetailsForm
            form={form}
            updateProperty={(id: string, value: string) => updateEntity(id, value)}
        />
    }

    function contactsForm() {
        return <ContactsForm
            email={{
                placeholder: FormInputPlaceholder.email,
                value: form.client_email,
                onChange: (value: string) => {
                    updateEntity(clientEmail.id, value)
                    updateEntityInEditorBySystemEntityId(clientEmail.id, value)
                }
            }}
            phoneNumber={
                {
                    placeholder: FormInputPlaceholder.phoneNumber,
                    value: form.client_phone_number,
                    onChange: (value: string) => {
                        updateEntity(clientPhoneNumber.id, value)
                        updateEntityInEditorBySystemEntityId(clientPhoneNumber.id, value)
                    }
                }
            }
        />
    }

    return <div>
        <hr className="newHR"/>

        <div className="Body_15" style={{marginTop: "15px", marginBottom: "5px", textAlign: "left"}}>
            Обязательные для заполнения поля помечены *. Остальные поля можно не заполнять, но это упростит подписание со стороны вашего клиента
        </div>

        {
            contractSystemEntity(props.contract, clientRole.id)?.editable == true &&
            <ChooseAccountTypeFrom
                title={"Клиент подписывает договор как:"}
                role={form.client_role}
                allowNotSelectedOptions={true}
                onChange={(value: string) => {
                    updateEntity(clientRole.id, value)
                }}
            />
        }

        {
            makeForm()
        }

        {
            contactsForm()
        }
    </div>
}

FillClientDataView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(FillClientDataView)

export default FillClientDataView