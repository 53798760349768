import {connect} from "react-redux";
import React from "react";
import {FormInput, FormInputMask, FormInputPlaceholder} from "./FormInput";
import {clientBankAccount, clientBankName, clientBIC, clientCorrAccount} from "../../../methods/SystemEntity";

type Props = {
    form: any
    updateProperty: Function
    bank_name_disabled?: boolean,
    bank_account_disabled?: boolean,
    corr_account_disabled?: boolean,
    bic_disabled?: boolean
}

let BankDetailsForm: any = function BankDetailsForm(props: Props) {
    return <div>
        <div className="Subheader_17"  style={{marginTop: "20px"}}>
            Банковские реквизиты:
        </div>
        <FormInput
            props={{
                placeholder: FormInputPlaceholder.bankName,
                disabled: props.bank_name_disabled,
                value: props.form.client_bank_name,
                onChange: (value: string) => props.updateProperty(clientBankName.id, value)
            }}
        />

        <FormInput
            props={{
                placeholder: FormInputPlaceholder.bankAccount,
                disabled: props.bank_account_disabled,
                value: props.form.client_bank_account,
                onChange: (value: string) => props.updateProperty(clientBankAccount.id, value),
                mask: FormInputMask.bankAccount,
            }}
        />

        <FormInput
            props={{
                placeholder: FormInputPlaceholder.corrAccount,
                disabled: props.corr_account_disabled,
                value: props.form.client_corr_account,
                onChange: (value: string) => props.updateProperty(clientCorrAccount.id, value),
                mask: FormInputMask.corrAccount,
            }}
        />

        <FormInput
            props={{
                placeholder: FormInputPlaceholder.bic,
                disabled: props.bic_disabled,
                value: props.form.client_bic,
                onChange: (value: string) => props.updateProperty(clientBIC.id, value),
                mask: FormInputMask.bic,
            }}
        />
    </div>
}

BankDetailsForm = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(BankDetailsForm)

export default BankDetailsForm
