import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {FormControlLabel, RadioGroup, useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import Radio from "@mui/material/Radio";
import {ReactComponent as RadioChecked} from "../../../Icons/size24/RadioChecked.svg";
import {ReactComponent as RadioUnchecked} from "../../../Icons/size24/RadioUnchecked.svg";
import {boolean} from "mathjs";

let ChooseWhoCanFillView: any = function ChooseWhoCanFillView(props: any) {
    const editable = props.editable

    return <>
        <div className="commonLabel">
            Кто будет заполнять этот блок?
        </div>

        <RadioGroup
            defaultValue={editable}
            onChange={(e, value) => {
                props.updateEditable(value == "true")
            }}
        >
            <FormControlLabel
                key={"Нет"}
                value={false}
                control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                label={'Только вы'}
            />
            <FormControlLabel
                key={"Да"}
                value={true}
                control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                label={'Вы и ваш клиент'}
            />
        </RadioGroup>
    </>
}

ChooseWhoCanFillView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ChooseWhoCanFillView)

export default ChooseWhoCanFillView
