import {useRoute} from "react-router5";
import {CircularProgress, useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import React from "react";

export let PageLoader: any = function PageLoader(props: any) {
    const matches = useMediaQuery("(min-width: 760px)");

    return <div
        style={{
            marginTop: "130px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        <CircularProgress sx={{color: "#1FA3EE"}} />
    </div>
}