import React, {useRef} from 'react'


import "styles/components/popup.sass"

import JoditEditor from 'jodit-react';
import {Jodit} from "jodit/types/jodit";

type Props = {
    body: string,
    readonly: boolean,
    onBlur?: ((newValue: string) => void),
    onChange?: ((newValue: string) => void)
}



const config: Partial<Jodit['options']> = {
    toolbar: true,
    readonly: false,
    sizeSM: 150,
    sizeMD: 160,
    sizeLG: 170,
    defaultActionOnPaste: 'insert_only_text',
    disablePlugins: ["file", "video","about","find","classSpan","speechRecognize","spellcheck", "paste", "search", "source", "addNewLine", "hr"],
    removeButtons: ["strikethrough", "hr", "subscript", "superscript", "selectall", "fullsize", "print", "preview", "symbols", "cut", "copy"],
    // @ts-ignore
    uploader: {
        insertImageAsBase64URI: true,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp']
    }
}

const readonlyConfig: Partial<Jodit['options']> = {
    toolbar: false,
    readonly: true,
}

function Editor({body, readonly, onBlur, onChange}: Props) {
    const editor = useRef(null);

    {/* @ts-ignore */}
    return <JoditEditor
        ref={editor}
        value={body}
        className={readonly ? "contract-readonly" : "contract-editor"}
        config={readonly ? readonlyConfig : config}
        onBlur={onBlur}
        onChange={onChange}
    />
}

export default Editor
