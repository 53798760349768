import { parseDomain, ParseResultType } from 'parse-domain';

function getDomain() {
    const parseResult = parseDomain(
        window.location.hostname,
    );

    // check if the domain is listed in the public suffix list
    if (parseResult.type === ParseResultType.Listed) {
        const { subDomains, domain, topLevelDomains } = parseResult;

        console.log(subDomains); // ["www", "some"]
        console.log(domain); // "example"
        console.log(topLevelDomains); // ["co", "uk"]

        return domain + "." + topLevelDomains[0]
    } else {
        return ""
    }
}

export default getDomain