import {validateMany} from "../validate";
import {
    canEditSystemEntityById,
    contractSystemEntity,
    dateFromIsoToPlain,
    entityById,
} from "./systemEntityValueByKeyword";
import cleanPhoneNumber from "./cleanPhoneNumber";
import {
    clientEmail,
    clientLawAddress,
    clientLawName,
    clientOGRN,
    clientPassportIssuedAt,
    clientPassportIssuedBy,
    clientPassportIssuedByCode,
    clientPassportNumber,
    clientPassportPhoto,
    clientPassportRegistration,
    clientPassportRegistrationPhoto,
    clientPassportSelfiePhoto,
    clientPassportType, clientPhoneNumber, clientRole,
    clientTaxId,
    contactsEntities,
    customerPersonalEntities, entrepreneurAllEntities,
    entrepreneurBankEntities, isCustomerEntity, isEntrepreneurEntity, PassportType,
    SystemEntity,
    SystemEntityType
} from "./SystemEntity";
import _ from "lodash";
import {capitalize} from "./notifyUser";
import {clientRoles} from "../pages/Auth/UserForms/ChooseAccountTypeFrom";
import {preparePhoneNumber} from "../funcs";

export function extraFieldIsRequired(entity: any, isFirstSigner: boolean): boolean {
    if (!entity) {
        return false
    }

    if (isFirstSigner) {
        if (entity.adminCanFill) {
            if (entity.clientCanFill) {
                return false
            } else {
                return entity.isRequired ?? true
            }
        } else {
            return false
        }
    } else {
        return entity.clientCanFill && (entity.isRequired ?? true)
    }
}

function prepareIssuedAt(registerForm: any): string {
    if (registerForm.client_passport_issued_at) {
        return dateFromIsoToPlain(registerForm.client_passport_issued_at.trim())
    } else {
        return ''
    }
}

export function prepareDataInRegisterForm(registerForm: any): any {
    return {
        ...registerForm,
        client_last_name: capitalize(registerForm.client_last_name?.trim() ?? ''),
        client_first_name: capitalize(registerForm.client_first_name?.trim() ?? ''),
        client_middle_name: capitalize(registerForm.client_middle_name?.trim() ?? ''),

        client_passport_type: registerForm.client_passport_type?.trim() ?? '',
        client_passport_number: (registerForm.client_passport_number ?? '').trim().split('-').join(' '),
        client_passport_issued_by_code: registerForm.client_passport_issued_by_code?.trim() ?? '',
        client_passport_issued_at: prepareIssuedAt(registerForm),
        client_passport_issued_by: registerForm.client_passport_issued_by?.trim() ?? '',
        client_passport_registration: registerForm.client_passport_registration?.trim() ?? '',

        client_passport_photo: registerForm.client_passport_photo?.trim() ?? '',
        client_passport_selfie_photo: registerForm.client_passport_selfie_photo?.trim() ?? '',
        client_passport_registration_photo: registerForm.client_passport_registration_photo?.trim() ?? '',

        client_law_name: registerForm.client_law_name?.trim() ?? '',
        client_law_address: registerForm.client_law_address?.trim() ?? '',
        client_tax_id: registerForm.client_tax_id?.trim() ?? '',
        client_ogrn: registerForm.client_ogrn?.trim() ?? '',

        client_bank_name: registerForm.client_bank_name?.trim() ?? '',
        client_bank_account: registerForm.client_bank_account?.trim() ?? '',
        client_corr_account: registerForm.client_corr_account?.trim() ?? '',
        client_bic: registerForm.client_bic?.trim() ?? '',

        client_email: (registerForm.client_email ?? '').replace(/ /g,''),
        client_phone_number: cleanPhoneNumber(registerForm.client_phone_number ?? '')
    }
}


export function validateSystemEntitiesInForm(system_entities: SystemEntity[], form: any): string[] {
    return validateMany(system_entities.map((entity) => {
        let rule = ''
        if (entity.id == clientPassportNumber.id) {
            rule = entityById(system_entities, clientPassportType.id) === PassportType.russianPassport
                ? 'ruPassportNumber'
                : 'anotherPassportNumber'
        } else {
            rule = entity.validationRule ?? ''
        }
        return {rule: rule, entity: _.get(form, entity.id, ''), displayFieldText: entity.keyword}
    }))
}

export function validateRegisterFormWhileCreatingContract(
    entitiesToValidate: SystemEntity[],
    form: any,
): string[] {
    let contractClientRole = entityById(entitiesToValidate, clientRole.id)
    entitiesToValidate = entitiesToValidate.filter((entity) => {
        if (contractClientRole.value == clientRoles[0] && !isEntrepreneurEntity(entity.id)) {
            return false
        }

        if (contractClientRole.value == clientRoles[1] && !isCustomerEntity(entity.id)) {
            return false
        }

        if (contractClientRole.value == "" && !(isCustomerEntity(entity.id) || isEntrepreneurEntity(entity.id))) {
            return false
        }

        return !entity.hidden && !entity.editable
    })

    console.log("entitiesToValidate", entitiesToValidate, form)
    return validateSystemEntitiesInForm(entitiesToValidate, form)
}

export function validateEntrepreneurRegisterFormWhileSigning(
    form: any,
    contract: any,
    is_first_signer: boolean
): string[] {
    let entitiesToValidate = [clientLawName, clientLawAddress]

    if (form.client_tax_id.length > 0 || contract) {
        entitiesToValidate.push(clientTaxId)
    }

    if (form.client_ogrn.length > 0 || contract) {
        entitiesToValidate.push(clientOGRN)
    }

    if (contract) {
        entitiesToValidate = entitiesToValidate.concat(entrepreneurBankEntities)
    }

    return validateSystemEntitiesInForm(entitiesToValidate, form)
        .concat(
            validateExtraEntities(
                extraEntitiesToSend(contract, is_first_signer),
                is_first_signer
            )
        )
}

export function validateCustomerRegisterFormWhileSigning(
    form: any,
    contract: any,
    is_first_signer: boolean
): string[] {
    let entitiesToValidate = customerPersonalEntities
        .concat(contactsEntities)
        .concat(passportEntitiesToValidate(contract, form))

    return validateSystemEntitiesInForm(entitiesToValidate, form)
        .concat(
            validateExtraEntities(
                extraEntitiesToSend(contract, is_first_signer),
                is_first_signer
            )
        )
}

export function isExtraEntity(entity: any, is_first_signer: boolean) {
    if (is_first_signer) {
        return entity.adminCanFill
    } else {
        return entity.clientCanFill == true
    }
}

export function extraEntitiesToSend(contract: any, is_first_signer: boolean) {
    return contract?.entities.filter((entity: any) => {
        return isExtraEntity(entity, is_first_signer) || entity.type == SystemEntityType.Formula.toString()
    }) ?? []
}

export function validateExtraEntities(entities: any, isFirstSigner: boolean): string[] {
    let validationErrors: string[] = []
    for (const entity of entities) {
        if (extraFieldIsRequired(entity, isFirstSigner) && !entity.value) {
            validationErrors.push(`Поле "${entity.keyword}" заполнено не правильно. \n`)
        }
    }
    return validationErrors
}

export type PassportSettingsAdapter = {
    enabled: boolean
    number: boolean
    issued_by: boolean
    issued_by_code: boolean
    issued_at: boolean
    registration: boolean
    photo: boolean
    selfie_photo: boolean
    registration_photo: boolean
}

export function makePassportSettingsAdapter(contract: any): PassportSettingsAdapter {
    function isHidden(entity: SystemEntity): boolean {
        let contractEntity = contractSystemEntity(contract, entity.id)
        if (!contractEntity) {
            return false
        }
        return !(contractEntity.hidden ?? entity.id != clientPassportNumber.id)
    }


    return {
        enabled: isHidden(clientPassportNumber),
        number: isHidden(clientPassportNumber),
        issued_by_code: isHidden(clientPassportIssuedByCode),
        issued_at: isHidden(clientPassportIssuedAt),

        issued_by: isHidden(clientPassportIssuedBy),
        registration: isHidden(clientPassportRegistration),

        photo: isHidden(clientPassportPhoto),
        selfie_photo: isHidden(clientPassportSelfiePhoto),
        registration_photo: isHidden(clientPassportRegistrationPhoto),
    }
}

export function passportEntitiesToValidate(contract: any, form: any): SystemEntity[] {
    if (!contract) {
        return []
    }

    let passportSettings = makePassportSettingsAdapter(contract)
    if (!passportSettings.enabled) {
        return []
    }

    let entitiesToValidate = [clientPassportNumber]

    if (passportSettings.issued_at) {
        entitiesToValidate.push(clientPassportIssuedAt)
    }

    if (passportSettings.issued_by_code && form.client_passport_type === PassportType.russianPassport) {
        entitiesToValidate.push(clientPassportIssuedByCode)
    }

    if (passportSettings.issued_by && form.client_passport_type === PassportType.russianPassport) {
        entitiesToValidate.push(clientPassportIssuedBy)
    }

    if (passportSettings.registration && form.client_passport_type === PassportType.russianPassport) {
        entitiesToValidate.push(clientPassportRegistration)
    }

    if (passportSettings.photo) {
        entitiesToValidate.push(clientPassportPhoto)
    }
    if (passportSettings.selfie_photo) {
        entitiesToValidate.push(clientPassportSelfiePhoto)
    }
    if (passportSettings.registration_photo && form.client_passport_type === PassportType.russianPassport) {
        entitiesToValidate.push(clientPassportRegistrationPhoto)
    }

    return entitiesToValidate
}

export function validateContractCreatedForAndUser(contract: any, form: any, user: any): string {
    return validateContractCreatedForAndUserEmail(contract, form, user) ||
        validateContractCreatedForAndUserPhoneNumber(contract, form, user) ||
        validateContractCreatedForAndClientRole(contract, form, user)
}

export function validateContractCreatedForAndUserEmail(contract: any, form: any, user: any): string {
    if (!user) {
        return ''
    }
    let emailDisabled = !canEditSystemEntityById(contract, clientEmail.id)
    let formEmail = (form.client_email ?? "").toLowerCase()
    let userEmail = (user.email ?? "").toLowerCase()
    if (emailDisabled && formEmail != userEmail) {
        return `Договор нельзя подписать так как он выставлен на почту ${form.client_email}, а почта вашего аккаунта ${user.email}`
    } else {
        return ''
    }
}

export function validateContractCreatedForAndUserPhoneNumber(contract: any, form: any, user: any): string {
    if (!user) {
        return ''
    }
    let phoneNumberDisabled = !canEditSystemEntityById(contract, clientPhoneNumber.id)
    let client_phone_number = preparePhoneNumber(form.client_phone_number)
    if (phoneNumberDisabled && client_phone_number != user.phone_number) {
        return `Договор нельзя подписать так как он выставлен на номер ${client_phone_number}, а номер вашего аккаунта ${user.phone_number}`
    } else {
        return ''
    }
}

export function validateContractCreatedForAndClientRole(contract: any, form: any, user: any): string {
    if (!user || !contract) {
        return ''
    }
    let targetClientRole = entityById(contract.system_entities, clientRole.id)
    let clientRoleDisabled = !(targetClientRole?.editable ?? true)
    let targetUserRole= targetClientRole == clientRoles[0] ? 'юр. лицо' : 'физ. лицо'
    let currentUserRole= user.role == clientRoles[0] ? 'юр. лица' : 'физ. лица'

    if (clientRoleDisabled && form.client_role != user.role) {
        return `Договор нельзя подписать так как договор должно подписать ${targetUserRole}, а у вас аккаунт ${currentUserRole}`
    } else {
        return ''
    }
}