import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import "styles/pages/profile.sass"
import {httpClient} from "../../funcs";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import Popup from "../../components/popup";
import EnterTextPopup from "../Common/EnterTextPopup";

type Props = {
    close: Function,
    onCollectBonus: Function,
    $store: any,
    $commitToStore: Function
}

let ReferralsView: any = function ReferralsView(props: Props) {

    const [referrals, setReferrals] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [referralToCollect, setReferralToCollect] = useState<any | null>(null)

    useEffect(() => {
        requestReferrals()
    }, [])

    function requestReferrals() {
        httpClient.get('/get-referrals').then(({data}) => {
            setReferrals(data)
            setLoading(false)
        }).catch((error) => {
            notifyAboutError(props, error)
            setLoading(false)
        })
    }

    function collectBonus(referral: any) {
        setLoading(true)
        httpClient.post('/collect-referral-bonus', {
            promo_history_id: referral._id
        }).then(({data}) => {
            requestReferrals()
            notifyUser(props, "Бонус активирован", data)
            props.onCollectBonus()
        }).catch((error) => {
            notifyAboutError(props, error)
        })
    }

    function collectMoneyBonus(referral: any, message: string) {
        setLoading(true)
        httpClient.post('/collect-referral-money-bonus', {
            promo_history_id: referral._id,
            message: message
        }).then(({data}) => {
            requestReferrals()
            notifyUser(props, "Запрос отправлен", data)
            props.onCollectBonus()
        }).catch((error) => {
            notifyAboutError(props, error)
        })
    }

    function bonusTitle(bonus: any) {
        if (bonus[0] == "PromoCodeBonusType.PROLONG_SUBSCRIPTION") {
            return `Продление подписки на ${bonus[1]} дней`
        }

        if (bonus[0] == "PromoCodeBonusType.ADD_DOCUMENTS_COUNT") {
            return `+${bonus[1]} бонусных договоров на ваш счет`
        }
    }

    function moneyBonusTitle(bonus: number ) {
        return `${bonus/100} рублей на карту 🔥🔥🔥`
    }

    if (referralToCollect) {
        return <EnterTextPopup
            title={"Введите номер и банк на который вам перевести бонус"}
            placeholder={"Номер телефона и банк"}
            oldText={''}
            onClose={() => {
                setReferralToCollect(null)
            }}
            canSaveEmpty={false}
            saveNewName={(message: string) => {
                collectMoneyBonus(referralToCollect, message)
                setReferralToCollect(null)
            }}
        />
    }

    return <Popup
        style={{zIndex: 999}}
        onClose={props.close}
    >
        <div>
            <div className="commonTitle">
                Список приведенных вами людей
            </div>

            <div className="commonSubtitle" style={{marginTop: "15px", marginBottom: "25px"}}>
                Расскажите о сервисе ОкиДоки своим друзьям и поделитесь своим промо кодом. За каждого, кто купит пакет или оформит подписку, вы получите бонус!
            </div>
            {
                referrals.map((referral: any) => {
                    let user = referral.user_activated
                    if (!user) {
                        let fullname = referral.fullname
                        if (fullname) {
                            return <div>
                                <div className="commonLabel">
                                    {referral.fullname}
                                </div>
                                <div className="commonSubtitle" style={{marginTop: "5px"}}>
                                    Пока еще не купил ничего
                                </div>
                                <hr className="newHR"/>
                            </div>   
                        } else {
                            return 
                        }
                    }
                    return <div>
                        <div className="commonLabel">
                            {user.fullname}
                        </div>
                        <div className="commonSubtitle" style={{marginTop: "5px"}}>
                            Купил {user.tariff.type == 'package' ? 'пакет' : 'подписку'} {user.tariff.name} за {user.tariff.price / 100} рублей
                        </div>
                        <div className="commonSubtitle" style={{marginTop: "5px"}}>
                            Ваш бонус: {bonusTitle(user.bonus)}
                        </div>

                        <button
                            className="new withTitle"
                            disabled={(referral.bonus_collected ?? false) || loading}
                            style={{width: "200px !important", height: "25px", marginTop: "10px"}}
                            onClick={() => {
                                collectBonus(referral)
                            }}
                        >
                            <div className="buttonsContainer">
                                {
                                    (referral.bonus_collected ?? false) ? "Бонус получен" : "Получить бонус"
                                }
                            </div>
                        </button>

                        {
                            !!user.money_bonus &&
                            <div style={{marginTop: "15px"}}>
                                <div className="commonSubtitle">
                                    🔥Денежный бонус: {moneyBonusTitle(user.money_bonus)}🔥
                                </div>

                                <button
                                    className="new withTitle"
                                    disabled={(referral.money_bonus_collected) || loading}
                                    style={{width: "200px !important", height: "25px", marginTop: "10px"}}
                                    onClick={() => {
                                        setReferralToCollect(referral)
                                    }}
                                >
                                    { !referral.money_bonus_collected && "Получить денежный бонус" }
                                    { referral.money_bonus_collected == "AWAITING" && "Запрос на бонус отправлен" }
                                    { referral.money_bonus_collected == "COLLECTED" && "Денежный бонус получен" }
                                </button>
                            </div>
                        }
                        <hr className="newHR"/>
                    </div>
                })
            }
        </div>
    </Popup>
}

ReferralsView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ReferralsView)

export default ReferralsView