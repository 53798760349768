import Popup from "../../components/popup";
import {connect} from "react-redux";
import React from "react";
import WelcomeView from "./WelcomeView";

let WelcomePopup: any = function WelcomePopup(props: any) {
    function closePopup() {
        props.$commitToStore({
            showWelcomePopup: false
        })
    }

    return <Popup
        maxWidth="650px"
        onClose={closePopup}
    >
        <div>
            <WelcomeView $store={props.$store}/>

            <hr className="newHR"/>

            <button
                className="new withTitle _wa"
                onClick={closePopup}
            >
                Начать пользоваться
            </button>
        </div>
    </Popup>
}

WelcomePopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(WelcomePopup)

export default WelcomePopup