import React, {useState} from "react";
import {useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import {useRoute} from "react-router5";

import {ReactComponent as Delete} from "../Icons/size24/Delete.svg"
import {ReactComponent as DownloadLight} from "../Icons/size24/DownloadLight.svg"
import {ReactComponent as Edit} from "../Icons/size24/Edit.svg"
import {ReactComponent as ActionsHorizontal} from "../Icons/size24/ActionsHorizontal.svg"

import "pages/Styles/TemplatesCommon.sass"
import "pages/Styles/EditContractCommon.sass"
import {$moment, httpClient, nError} from "../../funcs";
import notifyUser from "../../methods/notifyUser";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import makeTemplateDownloadLink from "../../methods/makeTemplateDownloadLink";
import MenuButton from "../../methods/MenuButton";

type Props = {
    template: any,
    $store: any,
    didDeleteTemplate: Function
}

let TemplateCell: any = function TemplateView(props: Props) {
    const {template, $store, didDeleteTemplate} = props
    const $router = useRoute();
    const matches = useMediaQuery("(min-width: 760px)");

    // Delete template function

    const [deletePopup, setDeletePopup] = useState(false);
    async function deleteTemplate() {
        try {
            await httpClient.delete(`/template/${template._id}`);

            didDeleteTemplate()

            // Notify user
            notifyUser(props, "Шаблон удалён")
        } catch (error) {
            const $error = nError(error);
            notifyUser(props, $error.notification.title)
        } finally {
            setDeletePopup(false)
        }
    }

    function openTemplate() {
        $router.router.navigate(
            "new-contract",
            {act: "new", templateId: template._id},
            {reload: true},
        );
    }

    function makeDateElement() {
        if (template.updated_at) {
            let updatedAt = $moment(template.updated_at)
            let today = $moment().startOf('day');
            let yesterday = $moment().subtract(1, 'days').startOf('day');
            if (updatedAt.isSame(today, 'day')) {
                return <span>{`Сегодня, ${$moment(template.updated_at).format('HH:mm')}  `}</span>
            } else if (updatedAt.isSame(yesterday, 'day')) {
                return <span>{`Вчера, ${$moment(template.updated_at).format('HH:mm')}  `}</span>
            } else {
                return <span>{`${$moment(template.updated_at).format('LL')}  `}</span>
            }
        }
        return null
    }

    function makeActionsButton() {
        return <div>
            <MenuButton
                className="new smallIconed"
                items={[
                    {
                        "icon": <Edit className="icon light" style={{marginRight: "10px"}}/>,
                        "title": "Выставить на подпись",
                        onTap: () => {
                            openTemplate()
                        }
                    },
                    {
                        "icon": <DownloadLight className="icon" style={{marginRight: "10px"}}/>,
                        "title": <a
                            className="subtitle"
                            download
                            href={makeTemplateDownloadLink(template._id, $store.userSession)}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            Скачать
                        </a>,
                        onTap: () => {
                            window.location.href=makeTemplateDownloadLink(template._id, $store.userSession)
                        }
                    },
                    {
                        "icon": <Delete className="icon light" style={{marginRight: "10px"}}/>,
                        "title": "Удалить",
                        onTap: () => {
                            setDeletePopup(true)
                        }
                    }
                ]}
            >
                <ActionsHorizontal className="icon dark"/>
            </MenuButton>
        </div>
    }

    return <div
        className="templateViewContainer"
    >
        <div className="templateTitleContainer"
             onClick={(e) => {
                 openTemplate()
             }}
        >
            <div>
                <div className="title">
                    {template.name + "   "}
                </div>
                {
                    !!template.comment &&
                    <div className="Body_13 comment">
                        {template.comment}
                    </div>
                }
                <div className="subtitle">
                    {
                        makeDateElement()
                    }
                </div>
            </div>
            {
                makeActionsButton()
            }
        </div>

        {
            deletePopup &&
            <ConfirmationPopup
                title="Уверены, что хотите удалить шаблон?"
                onClose={() => {setDeletePopup(false)}}
                onConfirm={() => {
                    deleteTemplate()
                }}
            />
        }
    </div>
}

TemplateCell = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(TemplateCell)

export default TemplateCell