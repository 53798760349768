import React, {MouseEventHandler, useState} from "react";
import {useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import {useRoute} from "react-router5";
import "../../Styles/TemplatesCommon.sass"
import "../../Styles/EditContractCommon.sass"
import "../../Styles/UIKit.sass"

type Props = {
    options: string[],
    selectedOption: string,
    selectOptions: Function
}

let TabSelector: any = function TabSelector(props: Props) {
    const {options, selectedOption, selectOptions} = props

    const $router = useRoute();
    const matches = useMediaQuery("(min-width: 760px)");

    return <div
        style={{
            display: "table",
            height: "35px",
            borderBottom: "1px solid #E0E0E0"
        }}
    >
        <div
            className="buttonsContainer"
            style={{gap: "25px"}}
        >
            {
                options.map((option: string) => {
                    return <button
                        className="new"
                        onClick={() => {
                            selectOptions(option)
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0px"
                            }}
                        >
                            <div className="Body_17" style={{height: selectedOption == option ? "33px" : "35px"}}>
                                {option}
                            </div>
                            {
                                selectedOption == option &&
                                <div style={{background: "#33B5FF", height: "2px"}}></div>
                            }
                        </div>
                    </button>
                })
            }
        </div>
    </div>
}

TabSelector = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(TabSelector)

export default TabSelector