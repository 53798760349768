import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {EntityInput, makeDatePicker} from "../Entity/EntityInput";
import {contractNumber, currentDate, firstSigner} from "../../methods/SystemEntity";
import Radio from "@mui/material/Radio";
import {FormControlLabel, RadioGroup} from "@mui/material";
import {ReactComponent as RadioChecked} from "../Icons/size24/RadioChecked.svg";
import {ReactComponent as RadioUnchecked} from "../Icons/size24/RadioUnchecked.svg";
import {$moment} from "../../funcs";
import EntityCopyButton from "./ContractRequestedClientData/Views/EntityCopyButton";
import {updateLocalEntities} from "../../methods/updateLocalEntities";
import {contractSystemEntity} from "../../methods/systemEntityValueByKeyword";

let ContractNumberAndDateSection: any = function ContractNumberAndDateSection(props: any) {
    const [useCurrentDate, setUseCurrentDate] = useState(props.isTemplate)
    const currentDateEntity = contractSystemEntity(props.contract, currentDate.id) ?? currentDate

    const [autoincrementContractNumber, setAutoincrementContractNumber] = useState(props.isTemplate)
    const contractNumberEntity = contractSystemEntity(props.contract, contractNumber.id) ?? contractNumber
    const [isEditing, setIsEditing] = useState(false)
    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!currentDateEntity.value) {
            updateDate(today())
            console.log('currentDateEntity.value', currentDateEntity.value)
        }
        if (!contractNumberEntity.value) {
            updateContractNumber(props.contract.next_contract_number ?? props.userSession?.last_contract_number ?? "1")
        }
        },[]
    )
    function updateDate(date: string) {
        props.addEntities(true, [{...currentDateEntity, value: date}])
    }

    function updateContractNumber(number: string) {
        props.addEntities(true, [{...contractNumber, value: number}])
    }

    function today() {
        return $moment().format("LL")
    }

    function contractDateView() {
        return <div>
            <hr className={"newHR"}/>
            <div className="Body_17">
                От какого числа будет подписан договор?
            </div>

            <div className={"titleContainer"}>
                <RadioGroup
                    defaultValue={useCurrentDate}
                    onChange={(e, value) => {
                        updateDate(today())
                        setUseCurrentDate(value === "true")
                    }}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                        label={"Текущая дата"}
                    />
                    <FormControlLabel
                        value={false}
                        sx={{marginTop: "0px"}}
                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                        label={"Указать дату"}
                    />
                </RadioGroup>

                <EntityCopyButton
                    keyword={currentDate.keyword}
                    onCopy={() => {
                        props.addEntities(true, [contractSystemEntity(props.contract, currentDate.id)] ?? currentDate)
                        updateLocalEntities(props.contract, props.$store.userSession)
                    }}
                />
            </div>


            <div
                style={{marginTop: "10px"}}
            >
                {
                    makeDatePicker(
                        input,
                        isEditing,
                        setIsEditing,
                        currentDateEntity,
                        updateDate,
                        useCurrentDate
                    )
                }
            </div>
        </div>
    }

    function contractNumberView() {
        return <div>
            <hr className={"newHR"}/>
            <div className="Body_17">
                Номер договора
            </div>
            <div className={"titleContainer"}>
                <RadioGroup
                    defaultValue={autoincrementContractNumber}
                    onChange={(e, value) => {
                        setAutoincrementContractNumber(value === "true")
                        if (autoincrementContractNumber) {
                            updateContractNumber(props.contract.next_contract_number ?? props.userSession?.last_contract_number ?? "1")
                        } else {
                            if (!!contractNumberEntity.value) {
                                updateContractNumber(props.contract.next_contract_number)
                            }
                        }
                    }}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                        label={"Автоматически увеличивать номер договора на 1"}
                    />
                    <FormControlLabel
                        value={false}
                        sx={{marginTop: "0px"}}
                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                        label={"Указать номер договора"}
                    />
                </RadioGroup>

                <EntityCopyButton
                    keyword={contractNumber.keyword}
                    onCopy={() => {
                        props.addEntities(true, [contractSystemEntity(props.contract, contractNumber.id)] ?? contractNumber)
                        updateLocalEntities(props.contract, props.$store.userSession)
                    }}
                />
            </div>

            <div style={{marginTop: "15px"}}>
                <EntityInput
                    disabled={autoincrementContractNumber}
                    entity={contractNumberEntity}
                    updateEntity={updateContractNumber}
                />
            </div>
        </div>
    }

    return <>
        {contractDateView()}

        {contractNumberView()}
    </>
}

ContractNumberAndDateSection = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractNumberAndDateSection)

export default ContractNumberAndDateSection