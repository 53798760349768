import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {Tooltip, useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import {getSystemEntityById} from "../../../../methods/SystemEntity";
import {contractSystemEntityIsHidden} from "../../../../methods/systemEntityValueByKeyword";
import {CopyToClipboard} from "react-copy-to-clipboard";
import notifyUser from "../../../../methods/notifyUser";
import {ReactComponent as Copy} from "../../../Icons/size24/Copy.svg";

let EntityCopyButton: any = function EntityCopyButton(props: any) {
    const keyword = props.keyword

    return <CopyToClipboard
        onCopy={() => {
            notifyUser(props, 'Метка поля скопирована', 'Теперь вставьте метку в текст договора')
            if (props.onCopy) {
                props.onCopy()
            }
        }}
        text={`{{${keyword}}}`}
    >
        <Tooltip arrow={true} title={"Скопировать метку поля"}>
            <button className="new smallIconed">
                <Copy className="icon" fill="#D4D8E1"/>
            </button>
        </Tooltip>
    </CopyToClipboard>
}

EntityCopyButton = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EntityCopyButton)

export default EntityCopyButton