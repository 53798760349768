import {$moment, preparePhoneNumber, prepareSeveralPhoneNumbers} from "./funcs"
import {trackGoal} from "./methods/trackGoal";

declare global {
  interface vRule {
    rule: string,
    entity: string,
    displayFieldText?: string,
    required?: boolean
  }
}

export const validate = (rule: string, entity: string, displayFieldText?: string) => {

  let isValid = true

  if (rule === '' || !rule)
    return

  if (!entity)
    return `Поле "${displayFieldText || rule}" не заполнено`

  /* === Account === */

  if (rule === "role")
    isValid = ['customer', 'entrepreneur'].includes(entity)

  else if (['firstName', 'lastName'].includes(rule))
    isValid = entity.length > 0

  else if (rule === "middleName")
    isValid = true

  else if (rule === "notEmpty")
    isValid = entity.length > 0

  else if (rule === "email") {
    isValid = String(entity)
        .toLowerCase()
        .match(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        ) != null
    if (!isValid) {
      trackGoal("invalid_email", {email: entity})
    }
  }


  else if (rule === "password")
    isValid = (/^.{6,64}$/).test(entity)

  else if (rule === "ruPassportNumber")
    isValid = (/^\d{4}\s\d{6}$/).test(entity)

  else if (rule === "anotherPassportNumber")
    isValid = entity.length > 0

  else if (rule === 'ruPassportIssuedBy')
    isValid = (/^[a-zа-яё\d][a-zа-яё\d\s.-]{3,398}[a-zа-яё]$/i).test(entity)

  else if (rule === 'ruPassportIssuedByCode')
    isValid = (/^\d{3}\-\d{3}$/i).test(entity)

  else if (rule === 'ruPassportIssuedDidAt') {
    let date = $moment(entity, "DD.MM.YYYY")
    let oldestDate = $moment("1980-01-01")
    isValid = (/^\d{2}.\d{2}.\d{4}$/i).test(entity) && date.isValid() && date < $moment() && date > oldestDate
  }
  else if (rule === "ruTaxId") {
    isValid = (/^(\d{10}|\d{12})$/).test(entity)
  }

  else if (rule === "ruOgrn")
    isValid = (/^(\d{13}|\d{15})$/).test(entity)

  else if (rule === "ruKpp")
    isValid = (/^\d{9}$/).test(entity)

  else if (rule === "ruBic")
    isValid = (/^\d{9}$/).test(entity)

  else if (['ruBankAccount', 'ruBankCorrAccount'].includes(rule))
    isValid = (/^\d{20}$/).test(entity)

  else if ([
    'address',
    'lawAddress',
    'zipAddress'
  ].includes(rule))
    isValid = entity.length > 0

  else if (rule === "phoneNumber") {
    try {
      let phoneNumber = preparePhoneNumber(entity)
      isValid = phoneNumber.length >= 11
    } catch {
      isValid = false
    }
  }

  else if (rule === "severalPhoneNumbers")
    try {
      prepareSeveralPhoneNumbers(entity)
      isValid = true
    }
    catch (e) {
      isValid = false
    }

  else if (["bankName", "lawName"].includes(rule))
    isValid = entity.length >= 1 && entity.length <= 512

  // Contracts

  else if (rule === 'contractName')
    isValid = (/^.{1,512}$/i).test(entity)

  else if (rule === 'contractDirectiveKeyword')
    isValid = entity.length >= 1 && entity.length <= 512

  else if (rule === 'contractDirectiveDescription')
    isValid = entity.length >= 3 && entity.length <= 512

  else if (rule === 'contractDirectiveValue')
    isValid = entity.length >= 1 && entity.length <= 512

  else if (rule === 'contractDirectivesList') {

    try {
      let entities = JSON.parse(entity)

      let directives: string[] = []

      entities.forEach((entity: {
        keyword: string,
        value: string,
        description: string,
        type: string,
        list: string[],
      }, i: number) => {

        if (directives.includes(entity.keyword)) {
          displayFieldText = entity.keyword
          throw new Error(`Поле "${entity.keyword}" уже есть, попробуйте изменить название`)
        }

        if (validate('contractDirectiveKeyword', entity.keyword)) {
          displayFieldText = entity.keyword
          throw new Error(`Название поля "${entity.keyword}" должно быть от 1 до 512 символов, попробуйте изменить название`)
        }

        directives.push(entity.keyword)
      })

      isValid = true
    }
    catch (error: any) {
      isValid = false
      return error.message
    }
  }

  /* === System === */

  else if (rule === "boolean")
    isValid = ["true", "false"].includes(entity)

  else if (rule === "comment")
    isValid = !(/^[a-zа-яё\d\s.-]{1,509}[^\s]$/i).test(entity)

  else if ((/_id$/).test(rule))
    isValid = (/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i).test(entity)

  else if (rule === "searchQuery")
    // Для поиска допустимы любые символы, любая длина. Хз почему тут регулярка
    isValid = true//(/^[^\s][a-zа-яё\d\s.-]{1,509}[^\s]$/i).test(entity)

  else if ((/WillAt$/i).test(rule)) {

    isValid = true

    const date = new Date(entity)
    const now = new Date()

    if ((date.toString() === "Invalid Date")) {
      isValid = false
    }
    else if (+now - +date > 0) {
      isValid = false
    }

  }

  else if ((/DidAt$/i).test(rule)) {

    isValid = true

    console.log(entity)

    let dmy = entity.split(".");

    const date = new Date(parseInt(dmy[2]), parseInt(dmy[1]) - 1, parseInt(dmy[0]))
    const now = new Date()

    isValid = true
    // if ((date.toString() === "Invalid Date")) {
    //   isValid = false
    // }
    // else if (+now - +date < 0) {
    //   isValid = false
    // }
  }

  else if ((/_At$/).test(rule)) {

    let str = entity

    if ((/^\d{2}\.\d{2}.\d{4}$/).test(entity))
      str = entity.split('.').reverse().join('-')

    isValid = (new Date(str).toString() !== "Invalid Date")
  }

  else if (rule === 'positiveInteger')
    isValid = !(isNaN(+entity) || +entity < 0)

  else if (rule === 'verificationCode')
    isValid = (/^\d{4}$/).test(entity)

  if (isValid === null)
    return `No validation rule set for this entity: "${entity}"`

  if (isValid === false)
    return `Поле "${displayFieldText || rule}" заполнено не правильно`

  return ""
}

export const validateMany = (vRules: vRule[]): string[] => {
  let results: string[] = []
  for (let rule of vRules) {
    if (rule.required != false || (rule.entity && rule.entity.length > 0)) {
      let result = validate(rule.rule, rule.entity, rule.displayFieldText) ?? ""
      if (result.length > 0) {
        results.push(result)
      }
    }
  }
  return results
}