import {trackGoal} from "./trackGoal";

export const useLocalStorage = (name: string): Function[] => {
    const getLocalStorage = () => {
        const local = localStorage?.getItem(name)
        if (local != null && local) {
            return JSON.parse(local)
        }
        return null
    }
    const setLocalStorage = (item: Object) => {
        try {
            localStorage?.setItem(name, JSON.stringify(item))
        }
        catch (error) {
            console.log("Local Storage is full, Please empty data");
            trackGoal(
                "unhandled_error",
                {
                    unhandled_error_message: "Local Storage is full, Please empty data",
                    unhandled_error_stack: error
                }
            )
        }
    }
    const removeLocalStorage = () => {
        return localStorage?.removeItem(name)
    }
    return [getLocalStorage, setLocalStorage, removeLocalStorage]
}