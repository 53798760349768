import Popup from "../../components/popup";
import {connect} from "react-redux";
import React from "react";
import Editor from "../../components/Editor";
import {ReactComponent as DownloadFilled} from "../Icons/size24/DownloadDark.svg";
import {getToken} from "../../methods/saveToken";

type Props = {
    template: any,
    saveContract: Function,
    onClose: Function,
    $store: any
}

let ContractPreviewPopup: any = function ContractPreviewPopup(props: Props) {
    const {template, saveContract, onClose, $store} = props

    function download() {
        let token = getToken()
        if (token) {
            window.location.href = `${process.env.REACT_APP_API_URL}/templates/${template._id}/download?access_token=${token}`
        }
    }

    return <Popup
        style={{zIndex: 999}}
        onClose={onClose}
    >
        <div>
            <div className="titleContainer">
                <div>
                    <button className="new bigIconed" onClick={() => {download()}}>
                        <DownloadFilled className="icon"/>
                    </button>
                </div>
                <button className="new withTitle" onClick={() => {saveContract()}}>
                    Подписать и отправить
                </button>
            </div>

            <div className="editContractConstructor readOnly">
                <Editor readonly={true} body={template.body} />
            </div>
        </div>
    </Popup>
}

ContractPreviewPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractPreviewPopup)

export default ContractPreviewPopup