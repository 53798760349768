import React from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {CopyToClipboard} from "react-copy-to-clipboard";
import Popup from "../../components/popup";
import notifyUser from "../../methods/notifyUser";
import {connect} from "react-redux";
import {commonSelect, TextareaStyled, TextFieldStyled} from "../Styles/CommonCSSProperties";
import {ReactComponent as Copy} from "../Icons/size24/Copy.svg";
import {HelpView} from "../Templates/TemplatesHelpPopup";

type Props = {
    close: Function,
    amoConfig: any,
    $store: any,
    $commitToStore: Function,
}

let AmoCRMIntegrationPopup: any = function AmoCRMIntegrationPopup(props: Props) {
    function onCopy() {
        notifyUser(props, "Строка скопирована. Теперь вставьте ее в настройках виджета в amoCRM");
    }

    function makeStringView(title: string, value: string, placeholder: string = "") {
        return <div className="titleContainer" style={{marginTop: "15px", gap: "20px"}}>
            <div className="commonLabel" style={{width: "100%"}}>
                <div className="commonLabel" style={{width: "100%"}}>
                    {title}
                </div>

                <TextareaStyled
                    placeholder={placeholder}
                    style={{
                        marginTop: "10px",
                    }}
                    readOnly
                    value={value}
                />
                <CopyToClipboard
                    onCopy={() => {
                        onCopy()
                    }}
                    text={value}
                >
                    <button className="new withTitle _wa" style={{marginTop: "15px"}}>
                        <div className="buttonsContainer">
                            <Copy className="icon" style={{fill: "white"}}/>
                            <span>Скопировать ключ</span>
                        </div>
                    </button>
                </CopyToClipboard>
            </div>
        </div>
    }

    function apiKey() {
        return `${props.$store.userSession.realty_api_key}`
    }

    return <Popup
        maxWidth="650px"
        onClose={() => {
            props.close()
        }}
    >
        <div>
            <HelpView
                title={"Интеграция с amoCRM"}
                subtitle={"Интеграция добавляет виджет ОкиДоки в карточку сделки в amoCRM.\n" +
                    "Создавать договоры можно прямо из карточки сделки, при этом, есть возможность указать необходимый шаблон и карточку подписанта!\n" +
                    "Все изменения статусов договора будут добавлены как комментарии в карточку сделки. \n" +
                    "\n" +
                    "Подробнее о том, как работает интеграция и как ее настроить, смотрите в нашем видео"}
                videoId={"96dX3tVahPw"}
            />
            <hr className="newHR"/>
            <div className="Body_15" style={{textAlign: "left"}}>
                Чтобы настроить интеграцию с amoCRM - скопируйте ключ и вставьте его в настройках виджета и следуйте дальнейшим инструкциям
            </div>
            <div>
                {
                    makeStringView("Ключ API", apiKey())
                }

                <hr className="newHR"/>
                <button
                    className="new withTitle _wa"
                    style={{marginTop: "15px"}}
                    onClick={() => {
                        props.close()
                    }}
                >
                    <div className="buttonsContainer">
                        <span>Закрыть</span>
                    </div>
                </button>
            </div>
        </div>
    </Popup>
}

AmoCRMIntegrationPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(AmoCRMIntegrationPopup)

export default AmoCRMIntegrationPopup
