import {EntityInput} from "./EntityInput";
import React from "react";
import {connect} from "react-redux"
import {ReactComponent as Delete} from "pages/Icons/size24/Delete.svg";

export let EntityAdditionalValuesView: any = function Entity(props: any) {
    const {entity, updateEntity, myRef} = props

    if ((entity.value?.length == 0 && (entity.additionalValues ?? []).length == 0) || entity.editable == false) {
        return
    }

    return <>
        {
            (entity.additionalValues ?? []).map((value: string, i: number) => {
                return <div className="titleContainer" style={{marginTop: "15px", gap: "10px"}}>
                    <EntityInput
                        myRef={myRef}
                        entity={{
                            ...entity,
                            value: entity.additionalValues[i]
                        }}
                        updateEntity={(value: string) => {
                            let additionalValues = entity.additionalValues ?? []
                            if (i < additionalValues.length) {
                                additionalValues[i] = value
                                updateEntity(entity.value, additionalValues)
                            }
                        }}
                    />

                    <button
                        className="new"
                        onClick={() => {
                            entity.additionalValues.splice(i, 1)
                            updateEntity(entity.value, entity.additionalValues)
                        }}
                    >
                        <Delete className="icon dark"/>
                    </button>

                </div>
            })
        }

        <button
            className="new"
            style={{
                marginTop: "15px"
            }}
            onClick={() => {
                let additionalValues = entity.additionalValues ?? []
                additionalValues.push("")
                updateEntity(entity.value, additionalValues)
            }}
        >
            <div className="commonLabel" style={{color: "#33B5FF"}}>
                + Добавить значение
            </div>
        </button>
    </>
}

EntityAdditionalValuesView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EntityAdditionalValuesView)