import notifyUser from "methods/notifyUser";
import {getToken} from "../../methods/saveToken";

const downloadContract = (contract: any) => {
    let anchor = document.createElement("a");

    document.body.appendChild(anchor);
    let file = `${process.env.REACT_APP_API_URL}/contracts/${contract._id}/download`;

    let headers = new Headers();
    let token = getToken()
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    fetch(file, { headers })
        .then(response => {
            if (response.ok) {
                return response.blob()
            } else {
                // notifyUser(props, 'У вас нет прав скачивать этот договор')
                return Promise.reject(response.statusText)
            }
        })
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);

            anchor.href = objectUrl;
            anchor.download = `${contract.name}.pdf`;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        });
}

export default downloadContract