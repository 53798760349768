import React from 'react'
import { useRoute } from 'react-router5'
import { Chip, useMediaQuery } from '@mui/material'
import { connect } from "react-redux"
import { httpClient } from 'funcs'

import StoreProps from 'utils/types/storeProps'

let BalanceHolder: any = function (props: StoreProps) {
  
  const [balance, setBalance] = React.useState<any>()
  const mobile = useMediaQuery('(max-width: 760px)')

  React.useEffect(() => {
    if (typeof props.$store.balance == 'number') {
      setBalance(props.$store.balance)
    }
  }, [props.$store.balance])

  const style = mobile ? {
    position: 'fixed',
    bottom: '0',
    left: '50%',
    transform: 'translate(-50%, 0)',
    marginBottom: '90px',
    width: "auto",
    zIndex: '2',
    opacity: '100 ',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  } : {}

  return (typeof props.$store.balance == 'number'
          ? <Chip
              sx={{
                background: '#1FA3EE',
                color: "#FCFCFF",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "30px",
                ...style
              }}
              label={`Доступно договоров: ${balance}`}
          />
          : null
  )
}

BalanceHolder = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(BalanceHolder)


export default BalanceHolder