import React, { useEffect, useState } from "react"
import { useRoute } from "react-router5"
import { connect } from "react-redux"

import classnames from "classnames"

import "styles/main.sass"

import Icon from "./components/Icon"
import InstagramLogo from "./instagram-round-color-icon.svg"
import Nav from "./components/elements/Nav"
import MobileFooter from "./components/elements/MobileFooter"

import imgLogoFull from "images/logo-full.svg"
import imgLogoFullHighlighted from "images/logo-full-highlighted.svg"

import AuthForm from "pages/Auth/AuthForm"
import AnotherPage from "pages/Another"
import RegisterPage from "pages/Auth/Register"
import NewContractPage from "pages/EditContract/NewContract"
import ProfilePage from "pages/Profile/Profile"
import {Fab, useMediaQuery} from "@mui/material"
import isAuthorized from "utils/funcs/isAuthorized"
import BalanceHolder from "components/elements/nav/BalanceHolder"
import EditContractPage from "./pages/EditContract/EditContractPage";
import {ReactComponent as Close} from "./pages/Icons/size24/Close.svg";
import ContractViewPage from "./pages/Contracts/ContractView/ContractViewPage";
import ContractListPage from "./pages/Contracts/ContractList/ContractListPage";
import {LocalizationProvider, ruRU} from '@mui/x-date-pickers';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {SupportAgent} from "@mui/icons-material";
import {openInNewTab, supportLink} from "./methods/openInNewTab";
import WelcomePopup from "./pages/Profile/WelcomePopup";
import {getToken, saveToken} from "./methods/saveToken";
import {loadSelf} from "./methods/loadSelf";
import Popup from "./components/popup";
import UserStoryPopup from "./pages/Profile/UserStoryPopup";
import {trackGoal} from "./methods/trackGoal";
import StackTrace from "stacktrace-js";
import SharedTemplatePage from "./pages/ShareTemplate/SharedTemplatePage";
import CreatePDFPage from "./pages/EditContract/CreatePDF/CreatePDFPage";

let notificationTimeout: any = null

let App: any = function App(props: any) {
  window.addEventListener('error', function (event) {
    let error = event.error
    if (error) {
      console.log("unhandled_error.message", error.message)
      console.log("unhandled_error.stack", error.stack)

      StackTrace.fromError(error).then((err) => {
        console.log("StackTrace", err)
        trackGoal(
            "unhandled_error",
            {
              unhandled_error_message: error.message,
              unhandled_error_stack: err
            }
        )
      })
    }
  })

  const $router = useRoute()

  const [location, setLocation] = useState(window.location)

  const [shouldBeHeaderHightlighted, setShouldBeHeaderHightlighted] = useState(location.pathname === '/')
  const [shouldBeHeaderFixed, setShouldBeHeaderFixed] = useState(location.pathname === '/')
  const [userStoryPopup, setUserStoryPopup] = useState<any>(null)
  
  const [mobileNavShown, setMobileNavShown] = useState(false)

  // User redirection
  useEffect(() => {
    const isMainOrRegisterPage = ['main', 'register'].includes($router.route.name)

    const userHasSession = getToken() != null

    if ($router.route.name == "invite") {
      props.$commitToStore({
        inviteRoleId: $router.route.params.roleId
      })
      if (userHasSession) {
        $router.router.navigate('profile')
      } else {
        $router.router.navigate('register')
      }
      return
    }

    if (!userHasSession && !isMainOrRegisterPage) {
      if (['contract'].includes($router.route.name)) {
        $router.router.navigate(
            'contract',
            {
              contractId: $router.route.params.contractId,
              is_first_signer: $router.route.params.is_first_signer,
            }
        )
      } else {
        setTimeout(() => { $router.router.navigate('main') }, 0)
      }
    } else if (userHasSession && isMainOrRegisterPage) {
      setTimeout(() => { $router.router.navigate('profile') }, 0)
    }
  }, [])

  // If authed, set bearer authentication for API requests
  useEffect(() => {
    let token = getToken()
    if (token) {
      saveToken(token)

      loadSelf(props)
    }
  }, [])

  useEffect(() => {
    if (props.$store.signedOut) {
      $router.router.navigate('main')
      props.$commitToStore({
        signedOut: false
      })
    }
  }, [props.$store.signedOut])

  useEffect(() => {
    let user_stories = props.$store.userSession?.user_stories
    if (user_stories) {
      let notShownStory = user_stories.filter((story: any) => story.shown == false)[0]
      if (notShownStory) {
        setUserStoryPopup(notShownStory)
      }
    }
  }, [props.$store.userSession?.user_stories])

  useEffect(() => {
    setShouldBeHeaderHightlighted($router.route.name === 'main')
    setShouldBeHeaderFixed($router.route.name === 'main')
  }, [$router])

  // Handle notifications
  useEffect(() => {

    if (!props.$store.notification) return

    clearTimeout(notificationTimeout)

    notificationTimeout = setTimeout(() => {
      props.$commitToStore({
        notification: null
      })
    }, 5000)
  }, [props.$store.notification])

  const isDesk = useMediaQuery('(min-width:760px)')
  function showMobileFooter(): boolean {
    if (!tokenLoaded() ||
        $router.route.name === 'main' ||
        $router.route.name === 'contract' ||
        ($router.route.name === 'new-contract' && $router.route.params.act === 'new') ||
        isDesk
    ) {
      return false
    }
    return true
  }

  function showDesktopFooter() {
    return $router.route.name !== 'new-contract' &&
        $router.route.name !== 'main' &&
        $router.route.name !== 'contract' &&
        isDesk
  }

  function makeDesktopFooter() {
    return <footer>

      {/* Footer left */}

      <div className="__left">
        <img src={imgLogoFull} className="footer-logo" alt="OkiDoki" />

        <div className="social-links">
          <span>Мы в социальных сетях:</span>

          <a href="https://www.instagram.com/try_oki_doki/">
            <img style={{width: "30px", height: "30px"}} src={InstagramLogo}/>
          </a>

          <a href="https://t.me/okidoki_digital_docs">
            <div style={{display: "flex"}}>
              <Icon icon="telegram-4" className="__telegram" />
            </div>
          </a>

          <a href="https://vk.com/public215560534" >
            <div style={{display: "flex"}}>
              <Icon icon="vk-4" className="__vk" />
            </div>
          </a>
        </div>
      </div>

      {/* Footer right */}

      <div className="__right">
        <div className="copyright-i">
          Copyright &copy; 2021-2023 OkiDoki. Все права защищены
        </div>
      </div>
    </footer>
  }

  function tokenLoaded() {
    return getToken() != null
  }

  // Render
  return (
      <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={"ru"}
          localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <>

        {/* Header */}

        {$router.route.name !== 'main' && <>
          <header className={classnames({
            _fixed: shouldBeHeaderFixed,
            _highlighted: shouldBeHeaderHightlighted
          })}>

            <div className="__left">
              <img src={shouldBeHeaderHightlighted ? imgLogoFullHighlighted : imgLogoFull}
                   alt="OkiDoki logo"
                   className="header-logo"
                   onClick={() => {
                      $router.router.navigate(isAuthorized() ? 'profile' : 'main')
                   }}
              />
            </div>

            <div className="__right">
              <Nav />
            </div>
          </header>
          <div className={classnames('mobile-nav', { _shown: mobileNavShown })} onClick={() => setMobileNavShown(false)}>
            <Nav onClick={() => setMobileNavShown(false)} />
          </div>
        </>}

        {
          tokenLoaded() && props.$store.showWelcomePopup && !props.$store.inviteRoleId &&
          <WelcomePopup
              userSession={props.$store.userSession}
              $commitToStore={props.$commitToStore}
          />
        }

        {/* Pages */}
        <main>
          {$router.route.name === 'main' && <AuthForm />}
          {$router.route.name === 'register' && <RegisterPage />}
          {$router.route.name === 'invite' && <RegisterPage />}

          {tokenLoaded() && $router.route.name === 'another' && <AnotherPage />}
          {tokenLoaded() && $router.route.name === 'main' && <NewContractPage />}
          {tokenLoaded() && $router.route.name === 'new-contract' && <NewContractPage />}
          {tokenLoaded() && $router.route.name === 'signed-contracts' && <ContractListPage />}
          {/*{tokenLoaded() && $router.route.name === 'template' && <EditContractPage />}*/}
          {$router.route.name === 'contract' && <ContractViewPage />}
          {tokenLoaded() && $router.route.name === 'profile' && <ProfilePage />}
          {tokenLoaded() && $router.route.name === 'profile.update' && <ProfilePage />}
          {tokenLoaded() && $router.route.name === 'shared-template' && <SharedTemplatePage />}
          {tokenLoaded() && $router.route.name === 'new-pdf-contract' && <CreatePDFPage />}

          <Fab
              sx={{
                zIndex: 1,
                position: "fixed",
                bottom: showMobileFooter() ? "93px" : "20px",
                right: "20px",
                background: "#33B5FF",
                '&:hover': {
                  backgroundColor: '#1FA3EE',
                },
              }}
              onClick={() => {openInNewTab(supportLink())}}
          >
            <SupportAgent
                style={{fill: "white", width: "35px", height: "35px"}}
            />
          </Fab>
        </main>


        {/* Footer */}

        {
            showDesktopFooter() &&
            makeDesktopFooter()
        }

        {
          showMobileFooter() && <BalanceHolder/>
        }
        {
          showMobileFooter() && <MobileFooter/>
        }

        <div className={classnames('notification', {
          _hidden: !props.$store.notification
        })}>
          {
              props.$store.notification &&
              <>
                <div className="titleContainer">
                  <div></div>
                  <button
                      className="new smallIconed close-button"
                      onClick={() => {
                        props.$commitToStore({
                          notification: null
                        })
                      }}
                  >
                    <Close className="icon" style={{stroke: "#75809E"}} />
                  </button>
                </div>
                <div className="commonLabel" style={{marginTop: "-20px", marginRight: "60px"}}>
                  {props.$store.notification.title}
                </div>
                {props.$store.notification.content && (
                    <div className="commonSubtitle">
                      {props.$store.notification.content}
                    </div>
                )}
              </>
          }
        </div>

        {/*Временно отключаю онбординг на странице шаблонов*/}
        {/*{*/}
        {/*  $router.route.name === 'contract-templates' && showInstructions && showInstructions.includes('templatesInstruction') &&*/}
        {/*  (*/}
        {/*    <ContractTemplatePageInstruction {...props} run={true} instructionName='templatesInstruction' />*/}
        {/*  )*/}
        {/*}*/}

          {
            userStoryPopup &&
              <UserStoryPopup
                  story={userStoryPopup}
                  onClose={() => {
                    setUserStoryPopup(false)
                  }}
              />
          }
      </>
      </LocalizationProvider>
  )
}

App = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(App)

export default App;