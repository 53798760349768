import { connect } from "react-redux"

import _ from "lodash"
import "styles/components/popup.sass"


export const BANK_DETAIL_FIELDS = [
  {
    key: "bank_name",
    label: "Название банка",
    mask: "",
  },
  {
    key: "bank_account",
    label: "Расчетный счет",
    mask: "99999999999999999999",
  },
  {
    key: "corr_account",
    label: "Корр/счет",
    mask: "99999999999999999999",
  },
  {
    key: "bic",
    label: "БИК",
    mask: "999999999",
  },
]

function userHasBankFields(userSession: any) {
  return BANK_DETAIL_FIELDS.filter(({key}: any) => { return userSession[key]})
}

let BankDetailsView: any = function EntrepreneurBankDetails(props: any) {
  const userSession = props.$store.userSession;

  if (userHasBankFields(userSession).length > 0) {
    return <div className={"templateViewContainer disabledHover"}>
      <div className="commonLabel" style={{textAlign: "left"}}>
        Реквизиты организации:
      </div>

      {
        BANK_DETAIL_FIELDS
            .filter(({key}: any) => {
              return userSession[key]?.length > 0
            })
            .map(({key, label}: any) => {
              return (
                  <div className="common" style={{marginTop: "20px"}}>
                    <div className="commonSubtitle" style={{display: "flex", alignItems: "center"}}>{label}:</div>
                    <div className="commonRegular15">{userSession[key]}</div>
                  </div>
              )
            })
      }
    </div>
  }

  return null
}

BankDetailsView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
      $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(BankDetailsView)

export default BankDetailsView
