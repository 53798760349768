import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {ReactComponent as CheckboxIcon} from "../../Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxChecked} from "../../Icons/size24/CheckboxChecked.svg";
import PassportSettingsView from "./PassportSettingsView";
import NeedConfirmationView from "./NeedConfirmationView";
import StoreClientDataView from "./StoreClientDataView";
import ChooseWhoCanFillView from "./Views/ChooseWhoCanFillView";
import {clientPassportNumber, clientPassportRegistration, clientRole} from "../../../methods/SystemEntity";
import ChooseTargetUserRoleView from "./Views/ChooseTargetUserRoleView";
import SectionHeader from "../SectionHeader";
import _ from "lodash";
import {contractSystemEntity, contractSystemEntityIsHidden} from "../../../methods/systemEntityValueByKeyword";
import {clientRoles} from "../../Auth/UserForms/ChooseAccountTypeFrom";
import CustomerPersonalDataView from "./CustomerPersonalDataView";
import ClientContactDataView from "./ClientContactDataView";
import EntrepreneurRequestedBankDataView from "./EntrepreneurRequestedBankDataView";
import EntrepreneurRequestedPersonalDataView from "./EntrepreneurRequestedPersonalDataView";

type Props = {
    contract: any,
    close: Function,
    updateContractSettings: Function,
    updateEntity: Function,
    addEntities: Function,
    deleteEntities: Function,
}

let RequestedClientDataSection: any = function ContractRequestedClientDataSection(props: Props) {
    const {contract} = props
    const settings = contract.settings ?? {}

    function sectionTitle(section: string) {
        switch (section) {
            case 'requested_client_data.fio':
                return "ФИО клиента"
            case 'requested_client_data.law_data':
                return "Данные организации"
            case 'requested_client_data.passport':
                return "Настройки паспорта"
            case 'requested_client_data.bank_data':
                return "Реквизиты организации"
            case 'requested_client_data.contacts':
                return "Контактные данные"
            case 'requested_client_data.need_confirmation':
                return "Проверять введённые клиентом данные?"
            case 'requested_client_data.store_client_data':
                return "Разрешать клиентам не хранить персональные данные в ОкиДоки?"
            default:
                return ''
        }
    }

    function sectionExpanded(sectionId: string) {
        return _.get(settings, sectionId, false)
    }

    function toggleSectionExpanded(sectionId: string) {
        let updatedSettings = {...settings}
        let expanded = sectionExpanded(sectionId)
        _.set(updatedSettings, sectionId, !expanded)
        props.updateContractSettings(updatedSettings)
    }

    function sectionHeader(sectionId: string, element: JSX.Element) {
        return <SectionHeader
            sectionId={sectionId}
            title={sectionTitle(sectionId)}
            expanded={sectionExpanded(sectionId)}
            toggleSectionExpanded={toggleSectionExpanded}
            body={element}
            secondLayer={true}
        />
    }

    const clientRoleEntity = contractSystemEntity(contract, clientRole.id) ?? clientRole

    function targetUserRoleView() {
        return <div style={{marginBottom: "5px"}}>
            <ChooseTargetUserRoleView
                entity={clientRoleEntity}
                contract={contract}
                addEntities={props.addEntities}
            />
        </div>
    }

    function customerPersonalDataView() {
        return <CustomerPersonalDataView
            contract={contract}
            addEntities={props.addEntities}
        />
    }

    function passportView() {
        return <div>
            <hr className={"newHR"}/>

            <PassportSettingsView
                contract={contract}
                addEntities={props.addEntities}
                deleteEntities={props.deleteEntities}
            />
        </div>
    }

    function entrepreneurPersonalDataView() {
        return <div>
            <hr className={"newHR"}/>

            <EntrepreneurRequestedPersonalDataView
                contract={contract}
                addEntities={props.addEntities}
            />
        </div>
    }

    function entrepreneurBankDataView() {
        return <div>
            <hr className={"newHR"}/>

            <EntrepreneurRequestedBankDataView
                contract={contract}
                addEntities={props.addEntities}
            />
        </div>
    }

    function clientContactDataView() {
        return <ClientContactDataView
            contract={contract}
            addEntities={props.addEntities}
        />
    }

    function needConfirmationView() {
        return <NeedConfirmationView
            settings={settings}
            updateContractSettings={props.updateContractSettings}
        />
    }

    function storeClientDataView() {
        return <StoreClientDataView
            contract={contract}
            addEntities={props.addEntities}
        />
    }

    return <>
        <div>
            <hr className="newHR"/>

            {
                targetUserRoleView()
            }

            <div className={"sectionsContainer"}>
                {
                    (clientRoleEntity.value == clientRoles[1] || clientRoleEntity.editable) &&
                    sectionHeader("requested_client_data.fio", customerPersonalDataView())
                }
                {
                    (clientRoleEntity.value == clientRoles[1] || clientRoleEntity.editable) &&
                    sectionHeader("requested_client_data.passport", passportView())
                }
                {
                    (clientRoleEntity.value == clientRoles[0] || clientRoleEntity.editable) &&
                    sectionHeader("requested_client_data.law_data", entrepreneurPersonalDataView())
                }
                {
                    (clientRoleEntity.value == clientRoles[0] || clientRoleEntity.editable) &&
                    sectionHeader("requested_client_data.bank_data", entrepreneurBankDataView())
                }
                {sectionHeader("requested_client_data.contacts", clientContactDataView())}
                {sectionHeader("requested_client_data.need_confirmation", needConfirmationView())}
                {sectionHeader("requested_client_data.store_client_data", storeClientDataView())}
            </div>
        </div>
    </>
}

RequestedClientDataSection = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(RequestedClientDataSection)

export default RequestedClientDataSection
