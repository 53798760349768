import { connect } from "react-redux"
import { useRoute } from "react-router5"

import { useEffect } from "react"
import EditContractPage from "./EditContractPage";
import TemplatesPage from "../Templates/TemplatesPage";

let NewContractPage: any = function NewContractPage() {

  const $router = useRoute()

  // On mounted
  useEffect(() => {

    // Set default act
    if (!['new', 'list'].includes($router.route.params.act))
      // window.location.href = `${$router.router.buildPath('new-contract')}?act=list`
      $router.router.navigate('new-contract', {
        act: 'list'
      }, { reload: true })

  }, [])

  // Render
  return (
    <div className="NewContractPage">
      {$router.route.params.act === 'new' && <EditContractPage />}
      {$router.route.params.act === 'list' && <TemplatesPage />}
    </div>
  )
}

NewContractPage = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(NewContractPage)

export default NewContractPage
