import notifyUser from "./notifyUser";
import {getPostfixForEntity} from "./utils";


export function checkChangesInBody(props: any, template: any): any {
    let changedValue: string | null = null

    let node = window.getSelection ? window.getSelection()?.focusNode : undefined
    let selectedElement = node?.parentElement

    for (let entity of template.entities.concat(template.system_entities)) {
        let name = `<<${entity.keyword}>>`
        document.getElementsByName(name).forEach((element: HTMLElement) => {
            if (element === selectedElement && changedValue == null) {
                notifyUser(props, 'Предупреждение', `Поле "${entity.keyword}" можно редактировать только в колонке "Поля"`)
                // let elementId = `input.${entity.keyword}`
                // let inputView = document.getElementById(elementId)
                // if (inputView) {
                //     console.log("inputView", inputView)
                //     highlightElement(inputView)
                // }
            }
        })
    }

    return template
}
