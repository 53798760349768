import {connect} from "react-redux";
import {commonSelect, commonTextField, TextareaStyled, TextFieldStyled} from "../Styles/CommonCSSProperties";
import {$moment} from "../../funcs";
import FormControl from "@mui/material/FormControl";
import {Autocomplete, CircularProgress} from "@mui/material";
import {ReactComponent as Upload} from "../Icons/size24/Upload.svg";
import {ReactComponent as RefreshFilled} from "../Icons/size24/RefreshFilled.svg";
import {ReactComponent as Delete} from "../Icons/size24/Delete.svg";
import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import imageCompression from "browser-image-compression";
import notifyUser from "../../methods/notifyUser";
import {formatBytes} from "../../methods/formatBytes";
import {DatePicker, PickersDay} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale'
import {IMaskInput} from "react-imask";
import {MobileInputMask} from "../../components/elements/InputWithMask";
import {trackGoal} from "../../methods/trackGoal";
import {clientPassportNumber} from "../../methods/SystemEntity";

type Props = {
    myRef: {current: {}},
    entity: any,
    disabled?: boolean,
    updateEntity: Function,
    setImageStringTag: boolean,
    maxWidthOrHeight: number,
}


function defaultDateValue(entity: any) {
    let date = $moment(entity.value, "LL")
    if (date) {
        return dayjs(date.format("MM/DD/YYYY"))
    } else {
        return null
    }
}

function makeDatePicker(input: any, isEditing: any, setIsEditing: any, entity: any, updateEntity: any, disabled: boolean = false) {
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        weekStart: 1,
    })

    return <DatePicker
        inputRef={input}

        slots={{
            // Override default <ActionBar /> with a custom one
            day: PickersDay,
        }}

        dayOfWeekFormatter={(day) => {
            switch (day) {
                case "Su":
                    return "Вс"
                case "Mo":
                    return "Пн"
                case "Tu":
                    return "Вт"
                case "We":
                    return "Ср"
                case "Th":
                    return "Чт"
                case "Fr":
                    return "Пт"
                case "Sa":
                    return "Сб"
                default:
                    return day
            }
        }}
        slotProps={{
            // pass props `actions={['clear']}` to the actionBar slot
            day: { showDaysOutsideCurrentMonth: true },
        }}
        disabled={disabled}
        format={"DD.MM.YYYY"}
        value={defaultDateValue(entity)}
        sx={{
            border: isEditing ? "1px solid #75809E !important" : "none",
            background: isEditing ? "white !important" : "#EAECF3",
            padding: isEditing ? "2.5px !important" : "3.5px",
            width: "100%"
        }}
        onOpen={() => {
            setIsEditing(true)
        }}
        onClose={() => {
            setIsEditing(false)
        }}
        onChange={(value: any) => {
            value = value ?? ""
            let date = $moment(`${value.$D}.${value.$M + 1}.${value.$y}`, "DD.MM.YYYY")
            let formattedDate = ""
            if ((date.toString() === "Invalid Date")) {
                formattedDate = ""
            } else {
                formattedDate = date.isValid() ? date.format('LL') : ""
            }
            setTimeout(() => {
                updateEntity(formattedDate)
            }, 100);
        }}
    />
}


let EntityInput: any = function EntityInput(props: Props) {
    const {myRef, disabled, entity, updateEntity, setImageStringTag=true, maxWidthOrHeight=1024} = props

    useImperativeHandle(myRef,
        () => focusInput,
        []
    )

    const [isEditing, setIsEditing] = useState(false)

    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);

    const photoUploadRef = useRef<HTMLInputElement>(null);
    const input = useRef<HTMLInputElement>(null);
    const textInput = useRef<HTMLTextAreaElement>(null);
    const maskInput = useRef(null);

    async function getBase64(file: any) {
        let errorMessage = 'Не удалось загрузить фото. Попробуйте загрузить другое фото или взять из галлереи/камеры. Так же стоит проверить разрешение браузера на доступ к галереи и наличие свободного места на устройстве. Если проблема сохранится - попробуйте другой браузер или другое устройство'
        if (!file) {
            notifyUser(props, errorMessage)
            trackGoal("take_photo_error")
        }

        let compressedFile: any
        try {
            compressedFile = await imageCompression(file, { maxSizeMB: 0.3, maxWidthOrHeight: maxWidthOrHeight });
        } catch (error) {
            setLoading(false)
            notifyUser(props, errorMessage)
            // @ts-ignore
            trackGoal("take_photo_compression_error", { photo_error: error?.currentTarget?.error?.message ?? 'another' })
            return
        }

        let reader = new FileReader();
        reader.onload = () => {
            setLoading(false)
            let element = setImageStringTag ?
                `<div style='max-width: 80%; margin-left: auto; margin-right: auto; display: block; margin-bottom: 15px; page-break-inside: avoid;'><img style='max-width: 100%; max-height: 450px; width: auto; height: auto; margin-left: auto; margin-right: auto; display: block; page-break-inside: avoid; border-radius: 10px' src='${reader.result}'  alt="Фото"/></div>`
                : reader.result
            updateEntity(element)
        }
        reader.onerror = (error) => {
            setLoading(false)
            notifyUser(props, errorMessage)
            trackGoal("take_photo_file_reader_error", { photo_error: error })
        }

        reader.readAsDataURL(compressedFile);
    }

    function focusInput() {
        switch (entity.type) {
            case "Текст":
                textInput?.current?.focus()
                // @ts-ignore
                maskInput?.current?.element?.focus()
                break
            case "Фото":
                photoUploadRef.current?.click()
                break
            case "Дата":
                input.current?.focus()
                break
            case "Список":
                input.current?.focus()
                break
            default:
                input.current?.focus()
        }
    }

    function processSelectionOfEntity(entity: any) {
        let kw = `<<${entity.keyword}>>`;
        document.getElementsByName(kw).forEach((element: HTMLElement) => {
            element.style.setProperty("border", "1px solid #DE9B94");
            element.style.setProperty("transition-duration", "200ms");
            element.style.setProperty("transition-property", "background");
            element.style.setProperty(
                "background",
                isEditing ? "#DE9B94" : "None",
            )
        })
    }

    useEffect(
        () => {
            processSelectionOfEntity(entity)
        },
        []
    )

    useEffect(
        () => {
            setTimeout(() => {
                processSelectionOfEntity(entity)
            }, 50);
        },
        [isEditing]
    )

    function makeInput() {
        switch (entity.type) {
            case "Текст":
                return makeTextInput()
            case "Список":
                return makeSelectInput()
            case "Фото":
                if (entity.value) {
                    return makePhotoView()
                } else {
                    return makePhotoInput()
                }
            case "Дата":
                return makeDatePicker(input, isEditing, setIsEditing, entity, updateEntity)
            case "Формула":
                return makeFormulaView()
            default:
                return null
        }
    }


    function makeTextInput() {
        if (entity.needNumberToWords) {
            return <IMaskInput
                ref={maskInput}
                placeholder={"Введите число..."}
                type="decimal"
                style={commonTextField()}
                value={entity.value?.replaceAll('.', ',') ?? ''}
                onFocus={({target: {value}}: any) => {
                    setIsEditing(true)
                }}
                onBlur={({target: {value}}: any) => {
                    setIsEditing(false)
                }}
                mask={Number}
                disabled={false}
                onAccept={(value, mask) => {
                    updateEntity(value)
                }}
            />
        } else {
            return <TextareaStyled
                ref={textInput}
                value={entity.value}
                disabled={disabled}
                placeholder={"Введите значение..."}
                onFocus={({target: {value}}: any) => {
                    setIsEditing(true)
                }}
                onBlur={({target: {value}}: any) => {
                    setIsEditing(false)
                }}
                onChange={({target: {value}}: any) => {
                    updateEntity(value)
                }}
            />
        }
    }

    function makeFormulaView() {
        return <TextareaStyled
            ref={textInput}
            value={entity.value}
            disabled={true}
            placeholder={"Автоматически вычисляется"}
        />
    }

    function makeSelectInput() {
        return <FormControl sx={{width: "100%"}}>
            <Autocomplete
                freeSolo
                disablePortal
                handleHomeEndKeys={true}

                inputValue={entity.value}
                className="classicStyleMuiSelect"
                options={entity.list}
                sx={commonSelect()}
                onFocus={({target}) => {
                    setIsEditing(true)
                }}
                onBlur={({target}) => {
                    setIsEditing(false)
                }}
                onInputChange={(e, value, reason) => {
                    console.log("onInputChange", value, reason)
                    updateEntity(value)
                }}
                renderInput={(params) => (
                    <TextFieldStyled
                        inputRef={input}
                        {...params}
                        multiline
                        maxRows={1}
                        sx={{
                            border: isEditing ? "1px solid #75809E !important" : "none",
                            background: isEditing ? "white !important" : "#EAECF3",
                            padding: isEditing ? "2.5px !important" : "3.5px",
                        }}
                        variant="outlined"
                        placeholder={"Введите или выберите значение... "}
                    />
                )}
            />
        </FormControl>
    }

    function makePhotoInput() {
        return <div>
            <input
                type="file"
                accept="image/*"
                ref={photoUploadRef}
                style={{display: "none"}}
                onChange={({target: {files}}) => {
                    setLoading(true)
                    const file =
                        files && files.length ? files[0] : null;
                    let name = file?.name ?? "";
                    setFileName(name)
                    getBase64(file)
                }}
            />
            <button
                className="new withTitle secondary _wa"
                style={{height: "60px"}}
                onClick={(e) => {
                    photoUploadRef.current?.click()
                }}
            >
                <div
                    className="buttonsContainer"
                    style={{justifyContent: "center"}}
                >
                    {
                        loading
                            ? <CircularProgress size={"25px"} sx={{color: "#1FA3EE"}} />
                            : <Upload className="icon" style={{fill: "#0E1F4D"}}/>
                    }
                    <span>Выбрать фото</span>
                </div>
            </button>
        </div>
    }

    function makePhotoView() {
        return <div className="titleContainer framed" style={{height: "60px"}}>
            <div className="buttonsContainer">
                {
                    loading && <CircularProgress sx={{color: "#1FA3EE"}} />
                }
                {
                    !loading &&
                    <button
                        className="new smallIconed"
                        onClick={() => {
                            setFileName("")
                            updateEntity("")
                        }}
                    >
                        <Delete className="icon dark"/>
                    </button>
                }

                {
                    !loading &&
                    <div className="commonLabel hideOverflow" style={{maxWidth: "120px"}}>
                        {fileName}
                    </div>
                }
            </div>
            <div className="commonSubtitle">
                {formatBytes((entity.value?.toString() ?? "").length ?? 0, 0)}
            </div>
        </div>
    }

    return makeInput()
}

EntityInput = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EntityInput)

export {EntityInput, makeDatePicker}
