import React from "react";
import {$moment} from "../../funcs";
import {connect} from "react-redux";

let MyTariffView: any = function MyTariffView(props: any) {
    let {tariff} = props

    const tariffType = tariff?.type ?? 'package'

    function tariffBG(): string {
        return "#33B5FF"
    }

    function tariffNameBG(): string {
        return "#16A6F8"
    }

    function hrBG(): string {
        return "#75CDFF"
    }

    if (!tariff) {
        return <></>
    }

    return <div>
        <div
            key={tariff._id}
            style={{background: tariffBG()}}
            className={"tariff"}
        >
            <div
                className="name"
                style={{background: tariffNameBG(), display: "inline-block"}}
            >
                {tariffType == 'package' ? 'Пакетная система' : tariff.name}
            </div>

            <div className="price" style={{marginTop: "10px"}}>
                {
                    tariffType == 'package' &&
                    <div>
                        {`Доступно договоров: ${props.$store.balance ?? "--"}`}
                        <div style={{marginTop: "5px", fontWeight: 400, opacity: 0.9}}>
                            Баланс бессрочный, никогда не сгорит. Пополнить баланс вы можете ниже
                        </div>
                    </div>

                }
                {
                    tariffType == 'subscription' &&
                    `${tariff.price/100} рублей ${tariff.period == 'month' ? 'в месяц' : 'в год'}`
                }
            </div>

            {
                tariffType == 'subscription' && props.$store.userSession?.tariff?._id == tariff._id && props.$store.userSession.next_subscription_charge_date &&
                <div>
                    <hr className="newHR" style={{background: hrBG()}}/>

                    <div className="commonSubtitle" style={{color: "#FFFFFF"}}>
                        Следующее списание: {$moment(props.$store.userSession.next_subscription_charge_date).format('LL')}
                    </div>
                </div>
            }

        </div>
    </div>
}

MyTariffView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(MyTariffView)

export default MyTariffView