import React from "react";

/**
 *
 * @param value - any value that we need do listen
 * @param delay ms
 *
 *
 * @description returning value when debounce timer ends
 *
 * We getting last state of our value
 *
 */

export default function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = React.useState<any>(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}
