import React, {useState} from "react";
import Popup from "../../components/popup";
import {connect} from "react-redux";

type Props = {
    users: any[],
    selectUser: Function,
}

let SelectUserPopup: any = function EntityFromPopup(props: Props) {
    const {users, selectUser} = props

    function userName(user: any) {
        if (user.role == 'customer') {
            return user.last_name + ' ' + user.first_name + ' ' + user.middle_name
        }

        if (user.role == 'entrepreneur') {
            return user.law_name
        }

        return ''
    }
    return <Popup
        style={{zIndex: 999}}
        background={"#F9F9FB"}
    >
        <div>
            <div className="commonLabel" style={{marginTop: "25px", textAlign: "center"}}>
                Выберите пользователя для авторизации
            </div>
            <div className="contractList" style={{marginTop: "15px"}}>
                {
                    users.map((user) => {
                        return <div
                            className="templateViewContainer"
                            onClick={(e) => {
                                selectUser(user)
                            }}
                        >
                            <div className="templateTitleContainer">
                                <div>
                                    <div className="title">
                                        {userName(user)}
                                    </div>
                                    <div className="Body_13 comment">
                                        {user.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </Popup>
}

SelectUserPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(SelectUserPopup)

export default SelectUserPopup