import React, { useState } from "react"
import { connect } from "react-redux"
import "styles/pages/profile.sass"
import {httpClient} from "../../funcs";
import {openInNewTab} from "../../methods/openInNewTab";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Refresh, SyncDisabled} from "@mui/icons-material";
import {ReactComponent as Delete} from "../Icons/size24/Delete.svg";
type Props = {
    $store: any,
    $commitToStore: Function
}

enum TGBotStatus {
    awaitingConfirmation = "AWAITING_CONFIRMATION",
    confirmed = "CONFIRMED"
}

let TGBotIntegrationView: any = function TGBotIntegrationView(props: Props) {

    async function requestCodeForTGBot() {
        httpClient.post('/integrate-tg-bot').then(({ data }) => {
            let tg_contacts = tgContacts()
            tg_contacts.push(
                {
                    status: "AWAITING_CONFIRMATION",
                    code: data.code
                }
            )

            props.$commitToStore({
                userSession: {
                    ...props.$store.userSession,
                    tg_contacts: tg_contacts
                },
            })
        }).catch((error) => {
            notifyAboutError(props, error)
        })
    }
    async function turnOffTGBot(contact: any) {
        httpClient.post(
            '/turn-off-tg-bot',
            {
                contact
            }
        ).then(({ data }) => {
            props.$commitToStore({
                userSession: {
                    ...props.$store.userSession,
                    tg_contacts: data
                },
            })
        }).catch((error) => {
            notifyAboutError(props, error)
        })
    }

    function tgContacts() {
        return props.$store.userSession?.tg_contacts ?? []
    }

    function makeContactView(contact: any) {
        if (!contact) {
            return
        }
        if (contact.status == TGBotStatus.awaitingConfirmation) {
            return <div>
                <div className="row-data">
                    <div>
                        <div>
                            <div className="commonSubtitle">
                                Отправьте боту <a href={`https://t.me/${process.env.REACT_APP_TG_AUTH_BOT_NAME}`} target="_blank">@{process.env.REACT_APP_TG_AUTH_BOT_NAME}</a> ключ:
                            </div>
                            <div className={"buttonsContainer"}>
                                <div className="commonLabel">
                                    {contact.code}
                                </div>
                                <button
                                    className="new smallIconed"
                                    onClick={() => {
                                        turnOffTGBot(contact)
                                    }}
                                >
                                    <Delete className="icon dark"/>
                                </button>
                            </div>

                        </div>
                        <div className="buttonsContainer" style={{marginTop: "10px"}}>
                            <CopyToClipboard
                                onCopy={() => {
                                    notifyUser(props, "Ключ скопирован, теперь отправьте его в бот. Телеграм откроется в течении 4 секунд");
                                    setTimeout(() => {
                                        openInNewTab(`https://t.me/${process.env.REACT_APP_TG_AUTH_BOT_NAME}`)
                                    }, 4000)

                                }}
                                text={contact.code}
                            >
                                <button
                                    className="new withTitle secondary small"
                                >
                                    Скопировать ключ
                                </button>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <hr className="newHR"/>
            </div>
        }

        if (contact.status == TGBotStatus.confirmed) {
            return <div>
                <div className={"buttonsContainer"}>
                    {
                        contact.first_name &&
                        <div className={"commonSubtitle"}>
                            {contact.first_name}
                        </div>
                    }
                    {
                        contact.username &&
                        <a href={`https://t.me/${contact.username}`} target="_blank">@{contact.username}</a>
                    }
                    {
                        !contact.first_name && !contact.username &&
                        <div className={"commonSubtitle"}>
                            Телеграм бот подключен
                        </div>
                    }


                    <button
                        className="new smallIconed"
                        onClick={async () => {
                            await turnOffTGBot(contact)
                        }}
                    >
                        <Delete className="icon dark"/>
                    </button>
                </div>
            </div>
        }
    }

    return <div className={"templateViewContainer disabledHover"}>
        <div className="commonTitle" style={{textAlign: "left"}}>
            Уведомления
        </div>

        <div className="commonSubtitle" style={{textAlign: "left"}}>
            Настройте телеграм бота, чтобы получать подписанные договора прямо в мессенджере
        </div>

        <hr className={"newHR"}/>

        {
            tgContacts().map((contact: any) => {
                return makeContactView(contact)
            })
        }

        <div className="row-data">
            <button
                className="new withTitle small"
                onClick={async () => {
                    await requestCodeForTGBot()
                }}
            >
                {
                    tgContacts().length > 0 ? "Подключить еще один аккаунт" : "Подключить телеграм бот"
                }
            </button>
        </div>
    </div>
}

TGBotIntegrationView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(TGBotIntegrationView)

export default TGBotIntegrationView