import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {ReactComponent as CheckboxIcon} from "../../Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxChecked} from "../../Icons/size24/CheckboxChecked.svg";

let NeedConfirmationView: any = function NeedConfirmationView(props: any) {
    const settings = props.settings

    return <div>
        <hr className={"newHR"}/>

        <div className="commonSubtitle" style={{marginTop: "5px"}}>
            После подписания договора вам придет уведомление с просьбой проверить введенные клиентом данные. Договор будет считаться подписанным только после того, как вы завершите проверку.
        </div>

        <div className="buttonsContainer" style={{marginTop: "10px"}}>
            <Checkbox
                icon={<CheckboxIcon className="icon"/>}
                checkedIcon={<CheckboxChecked className="icon"/>}
                checked={settings.need_confirmation}
                onChange={({target: {checked}}) => {
                    props.updateContractSettings({...settings, need_confirmation: checked})
                }}
            />
            <div className="commonLabel">
                Проверять договор после подписания клиентом
            </div>
        </div>
    </div>
}

NeedConfirmationView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(NeedConfirmationView)

export default NeedConfirmationView
