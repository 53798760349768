import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {connect} from "react-redux";
import ChooseWhoCanFillView from "./Views/ChooseWhoCanFillView";
import {contractSystemEntity, contractSystemEntityIsHidden} from "../../../methods/systemEntityValueByKeyword";
import {
    bulkUpdateSystemEntities,
    clientFirstName, clientLawAddress, clientLawName, customerPersonalEntities, entrepreneurPersonalEntities,
} from "../../../methods/SystemEntity";
import EntityCopyButton from "./Views/EntityCopyButton";
import RequestedEntityView from "./Views/RequestedEntityView";

let EntrepreneurRequestedPersonalDataView: any = function CustomerPerosnalDataView(props: any) {
    const contract = props.contract
    function copyButton(keyword: string) {
        return <EntityCopyButton keyword={keyword}/>
    }

    function disabledEntityView(title: string, subtitle: string | null = null) {
        return <RequestedEntityView
            title={title}
            subtitle={subtitle}
            disabled={true}
            checked={true}
        />
    }

    return <div>
        <div style={{marginTop: "15px"}}>
            <ChooseWhoCanFillView
                editable={(contractSystemEntity(contract,clientLawName.id) ?? clientLawName).editable}
                updateEditable={(editable: boolean) => {
                    let entitiesToUpdate = bulkUpdateSystemEntities(contract, entrepreneurPersonalEntities, "editable", editable)
                    props.addEntities(true, entitiesToUpdate)
                }}
            />
        </div>

        <div className={"titleContainer"}>
            {disabledEntityView("Название организации")}
            {copyButton(clientLawName.keyword)}
        </div>

        <div className={"titleContainer"}>
            {disabledEntityView("Адрес регистрации организации")}
            {copyButton(clientLawAddress.keyword)}
        </div>

        {disabledEntityView("ИНН организации")}

        {disabledEntityView("ОГРН органиазации")}
    </div>
}

EntrepreneurRequestedPersonalDataView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EntrepreneurRequestedPersonalDataView)

export default EntrepreneurRequestedPersonalDataView