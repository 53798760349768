import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {connect} from "react-redux";
import {ReactComponent as RadioChecked} from "../../Icons/size24/RadioChecked.svg";
import {ReactComponent as RadioUnchecked} from "../../Icons/size24/RadioUnchecked.svg";

export type AccountTypeProps = {
    title: string,
    role: string,
    allowNotSelectedOptions?: boolean,
    onChange: Function
}

export const clientRoles: string[] = ['entrepreneur', "customer"]
export function clientRoleTitle(role: string): string {
    switch (role) {
        case clientRoles[0]:
            return "ИП или ООО"
        case clientRoles[1]:
            return "Физ. лицо или самозанятый"
        default:
            return "Не выбрано"
    }
}

export let ChooseAccountTypeFrom: any = function ChooseAccountTypeFrom(props: AccountTypeProps) {
    const roles = props.allowNotSelectedOptions ? clientRoles.concat([""]) : clientRoles
    return <div>
        <div className="Subheader_17">
            {
                props.title
            }
        </div>
        <RadioGroup
            defaultValue={props.role}
            onChange={(e, role) => {
                props.onChange(role)
            }}
        >
            {
                roles.map((role) =>
                    <FormControlLabel
                        value={role}
                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                        label={clientRoleTitle(role)}
                    />
                )
            }
        </RadioGroup>
    </div>
}

ChooseAccountTypeFrom = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(ChooseAccountTypeFrom)
