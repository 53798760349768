import {validate} from "../validate";

export function emptyEntities(contract: any): string[] {
    return contract.entities
        .filter((entity: any) => !("isRequired" in entity) || entity["isRequired"] === true)
        .filter(
            (entity: any) =>
                (["Текст", "Дата", "Фото", "Список", "Формула"].includes(entity.type) || !entity.type) &&
                !entity.clientCanFill &&
                (entity.value?.toString() ?? "").length == 0,
        )
        .map((entity: any) => { return entity.keyword })
}