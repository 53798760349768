import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import EntityCopyButton from "./Views/EntityCopyButton";
import Checkbox from "@mui/material/Checkbox";
import ChooseWhoCanFillView from "./Views/ChooseWhoCanFillView";
import {contractSystemEntity} from "../../../methods/systemEntityValueByKeyword";
import {ReactComponent as CheckboxIcon} from "../../Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxDisabledChecked} from "pages/Icons/size24/CheckboxDisabledChecked.svg"
import {
    bulkUpdateSystemEntities, clientEmail,
    clientPhoneNumber, contactsEntities
} from "../../../methods/SystemEntity";

let ClientContactDataView: any = function ClientContactDataView(props: any) {
    const contract = props.contract
    function copyButton(keyword: string) {
        return <EntityCopyButton keyword={keyword}/>
    }

    function makePropertyView(entityId: string, title: string, subtitle: string | null = null) {
        return <div className="buttonsContainer" style={{marginTop: "15px"}}>
            <Checkbox
                sx={{padding: "0px"}}
                icon={<CheckboxIcon className="icon"/>}
                disabled={true}
                checkedIcon={<CheckboxDisabledChecked className="icon"/>}
                checked={true}
            />
            <div>
                <div className="commonLabel">
                    {title}
                </div>
                {
                    subtitle &&
                    <div className="commonSubtitle">
                        {subtitle}
                    </div>
                }
            </div>
        </div>
    }

    return <>
        <div style={{marginTop: "15px"}}>
            <ChooseWhoCanFillView
                editable={(contractSystemEntity(contract, clientPhoneNumber.id) ?? clientPhoneNumber).editable}
                updateEditable={(editable: boolean) => {
                    let entitiesToUpdate = bulkUpdateSystemEntities(contract, contactsEntities, "editable", editable)
                    props.addEntities(true, entitiesToUpdate)
                }}
            />
        </div>

        <div className={"titleContainer"}>
            {makePropertyView(clientEmail.id, "E-mail клиента", "Почта обеспечивает юридическую значимость простой электронной подписи, поэтому она обязательна")}
            {copyButton(clientEmail.keyword)}
        </div>

        <div className={"titleContainer"}>
            {makePropertyView(clientPhoneNumber.id, "Телефон клиента", "На телефон придет смс код для подписания договора, поэтому телефон обязателен для заполнения")}
            {copyButton(clientPhoneNumber.keyword)}
        </div>
    </>
}

ClientContactDataView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ClientContactDataView)

export default ClientContactDataView