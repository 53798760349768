import { connect } from "react-redux"
import {BaseLink, useRoute} from "react-router5"
import BalanceHolder from "./nav/BalanceHolder";
import isAuthorized from "utils/funcs/isAuthorized";
import React from "react";

let Nav: any = function Nav(props: any) {

    const $router = useRoute()

    const style = {
        textDecoration: "underline",
        textUnderlineOffset: "3px",
        textDecorationColor: "#33B5FF",
        textDecorationThickness: "2px"
    }

    // Render
    return (
        isAuthorized() ?
            <nav className="profile-navigation">
                <BaseLink router={$router.router} routeName="profile">
                    <button className="_wa _transparent cursor">
                        <BalanceHolder/>
                    </button>
                </BaseLink>
                <BaseLink router={$router.router} routeName="new-contract" routeParams={{act: 'list'}}>
                    <button
                        className="_wa _transparent"
                        style={$router.route.name === 'new-contract' ? style : {}}
                    >
                        Шаблоны
                    </button>
                </BaseLink>
                <BaseLink router={$router.router} routeName="signed-contracts">
                    <button
                        className="_wa _transparent"
                        style={$router.route.name === 'signed-contracts' || $router.route.name === 'contract' ? style : {}}
                    >
                        Выставленные
                    </button>
                </BaseLink>
                <BaseLink router={$router.router} routeName="profile" className="_wa _transparent">
                    <button
                        className="_wa _transparent"
                        style={$router.route.name === 'profile' ? style : {}}
                    >
                        Профиль
                    </button>
                </BaseLink>
            </nav>
            : null
    )
}

Nav = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(Nav)

export default Nav
