import {clearAfterCreateContractEntities, clientPassportType, getSystemEntityById, SystemEntity} from "./SystemEntity";
import {getPostfixForEntity} from "./utils";
import {updateEntityInEditor} from "./updateEntityInEditor";
import {entityById} from "./systemEntityValueByKeyword";
import _ from "lodash";
import notifyUser from "./notifyUser";

export function updateEntityInList(entities: any[], keyword: string, value: string): any[] {
    for (const entity of entities) {
        if (entity.keyword == keyword) {
            entity.value = value
        }
    }
    return entities
}

export function entityByKeyword(entities: any[], keyword: string, oldKeyword: string | null = null): any | undefined {
    if (keyword.length == 0) {
        return
    }
    for (const entity of entities) {
        if (entity.keyword == keyword || entity.keyword == oldKeyword) {
            return entity
        }
    }
}

export function fullEntityValue(entity: any, separator: string = "\n"): string {
    if (!entity) {
        return ""
    }
    let value = entity.value ?? ""

    return value + additionalValuesString(entity, separator)
}

export function additionalValuesString(entity: any, separator: string = "\n"): string {
    if (!entity) {
        return ""
    }
    let result = ""
    if (entity.allowMultipleValues ?? false) {
        for (let additionalValue of entity.additionalValues ?? []) {
            if (additionalValue.length > 0) {
                let postfix = entity.postfix ? getPostfixForEntity({...entity, value: additionalValue}) : ''
                result += separator + additionalValue + postfix
            }
        }
    }
    return result
}

export function clearedEntitiesTemplate(template: any) {
    let entities = [...template.entities]
    let system_entities = [...template.system_entities]
    for (let entity of entities) {
        entity.value = entity.defaultValue ?? ""
        entity.additionalValues = []
        entity.postfix = ""
        updateEntityInEditor(entity)
    }

    for (let entity of clearAfterCreateContractEntities) {
        let entityToClear = entityById(system_entities, entity.id)
        if (entityToClear) {
            if (entityToClear.id == clientPassportType.id) {
                entityToClear.value = entityToClear.selectedValues[0]
            } else {
                entityToClear.value = ""
                entityToClear.postfix = ""
            }
        }
    }

    return {
        ...template,
        entities: entities,
        system_entities: system_entities
    }
}


export function getEntities(contract: any, isSystem: boolean): any[] {
    let path = isSystem ? 'system_entities' : 'entities'

    return [..._.get(contract, path, [])]
}

export function updateEntities(contract: any, isSystem: boolean, any_entities: any[]): any {
    let path = isSystem ? 'system_entities' : 'entities'

    let updatedContract = {...contract}
    _.set(updatedContract, path, any_entities)

    setTimeout(
        () => {
            for (let entity of any_entities) {
                updateEntityInEditor(entity, isSystem ? [] : updatedContract.entities)
            }
        },
        100
    )

    return updatedContract
}

export function updateEntity(contract: any, isSystem: boolean, entity: any): any {
    let any_entities = getEntities(contract, isSystem)
    const entityIndex = any_entities.findIndex(
        (e: any) => e.keyword === entity.keyword,
    );
    any_entities[entityIndex] = entity
    return updateEntities(contract, isSystem, any_entities)
}

export function addEntities(contract: any, isSystem: boolean, entities: any[]): any {
    let any_entities = getEntities(contract, isSystem)
    for (const entity of entities) {
        const entityIndex = any_entities.findIndex(
            (e: any) => e.keyword === entity.keyword,
        );
        if (entityIndex != -1) {
            any_entities[entityIndex] = entity
        } else {
            any_entities.push(entity)
        }
        // console.log("addEntities", entity)
    }
    return updateEntities(contract, isSystem, any_entities)
}

export function deleteEntities(contract: any, isSystem: boolean, keywords: string[]): any {
    let any_entities = getEntities(contract, isSystem)
    if (isSystem) {
        for (let any_entity of any_entities) {
            for (let keyword of keywords) {
                if (keyword == any_entity.keyword) {
                    any_entity.hidden = true
                }
            }
        }
    } else {
        any_entities = any_entities.filter((entity: any) => !keywords.includes(entity.keyword))
    }

    return updateEntities(contract, isSystem, any_entities)
}