import {httpClient} from "../funcs";

export function updateContractSettings(
    templateId: string | null,
    settings: any,
) {
    if (templateId) {
        httpClient
            .post("/template/update_settings", {
                template_id: templateId,
                settings: JSON.stringify(settings)
            })
            .then((r) => {});
    }
}