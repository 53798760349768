import _ from "lodash";

export enum ContractStatus {
    Draft ,
    Awaiting = 1,
    Signed = 2,
    Declined = 3,
    AwaitingConfirmation = 4,
    Canceled = 5,
}


export function contractStatus(contract: any): number {
    return _.get(contract, "status.internal_id")
}