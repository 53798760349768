import {ReactComponent as Warning} from "../Icons/size24/Warning.svg";
import {connect} from "react-redux";
import {ReactComponent as Close} from "../Icons/size24/Close.svg";
import React from "react";

type Props = {
    message: string,
    onClose: Function
}

let ErrorView: any = function ErrorView({message, onClose}: Props) {
    return <div className="errorContainer">
        <div className="titleContainer">
            <div></div>
            <button className="new smallIconed" style={{float: "right"}} onClick={() => onClose()}>
                <Close className="smallIcon" style={{stroke: "#75809E"}} />
            </button>
        </div>
        <div
            className="titleContainer"
            style={{marginTop: "-20px", marginRight: "26px", marginLeft: "auto", textAlign: "center"}}
        >
            <div></div>
            <div
                className="buttonsContainer"
                style={{textAlign: "center"}}
            >
                <Warning className="icon" style={{fill: "#E22631"}}/>
                <div className="errorMessage">
                    {message}
                </div>
            </div>
            <div></div>
        </div>
    </div>
}

ErrorView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ErrorView)

export default ErrorView