import React from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {connect} from "react-redux";
import {EntityInput} from "../../Entity/EntityInput";
import {ChoosePassportTypeForm, ChoosePassportTypeProps} from "./ChoosePassportTypeForm";
import {FormInput, FormInputProps} from "./FormInput";

export type PassportFormProps = {
    choosePassportType?: ChoosePassportTypeProps

    number?: FormInputProps
    issuedAt?: FormInputProps
    issuedBy?: FormInputProps
    issuedByCode?: FormInputProps
    registration?: FormInputProps

    photo?: PhotoInputProps
    selfie?: PhotoInputProps
    registrationPhoto?: PhotoInputProps
}

export type PhotoInputProps = {
    path: string
    value: string
    keyword: string
    onChange: Function
}

export let PassportForm: any = function PassportForm({form}: {form: PassportFormProps }) {
    function makePhotoInput(photoInputProps: PhotoInputProps) {
        return <div>
            <div className="Subheader_17" style={{marginTop: "15px", marginBottom: "5px"}}>
                {photoInputProps.keyword}
            </div>
            <EntityInput
                entity={{
                    keyword: photoInputProps.keyword,
                    value: photoInputProps.value,
                    type: "Фото"
                }}
                updateEntity={(value: string) => {
                    photoInputProps.onChange(value)
                }}
            />
        </div>
    }

    return <div>
        <div>
            <div className="Subheader_17" style={{marginTop: "20px"}}>
                Паспортные данные:
            </div>
            {
                form.choosePassportType &&
                <ChoosePassportTypeForm form={form.choosePassportType}/>
            }

            {
                form.number &&
                <FormInput props={form.number}/>
            }

            {
                form.issuedAt &&
                <FormInput props={form.issuedAt}/>
            }

            {
                form.issuedByCode &&
                <FormInput props={form.issuedByCode}/>
            }

            {
                form.issuedBy &&
                <FormInput props={form.issuedBy}/>
            }

            {
                form.registration &&
                <FormInput props={form.registration}/>
            }

            {
                form.photo &&
                makePhotoInput(form.photo)
            }

            {
                form.selfie &&
                makePhotoInput(form.selfie)
            }

            {
                form.registrationPhoto &&
                makePhotoInput(form.registrationPhoto)
            }
        </div>
    </div>
}

PassportForm = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(PassportForm)
