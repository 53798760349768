import * as module from "module";
import {useMediaQuery} from "@mui/material";

export function scrollToElement(elementId: string, highlight: boolean = false) {
    let element = document.getElementById(elementId)
    if (element) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "center"
        })
        if (highlight) {
            highlightElement(element)
        }
    }
}

export function highlightElement(element: HTMLElement) {
    let borderValue = element.style.getPropertyValue("border")
    let borderRadiusValue = element.style.getPropertyValue("border-radius")
    let paddingLeft = element.style.getPropertyValue("padding-left")

    element.style.setProperty("border-radius", "10px")
    element.style.setProperty("transition-duration", "300ms")
    element.style.setProperty("transition-property", "border")
    element.style.setProperty("border", "1px solid red")
    setTimeout(() => {
        element?.style.setProperty("border", borderValue)
        element?.style.setProperty("border-radius", borderRadiusValue)
    }, 3000)
}