
export enum UserPermission {
    OWNER = 'OWNER',
    ALL = 'ALL',

    BASIC = 'BASIC',

    TEMPLATES = 'TEMPLATES',
    CREATE_CONTRACT = 'CREATE_CONTRACT',
    EDIT_TEMPLATE = 'EDIT_TEMPLATE',

    CONTRACTS = 'CONTRACTS',
    EDIT_CONTRACT = 'EDIT_CONTRACT',
    SIGN_CONTRACT = 'SIGN_CONTRACT',
    CANCEL_CONTRACT = 'CANCEL_CONTRACT',

    ADD_USERS = 'ADD_USERS',
    EDIT_USER = 'EDIT_USER',
}

export function checkPermission(props: any, permission: UserPermission): boolean {
    let userSession = props.$store.userSession
    if (!userSession) {
        return false
    }

    let grantedPermission = userSession.permissions
    if (grantedPermission == undefined) {
        return true
    }

    if (permission == UserPermission.OWNER) {
        return false
    }

    return grantedPermission.includes(permission.toString())
}