import {connect} from "react-redux";
import _ from "lodash";
import {ReactComponent as Signed} from "../Icons/size24/Signed.svg";
import {ReactComponent as Declined} from "../Icons/size24/Declined.svg";
import {ReactComponent as Pending} from "../Icons/size24/Pending.svg";
import React from "react";
import {EditNote} from "@mui/icons-material";
import {firstSignerTitle, targetUserTitle} from "../../methods/workWithContract";

type Props = {
    contract: any
    userSession: any
}

let ContractStatusView: any = function ContractStatusView({contract, userSession}: Props) {
    let status = contract.status

    function contractStatusIcon() {
        switch (_.get(status, "internal_id")) {
            case 0:
                return <EditNote className="icon" style={{fill: "#75809E"}}/>
            case 2:
                return <Signed className="icon"/>
            case 3:
                return <Declined className="icon" style={{fill: "#E22631"}}/>
            case 5:
                return <Declined className="icon" style={{fill: "#E22631"}}/>
            default:
                return <Pending className="icon" style={{fill: "#FFCC66"}}/>
        }
    }

    function contractStatusTitle() {
        switch (_.get(status, "internal_id")) {
            case 0:
                return <div className="commonSubtitle priorityHigh" style={{color: "#75809E"}}>Черновик</div>
            case 2:
                return <div className="commonSubtitle priorityHigh" style={{ color: "#0E1F4D"}}>Подписан</div>
            case 3:
                let declineReason = _.get(status, "change_status_text", '')
                if (declineReason.length) {
                    return <div className="commonSubtitle priorityHigh">
                        <span style={{color: "#0E1F4D"}}>Отклонен по причине: </span>
                        <span>{declineReason}</span>
                    </div>
                } else {
                    return <div className="commonSubtitle priorityHigh" style={{color: "#0E1F4D"}}>Отклонен</div>
                }
            case 4:
                return <div className="commonSubtitle priorityHigh" style={{color: "#0E1F4D"}}>Ожидает проверки введённых данных</div>
            case 5:
                const cancelReason = _.get(status, "change_status_text")
                if (!!cancelReason) {
                    return <div className="commonSubtitle priorityHigh">
                        <span style={{color: "#0E1F4D"}}>Расторгнут по причине: </span>
                        <span>{cancelReason}</span>
                    </div>
                } else {
                    return <div className="commonSubtitle priorityHigh" style={{color: "#0E1F4D"}}>Расторгнут</div>
                }
            default:
                const returnReason = _.get(status, "change_status_text", '')
                if (returnReason.length) {
                    return <div className="commonSubtitle priorityHigh" style={{whiteSpace: "pre-wrap"}}>
                        <span style={{color: "#0E1F4D"}}>Ожидается
                        повторная подпись по причине: </span>
                        <span>{returnReason}</span>
                    </div>
                } else {
                    return <div className="commonSubtitle priorityHigh" style={{color: "#0E1F4D"}}>
                        {awaitingTitle()}
                    </div>
                }
        }
    }

    function awaitingTitle() {
        let commonTitle = "Ожидается подпись"
        if (!contract) {
            return commonTitle
        }
        let is_common_case = contract.first_signer?._id == contract.created_by?._id
        if (is_common_case) {
            return commonTitle
        }

        if (!contract.first_signer && !contract.target_user) {
            return `Ожидается подпись ${firstSignerTitle(contract)} и ${targetUserTitle(contract)}`
        }

        if (!contract.first_signer) {
            return `Ожидается подпись ${firstSignerTitle(contract)}`
        }

        if (!contract.target_user) {
            return `Ожидается подпись ${targetUserTitle(contract)}`
        }

        return commonTitle
    }

    return <div className="buttonsContainer">
        {
            contractStatusIcon()
        }
        {
            contractStatusTitle()
        }
    </div>
}

ContractStatusView = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(ContractStatusView)

export default ContractStatusView