import {connect} from "react-redux";
import React from "react";
import {commonTextField, TextareaStyled} from "../../Styles/CommonCSSProperties";
import {MobileInputMask} from "../../../components/elements/InputWithMask";
import {FormInput, FormInputProps} from "./FormInput";

type ContactsFormProps = {
    email: FormInputProps,
    phoneNumber: FormInputProps,
}

let ContactsForm: any = function ContactsForm(props: ContactsFormProps) {
    return <div>
        <div>
            <div className="Subheader_17" style={{marginTop: "20px", marginBottom: "10px"}}>
                Контактные данные:
            </div>

            <div>
                {
                    props.email?.tip &&
                    <div>
                        <div className="Subheader_17">
                            E-mail
                        </div>
                        <div className={"commonSubtitle"}>
                            { props.email?.tip ?? ''}
                        </div>
                        {
                            props.email.warning &&
                            <div style={{color: "#E22631"}} className={"editContractEntitiesCloseTip"}>
                                {props.email.warning}
                            </div>
                        }
                    </div>
                }

                <FormInput props={props.email}/>
            </div>
            {
                props.phoneNumber &&
                <div style={{marginTop: "10px"}}>
                    {
                        props.phoneNumber.tip &&
                        <div>
                            <div className="Subheader_17">
                                Номер телефона
                            </div>
                            <div className={"commonSubtitle"}>
                                { props.phoneNumber.tip }
                            </div>
                            {
                                props.phoneNumber.warning &&
                                <div style={{color: "#E22631"}} className={"editContractEntitiesCloseTip"}>
                                    {props.phoneNumber.warning}
                                </div>
                            }
                        </div>
                    }
                    <div className="commonTextfield" style={{marginTop: "5px"}}>
                        <MobileInputMask
                            ref={null}
                            value={props.phoneNumber.value}
                            disabled={props.phoneNumber.disabled ?? false}
                            onChange={props.phoneNumber.onChange}
                            style={{...(commonTextField()), marginTop: "5px"}}
                        />
                    </div>
                </div>
            }
        </div>
    </div>
}

ContactsForm = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(ContactsForm)

export default ContactsForm
