import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {connect} from "react-redux";
import ChooseWhoCanFillView from "./Views/ChooseWhoCanFillView";
import {contractSystemEntity, contractSystemEntityIsHidden} from "../../../methods/systemEntityValueByKeyword";
import {
    bulkUpdateSystemEntities,
    clientBankName,
    clientFirstName,
    entrepreneurBankEntities,
    entrepreneurPersonalEntities,
} from "../../../methods/SystemEntity";
import EntityCopyButton from "./Views/EntityCopyButton";
import Checkbox from "@mui/material/Checkbox";
import RequestedEntityView from "./Views/RequestedEntityView";

let EntrepreneurRequestedBankDataView: any = function CustomerPerosnalDataView(props: any) {
    const contract = props.contract
    function copyButton(keyword: string) {
        return <EntityCopyButton keyword={keyword}/>
    }

    function disabledEntityView(title: string, subtitle: string | null = null) {
        return <RequestedEntityView
            title={title}
            subtitle={subtitle}
            disabled={true}
            checked={true}
        />
    }

    function requestBankDataView() {
        let entity = contractSystemEntity(contract, clientBankName.id) ?? clientBankName
        return <RequestedEntityView
            entityId={""}
            title={"Нужны ли реквизиты?"}
            checked={!entity.hidden}
            updateChecked={(checked: boolean) => {
                let entitiesToUpdate = bulkUpdateSystemEntities(contract, entrepreneurBankEntities, "hidden", !checked)
                props.addEntities(true, entitiesToUpdate)
            }}
        />
    }

    return <div>
        {/*{*/}
        {/*    requestBankDataView()*/}
        {/*}*/}
        {
            !contractSystemEntityIsHidden(contract, clientBankName.id) &&
            <div>
                <div style={{marginTop: "15px"}}>
                    <ChooseWhoCanFillView
                        editable={(contractSystemEntity(contract,clientFirstName.id) ?? clientFirstName).editable}
                        updateEditable={(editable: boolean) => {
                            let entitiesToUpdate = bulkUpdateSystemEntities(contract, entrepreneurBankEntities, "editable", editable)
                            props.addEntities(true, entitiesToUpdate)
                        }}
                    />
                </div>

                {disabledEntityView("Банк клиента")}

                {disabledEntityView("БИК банка")}

                {disabledEntityView("Расчетный счет")}

                {disabledEntityView("Корр. счет")}
            </div>
        }
    </div>
}

EntrepreneurRequestedBankDataView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EntrepreneurRequestedBankDataView)

export default EntrepreneurRequestedBankDataView