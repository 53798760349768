import {connect} from "react-redux";
import React from "react";
import {FormInput, FormInputPlaceholder} from "./FormInput";

type CustomerFormProps = {
    lastName: string,
    lastNameDisabled?: boolean,
    onChangeLastName: Function,

    firstName: string,
    firstNameDisabled?: boolean,
    onChangeFirstName: Function,

    middleName: string,
    middleNameDisabled?: boolean,
    onChangeMiddleName: Function,
}

let CustomerForm: any = function CustomerForm(props: CustomerFormProps) {

    return <div>
        <div className="Subheader_17"  style={{marginTop: "15px"}}>
            ФИО:
        </div>

        <FormInput
            props={{
                placeholder: FormInputPlaceholder.lastName,
                value: props.lastName,
                disabled: props.lastNameDisabled ?? false,
                onChange: props.onChangeLastName,
            }}
        />

        <FormInput
            props={{
                placeholder: FormInputPlaceholder.firstName,
                value: props.firstName,
                disabled: props.firstNameDisabled ?? false,
                onChange: props.onChangeFirstName,
            }}
        />

        <FormInput
            props={{
                placeholder: FormInputPlaceholder.middleName,
                value: props.middleName,
                disabled: props.middleNameDisabled ?? false,
                onChange: props.onChangeMiddleName,
            }}
        />
    </div>
}

CustomerForm = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(CustomerForm)

export default CustomerForm
