async function clipboardCopyText(text: string) {
    await navigator.clipboard.writeText(text)
}

const clipboardCopyTextWithNotification = (text: string, props: any) => {
    clipboardCopyText(text);
    props.$commitToStore({
      notification: {
        title: `Промо-код скопирован`,
      }
    })
}

export { clipboardCopyText, clipboardCopyTextWithNotification };