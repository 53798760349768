export function clearFormattingForEntities(body: string) {
    let entityBGVariant1 = "background: #DE9B94;"
    let entityBGVariant2 = "background: rgb(222, 155, 148);"
    let entityBorderVariant1 = "border: 1px solid rgb(222, 155, 148);"
    let entityBorderVariant2 = "border: 1px solid #DE9B94;"

    body = body.split(entityBGVariant1).join("background: none;")
    body = body.split(entityBGVariant2).join("background: none;")
    body = body.split(entityBorderVariant1).join("border: none;")
    body = body.split(entityBorderVariant2).join("border: none;")

    return body
}