import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useRoute} from "react-router5";
import {connect} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {clientRoleTitle, clientRoles} from "../../../Auth/UserForms/ChooseAccountTypeFrom";
import {ReactComponent as CheckboxIcon} from "pages/Icons/size24/Checkbox.svg"
import {ReactComponent as CheckboxChecked} from "pages/Icons/size24/CheckboxChecked.svg"
import {ReactComponent as CheckboxDisabledChecked} from "pages/Icons/size24/CheckboxDisabledChecked.svg"
import {contractSystemEntity} from "../../../../methods/systemEntityValueByKeyword";
import {
    clientPassportNumber,
    customerAllEntities,
    customerPersonalEntities,
    entrepreneurAllEntities,
    entrepreneurPersonalEntities
} from "../../../../methods/SystemEntity";

let ChooseTargetUserPassportTypeView: any = function ChooseTargetUserAccountTypeView(props: any) {
    const entity = props.entity

    function checkboxDisabled(passportType: string): boolean {
        return entity.selectedValues && entity.selectedValues.length === 1 && checkboxChecked(passportType)
    }

    function checkboxChecked(passportType: string): boolean {
        return entity.selectedValues?.some((value: string) => passportType == value)
    }

    function processChecked(checked: boolean, index: number) {
        let entityToUpdate = {...entity}
        let updatedValue = entityToUpdate.allowedValues[index]
        if (checked) {
            if (!(entityToUpdate.selectedValues?.includes(updatedValue))) {
                entityToUpdate.selectedValues?.push(updatedValue)
            }
        } else {
            entityToUpdate.selectedValues = entityToUpdate.selectedValues?.filter(
                (value: string) => value != updatedValue
            ) ?? []
            if (entityToUpdate.selectedValues.length == 1)
                entityToUpdate.value = entityToUpdate.selectedValues[0]
        }

        props.addEntities(true, [entityToUpdate])
    }

    return <>
        <div>
            <div className="commonLabel">
                Какой документ необходим для подписания договора?
            </div>
            {
                (entity.allowedValues ?? []).map((passportType: string, index: number) => {
                    return <div
                        className="buttonsContainer"
                        style={{marginLeft: "-10px"}}
                    >
                        <Checkbox
                            disabled={checkboxDisabled(passportType)}
                            icon={<CheckboxIcon className="icon"/>}
                            checkedIcon={checkboxDisabled(passportType) ? <CheckboxDisabledChecked className="icon"/> : <CheckboxChecked className="icon"/>}
                            checked={checkboxChecked(passportType)}
                            onChange={({target: {checked}}) => {
                                processChecked(checked, index)
                            }}
                        />
                        <div className="commonLabel">
                            {passportType}
                        </div>
                    </div>
                })
            }
        </div>
    </>
}

ChooseTargetUserPassportTypeView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ChooseTargetUserPassportTypeView)

export default ChooseTargetUserPassportTypeView
