import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import {HelpView} from "../Templates/TemplatesHelpPopup";

let NewCreateContractFlowView: any = function NewCreateContractFlowView(props: any) {
    return <>
        <HelpView
            title={"Масштабное обновление процессы выставления договоров"}
            subtitle={"Мы серьезно переработали процесс выставления договоров с учетом вашей обратной связи. \n" +
                "\n" +
                "Теперь вся настройка шаблона сосредоточена в виджетах, и процесс строится как бы \"сверху вниз\". Вы \"спускаетесь\" по виджетам, открывая их по очереди и знакомитесь с возможностями сервиса.\n" +
                "\n" +
                "Виджеты так же доступны и в выставленных договорах! Теперь можно донастроить выставленный договор без создания нового договора. \n" +
                "\n" +
                "PDF тоже не обошли вниманием, теперь все возможности, которые раньше было в шаблонах, доступны и для выставления PDF договоров!"}
            videoId={"nOVkTMHyqRg"}
        />
    </>
}

NewCreateContractFlowView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(NewCreateContractFlowView)

export default NewCreateContractFlowView
