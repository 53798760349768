import Popup from "../../../components/popup";
import {Entity, Flow} from "../../Entity/Entity";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import RegisterPage from "../../Auth/Register";
import {useRoute} from "react-router5";

type Props = {
    contract: any,
    close: Function,
    updateEntity: Function,
    finish: Function,
    $store: any,
    $commitToStore: Function,
}

let FillEntitiesPopup: any = function FillEntitiesPopup(props: Props) {
    const {contract, close, updateEntity, finish, $store, $commitToStore} = props
    const [goToMainOnClose, setGoToMainOnClose] = useState(false)
    const $router = useRoute()

    return <Popup
        style={{zIndex: 999}}
        onClose={() => {
            if (goToMainOnClose) {
                setTimeout(() => $router.router.navigate('main'), 0)
            } else {
                close()
            }
        }}
        padding={"15px 15px"}
    >
        <div className="" style={{marginRight: "auto", marginLeft: "auto"}} >
            <RegisterPage
                contract={contract}
                title={"Подписать"}
                subtitle={"Заполните все поля чтобы подписать договор"}
                updateEntity={updateEntity}
                finish={finish}
                goToMainOnClose={(value: boolean) => {
                    setGoToMainOnClose(value)
                }}
                $store={$store}
                $commitToStore={$commitToStore}
            />
        </div>
    </Popup>
}

FillEntitiesPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(FillEntitiesPopup)

export default FillEntitiesPopup
