import Popup from "../../components/popup";
import {connect} from "react-redux";
import "pages/Styles/EditContractCommon.sass"

type Props = {
    title: string,
    onClose: Function,
    onConfirm: Function
}

let ConfirmationPopup: any = function ConfirmationPopup(props: Props) {
    const {title, onClose, onConfirm} = props

    return <Popup
        style={{zIndex: 999}}
        onClose={onClose}
    >
        <div>
            <div className="centeredLabel">
                <div className="commonLabel" style={{marginTop: "25px", textAlign: "center", whiteSpace: "pre-wrap"}}>
                    {title}
                </div>
            </div>

            <hr className="newHR"/>

            <div className="buttonsContainer" style={{width: "100%"}}>
                <button
                    className="new withTitle _wa"
                    onClick={(e) => {onConfirm()}}
                >
                    Да
                </button>
                <button
                    className="new withTitle secondary _wa"
                    onClick={(e) => {onClose()}}
                >
                    Нет
                </button>
            </div>
        </div>
    </Popup>
}

ConfirmationPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ConfirmationPopup)

export default ConfirmationPopup