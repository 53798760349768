import {addSpanTagToString} from "./addSpanTagsToBody";

export function checkInlineEntities(value: string, currentEntityKW: string, otherEntities: any[]): string {
    for (let entity of otherEntities) {
        let kw = `{{${entity.keyword}}}`;
        if (kw == currentEntityKW) {
            continue
        }
        value = addSpanTagToString(entity, value)
    }
    return value
}