import {CSSProperties} from "react";
import {styled} from "@mui/styles";
import {Autocomplete, TextareaAutosize} from "@mui/material";
import TextField from "@mui/material/TextField";

export const TextareaStyled: any = styled(TextareaAutosize) ((props) => {
    const padding = (props as any).padding ?? 21
    let error = (props as any).error ?? false

    let commonBorder = error ? "1px solid #EA0707" : "none"
    let commonBackground = error ? "white" : "#EAECF3"

    let borderColor = error ? "#EA0707" : "#75809E"
    let hoverBorderColor = error ? "#EA0707" : "#D4D8E1"

    return {
        resize: "none",
        padding: error ? `${padding - 1}px` : `${padding}px`,
        border: commonBorder,
        background: commonBackground,
        borderRadius: "10px",

        fontFamily: 'Nunito Sans',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        color: "#0E1F4D",
        width: "100%",

        "&:disabled": {
            border: "none",
            background: "#E0E0E0",
            color: "#898989",
        },

        "&:hover:not(:disabled)": {
            padding: `${padding - 1}px`,
            border: `1px solid ${hoverBorderColor}`,
            color: "#0E1F4D",
        },

        "&:focus:not(:disabled)": {
            padding: `${padding - 1}px`,
            background: "white",
            border: `1px solid ${borderColor}`,
            color: "#0E1F4D",
        },

        "&:active:not(:disabled)": {
            padding: `${padding - 1}px`,
            background: "white",
            border: `1px solid ${borderColor}`,
            color: "#0E1F4D",
        },
    }
})

export function commonTextField(padding: string = "20px", error: boolean = false): CSSProperties {
    let commonBorder = error ? "1px solid #EA0707" : "none"
    let commonBackground = error ? "white" : "#EAECF3"

    let borderColor = error ? "#EA0707" : "#75809E"
    let hoverBorderColor = error ? "#EA0707" : "#D4D8E1"

    return {
        height: "61px",
        resize: "none",
        padding: error ? "19px" : padding,
        border: commonBorder,
        background: commonBackground,
        borderRadius: "10px",

        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: 'Nunito Sans',

        lineHeight: "20px",
        color: "#0E1F4D",
        width: "100%",
    }
}

export const TextFieldStyled = styled(TextField) ((props) => {
    let error = (props as any).error ?? false

    let commonBorder = error ? "1px solid #EA0707" : "none"
    let commonBackground = error ? "white !important" : "#EAECF3"

    let borderColor = error ? "#EA0707" : "#75809E"
    let hoverBorderColor = error ? "#EA0707" : "green"

    return {
        resize: "none",
        padding: error ? "2.5px !important" : "3.5px",
        border: commonBorder,
        background: commonBackground,
        borderRadius: "10px",

        fontFamily: 'Nunito Sans',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        color: "orange",
        width: "100%",

        "&:disabled": {
            border: "none",
            background: "blue",
            color: "#0E1F4D",
        },

        "&:hover:not(:disabled)": {
            padding: "2.5px",
            border: `1px solid ${hoverBorderColor}`,
            color: "#0E1F4D",
        },

        "&:focus-visible:not(:disabled)": {
            padding: "2.5px",
            background: "white !important",
            border: `1px solid ${borderColor}`,
            color: "#0E1F4D",
        },

        "&:active:not(:disabled)": {
            padding: "2.5px",
            background: "white",
            border: `1px solid ${borderColor}`,
            color: "#0E1F4D",
        }
    }
})

export function commonSelect(height: string = "60px"): CSSProperties {
    return  {
        height: height,
        borderRadius: "10px",
        background: "#EAECF3",
        border: "none",
        fontFamily: 'Nunito Sans',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "20px"
    }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};