import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {ReactComponent as CheckboxIcon} from "../../Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxChecked} from "../../Icons/size24/CheckboxChecked.svg";
import {contractSystemEntity} from "../../../methods/systemEntityValueByKeyword";
import {currentDate, storeClientData} from "../../../methods/SystemEntity";

let StoreClientDataView: any = function StoreClientDataView(props: any) {
    const storeClientDataEntity = contractSystemEntity(props.contract, storeClientData.id) ?? storeClientData

    return <>
        <hr className={"newHR"}/>

        <div className="commonSubtitle" style={{marginTop: "5px"}}>
            Если поставить галочку, то вашим клиентам предоставят возможность самим решать разрешать или нет хранить персональные данные в системе ОкиДоки.
        </div>
        <div className="buttonsContainer" style={{marginTop: "10px"}}>
            <Checkbox
                icon={<CheckboxIcon className="icon"/>}
                checkedIcon={<CheckboxChecked className="icon"/>}
                checked={storeClientDataEntity.value}
                onChange={({target: {checked}}) => {
                    props.addEntities(true, [{...storeClientDataEntity, value: checked}])
                }}
            />
            <div>
                <div className="commonLabel">
                    Хранить данные клиента?
                </div>
            </div>
        </div>

        <div className="buttonsContainer" style={{marginTop: "10px"}}>
            <Checkbox
                icon={<CheckboxIcon className="icon"/>}
                checkedIcon={<CheckboxChecked className="icon"/>}
                checked={!storeClientDataEntity.hidden}
                onChange={({target: {checked}}) => {
                    props.addEntities(true, [{...storeClientDataEntity, hidden: !checked, editable: checked}])
                }}
            />
            <div>
                <div className="commonLabel">
                    Дать возможность клиенту выбрать самому?
                </div>
            </div>
        </div>
    </>
}

StoreClientDataView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(StoreClientDataView)

export default StoreClientDataView
