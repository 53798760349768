
export function firstSignerTitle(contract: any): string {
    if (!contract) {
        return 'Исполнителя'
    }
    return unwrap(contract.settings?.first_signer_title) ?? unwrap(contract.first_signer_title) ?? 'Исполнителя'
}

export function targetUserTitle(contract: any): string {
    if (!contract) {
        return 'Клиента'
    }
    return unwrap(contract.settings?.target_user_title) ?? unwrap(contract.target_user_title) ?? 'Клиента'
}

function unwrap(value: string | undefined): string | undefined {
    if (!value) {
        return
    }
    if (value.length == 0) {
        return
    }

    return value
}