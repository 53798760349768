import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {connect} from "react-redux";
import {ReactComponent as RadioChecked} from "../../Icons/size24/RadioChecked.svg";
import {ReactComponent as RadioUnchecked} from "../../Icons/size24/RadioUnchecked.svg";

export type ChoosePassportTypeProps = {
    allowNotSelectedOptions?: boolean
    allowedPassportTypes: string[],
    client_passport_type: string,
    onChange: Function
}

export let ChoosePassportTypeForm: any = function ChoosePassportTypeForm({form}: {form: ChoosePassportTypeProps}) {
    const passportTypes = form.allowNotSelectedOptions ? [""].concat(form.allowedPassportTypes) : form.allowedPassportTypes

    return <div>
        <RadioGroup
            defaultValue={form.client_passport_type}
            onChange={(e, value) => {
                form.onChange(value)
            }}
        >
            {
                passportTypes.map((passport_type: string) =>
                    <FormControlLabel
                        key={passport_type}
                        value={passport_type}
                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                        label={passport_type.length ? passport_type : "Не выбрано"}
                    />
                )
            }
        </RadioGroup>
    </div>
}

ChoosePassportTypeForm = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(ChoosePassportTypeForm)
