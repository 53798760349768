import {httpClient} from "../funcs";
import {useLocalStorage} from "./useLocalStorage";

const [get, set, remove] = useLocalStorage("access_token")

export function saveToken(access_token: string) {
    httpClient.defaults.headers['Authorization'] = `Bearer ${access_token}`
    set(access_token)
}

export function getToken(): string | null {
    return get()
}

export function removeToken() {
    console.log("removeToken")
    delete httpClient.defaults.headers['Authorization']
    remove()
}

export function loadToken() {
    let token = getToken()
    if (token) {
        saveToken(token)
    }
}

