import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useRoute } from "react-router5"

import {httpClient, isSMSAllowedForPhoneNumber} from "funcs"
import { validateMany } from "validate"

import cleanPhoneNumber from "../../methods/cleanPhoneNumber"

import imgLogoFull from "images/logo-full.svg"

import "styles/pages/auth.sass"
import {notifyAboutError} from "../../methods/notifyUser";
import {commonTextField} from "../Styles/CommonCSSProperties";
import SelectUserPopup from "./SelectUserPopup";

import { MobileInputMask } from "../../components/elements/InputWithMask"
import {finishAuth} from "../../methods/finishAuth";
import {trackGoal} from "../../methods/trackGoal";
import VerificationCodeView from "./VerificationCodeView";
import {sendVerificationCode} from "../../methods/sendVerificationCode";

let AuthPage: any = function AuthPage(props: any) {

  const $router = useRoute()

  const [authForm, updateAuthForm] = useState({
    $disabled: false,
    $step: 1,
    phoneNumber: '',
    verificationCode: '',
    codeChannel: 'sms'
  })
  const [users, setUsers] = useState<any[]>([])
  const [showSelectUser, setShowSelectUser] = useState(false)

  // Auth user function
  async function auth() {

    // Disallow sending form again
    updateAuthForm({
      ...authForm,
      $disabled: true
    })

    try {

      // Fetch data from authForm
      let { phoneNumber, $step, verificationCode } = authForm

      // Prepare data
      phoneNumber = cleanPhoneNumber(phoneNumber)

      // Send verification code
      if ($step === 1) {

        // Validate input data
        const validationErrors = validateMany([
          { rule: "severalPhoneNumbers", entity: phoneNumber, displayFieldText: 'Номер телефона' }
        ])
        if (validationErrors.length)
          throw new Error(validationErrors.join("\n"))

        let codeChannel = isSMSAllowedForPhoneNumber(phoneNumber) ? 'sms' : 'call'
        if (!await sendVerificationCode(props, phoneNumber, codeChannel)) {
          return
        }

        // Go to next step
        setTimeout(() => {
          updateAuthForm({
            ...authForm,
            codeChannel: codeChannel,
            $step: 2
          })
        }, 0)

      }
      else if ($step === 2) {

        // Validate input data
        const validationErrors = validateMany([
          { rule: "verificationCode", entity: verificationCode, displayFieldText: 'Проверочный код' }
        ])
        if (validationErrors.length)
          throw new Error(validationErrors.join("\n"))

        // Perform authorization
        let { data } = await httpClient.get('/auth_v2', {
          params: {
            phone_number: phoneNumber,
            verification_code: verificationCode,
          }
        })
        trackGoal("auth_successed")
        if (data.length > 1) {
          setUsers(data)
          setShowSelectUser(true)
        } else {
          await finishAuth(props, $router, data[0].access_token)
        }
      }
    }

    catch (error) {
      trackGoal("auth_error", { error: error })
      notifyAboutError(props, error)
    }
    finally {

      // Allow sending form again
      updateAuthForm({
        ...authForm,
        $disabled: false
      })
    }
  }

  function verificationCodeView() {
    return <div style={{marginTop: "15px"}}>
      <VerificationCodeView
          verification_code={authForm.verificationCode}
          code_channel={authForm.codeChannel}
          phone_number={authForm.phoneNumber}
          updateVerificationCode={(value: string) => {
            updateAuthForm({ ...authForm, verificationCode: value })
          }}
          updateCodeChannel={(value: string) => {
            updateAuthForm({ ...authForm, codeChannel: value })
          }}
          finish={ async () => {
            trackGoal("auth_request_code")
            await auth()
          }}
          back={() => {
            updateAuthForm({...authForm, $step: 1})
          }}
          $store={props.$store}
      />
    </div>
  }

  // Render
  return <>
    <img src={imgLogoFull} alt="OkiDoki" className="logo" />
    <div className="_vc AuthPage">

      <div className="H2_26" style={{textAlign: "center"}}>
        Войти в свой аккаунт
      </div>

      {/* Auth popup step 1 */}

      {
          authForm.$step === 1 && <>
            <div className="center">
              <div className="hint" style={{marginTop: '10px'}}>
                Введите свой номер телефона<br />для авторизации в приложении
              </div>
            </div>

            <form onSubmit={(e) => { e.preventDefault(); auth() }}>
              <div className="form-field">
                <MobileInputMask
                    ref={null}
                    style={{...commonTextField(), height: "45px"}}
                    disabled={false}
                    value={authForm.phoneNumber}
                    onChange={(value: string) => updateAuthForm({...authForm, phoneNumber: value})}
                />
              </div>
              <div className="form-field">
                <button className="new withTitle _wa" disabled={authForm.$disabled}>Войти в свой аккаунт</button>
              </div>
            </form>

            <div className="form-field" style={{marginTop: '30px'}}>
              <div className="commonLabel" style={{textAlign: "center"}}>
                Первый раз в ОкиДоки?
              </div>
              <button
                  className="new withTitle _wa"
                  style={{marginTop: "10px"}}
                  onClick={() => {
                    trackGoal("auth_go_to_register")
                    setTimeout(() => $router.router.navigate('register'), 0)
                  }}
              >
                Зарегистрироваться!
              </button>

            </div>

            <div className="commonSubtitle" style={{textAlign: "center", marginTop: "30px"}}>
              При входе и регистрации вы принимаете условия <a href="/confidential_policy.pdf" target="_blank">Политики конфиденциальности</a> и принимаете условия <a href="/agreement.pdf" target="_blank">Соглашения</a>
            </div>
          </>
      }

      {/* Auth popup step 2 */}

      {
          authForm.$step === 2 &&
          verificationCodeView()
      }
      {
          showSelectUser &&
          <SelectUserPopup
              users={users}
              selectUser={async (user: any) => {
                await finishAuth(props, $router, user.access_token)
              }}
          />
      }
    </div>
  </>
}

AuthPage = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(AuthPage)

export default AuthPage
