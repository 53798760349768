import React, {useEffect, useState} from 'react';
import useDebounce from '../useDebounce';

/**
 *
 * @param debouncedValue
 * @param name - template.name
 * @param isChanging ['false','saving','saved']
 * @param setIsChanging React.Dispatch<any> for debouncing value
 * @param updateTemplate function to update template
 *
 * @description Checking debounced value and updating if need so
 */

export default function useUpdateTemplate(template: any, updateTemplate: Function, ms: number = 1500) {
    const [isChanging, setIsChanging] = useState<string>('false')
    const debouncedValue = useDebounce(template, ms);
    const [needToSetFalse, setNeedToSetFalse] = useState(false)
    useEffect(
        () => {
            let update = async () => {
                if (debouncedValue) {
                    // console.log("update")
                    await updateTemplate(false);
                }
            }

            isChanging !== 'false' && isChanging !== 'saved' && template.name !== "" ? update() : null;

        },
        [debouncedValue]
    );

    useEffect(
        ()=> {
            // console.log("setIsChanging", isChanging)
            if(isChanging === 'saved'){
                setTimeout(() => {
                    setNeedToSetFalse(true)
                }, 2000)
            }
        },
        [isChanging]
    )

    useEffect(
        ()=> {
            if(isChanging === 'saved'){
                // console.log("setIsChanging false")
                setIsChanging('false')
                setNeedToSetFalse(false)
            }
        },
        [needToSetFalse]
    )

    return [isChanging, setIsChanging] as const
}