import Popup from "../../components/popup";
import {connect} from "react-redux";
import "pages/Styles/TemplatesCommon.sass"
import {useMediaQuery} from "@mui/material";
import React from "react";
import {ReactComponent as Close} from "pages/Icons/size24/Close.svg"
import {CalendarMonth, ExpandMore, School} from "@mui/icons-material";
import WelcomeView from "../Profile/WelcomeView";
import SectionHeader from "../CreateContract/SectionHeader";

export let TemplatesHelpPopup: any = function TemplatesHelpPopup(props: any) {
    const mobile = useMediaQuery("(max-width: 760px)");

    return <Popup
        style={{zIndex: 999}}
        onClose={props.onClose}
    >
        <WelcomeView $store={props.$store}/>
    </Popup>
}

export let TemplatesHelpView: any = function TemplatesHelpPopup(props: any) {
    return <HelpView
        title={"Что нужно сделать, чтобы подписать и отправить договор?"}
        subtitle={"Для начала вам нужен шаблон договора. Если у вас нет готового шаблона - то можете загрузить любой .docx файл (MS Word).\n" +
            "Посмотрите короткое видео о том, как это делается."}
        videoId={"pd8JAKGcD00"}
    />
}

export let TemplateGuideView: any = function TemplateGuideView(props: {template: any, toggleExpanded: Function}) {
    const mobile = useMediaQuery("(max-width: 760px)");

    let template = props.template
    if (!template) {
        return
    }

    let guides = template.guides ?? []
    let guide = guides.filter((guide: any) => guide.device == (mobile ? "mobile" : "desk"))[0] ??
        guides.filter((guide: any) => guide.device == "any")[0]

    if (!guide) {
        return
    }

    function helpView() {
        return <HelpView
            subtitle={guide.text}
            videoId={guide.video_id}
        />
    }

    return <SectionHeader
        sectionId={guide}
        title={guide.title}
        expanded={!guide.closed}
        toggleSectionExpanded={props.toggleExpanded}
        icon={<School sx={{fill: "#75809E"}}/>}
        body={helpView()}
    />
}

export let ContractHelpPopup: any = function ContractHelpPopup(props: any) {
    const mobile = useMediaQuery("(max-width: 760px)");

    return <Popup
        style={{zIndex: 999}}
        onClose={props.onClose}
    >
        <ContractHelpView/>
    </Popup>
}

export let ContractHelpView: any = function ContractHelpView(props: any) {
    return <HelpView
        title={"Что нужно сделать, чтобы подписать договор?"}
        subtitle={
            <div>
                Вначале важно прочитать договор. Как только убедитесь, что все с договором в порядке и нет ошибок, жмите кнопку "Заполнить".
                <br/>
                <br/>
                Затем заполняете все нужные данные и нажимаете кнопку "Подписать"
                <br/>
                <br/>
                Последний штрих - введите код из SMS, и вуаля! Договор подписан!
                <br/>
                <br/>
                Если хотите узнать, как это все делается, посмотрите короткое видео. Там все понятно покажут!
                <br/>
                <br/>
            </div>

        }
        videoId={"686LW9zbfH0"}
    />
}

type Props = {
    title: string,
    subtitle: any,
    videoId: string,
    close: Function,
    expand: Function
}

export let HelpView: any = function HelpView(props: Props) {
    return <div>
        <div className="titleContainer">
            <div className="commonLabel" style={{textAlign: "center"}}>
                {props.title}
            </div>
            {
                props.close &&
                <div style={{marginBottom: "auto"}}>
                    <button
                        className="new smallIconed close-button"
                        onClick={() => props.close()}
                    >
                        <Close className="icon" style={{stroke: "#75809E"}} />
                    </button>
                </div>
            }
            {
                props.expand &&
                <div style={{marginBottom: "auto"}}>
                    <button
                        className="new smallIconed close-button"
                        onClick={() => props.expand()}
                    >
                        <ExpandMore className="icon" style={{fill: "#75809E"}} />
                    </button>
                </div>
            }
        </div>

        {
            props.subtitle &&
            <div className="commonSubtitle" style={{marginTop: "25px", whiteSpace: "pre-line"}}>
                {props.subtitle}
            </div>
        }

        {
            props.videoId &&
            <div className="video-responsive">
                <iframe
                    width="480"
                    height="480"
                    src={`https://www.youtube.com/embed/${props.videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        }
    </div>
}