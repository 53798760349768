import {useRoute, BaseLink} from 'react-router5'
import React from 'react'
import classNames from 'classnames';
import {ReactComponent as Contract} from "pages/Icons/size24/Contract.svg";
import {ReactComponent as ContractsSigned} from "pages/Icons/size24/ContractSigned.svg";
import {ReactComponent as Profile} from "pages/Icons/size24/Profile.svg";
import {httpClient, nError} from "../../funcs";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";

function MobileFooter(props: any) {
    let $router = useRoute();
    const [active, setActive] = React.useState<string>($router.route.name)

    React.useEffect(() => {
        setActive($router.route.name)
    },[$router])

    return (
        <footer className="footer">
            <div>
                <BaseLink className={classNames({active: active === 'new-contract'})} router={$router.router} routeName="new-contract" routeParams={{act: 'list'}}>
                    <Contract/>
                    <p>Шаблоны</p>
                </BaseLink >
            </div>
            <div>
                <BaseLink router={$router.router} className={classNames({active: active === 'signed-contracts' || active === 'contract'})} routeName="signed-contracts">
                    <ContractsSigned/>
                    <p>Выставленные</p>
                </BaseLink>
            </div>
            <div>
                <BaseLink router={$router.router} routeName="profile"  className={classNames({active: active === 'profile'})} >
                    <Profile/>
                    <p>Профиль</p>
                </BaseLink>
            </div>
        </footer>
    )
}

export default MobileFooter