import React from 'react'

import { CircularProgress } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import classnames from "classnames"

import "styles/components/popup.sass"
import { httpClient } from 'funcs'
import { useRoute } from 'react-router5'

import StoreProps from 'utils/types/storeProps';

interface Props extends StoreProps {
  setPaymentNotification: Function,
  transactionId: string,
  success: any,
}


let PaymentStatus: any = ({setPaymentNotification, transactionId, success}: Props) => {
  const $router = useRoute();
  
  const [confirmed, setConfirmed] = React.useState<string>("")
  const [balance, setBalance] = React.useState<any>({})

  React.useEffect(()=> { 

      httpClient.get(`get-transaction-info/${transactionId}`).then((res)=> {   
        setConfirmed(res.data.status);
        setBalance(res.data)
      }).catch(err => {
        console.log(err)
      })        
   

  }, [])


  


  return (
    <div className={classnames('popup')} style={{}} >
      <div className="wrapper">
      
      <div className="container center">
        <h2 style={{marginBottom: '12px'}}>{success === "true" ? "Оплачено" : "Не получилось оплатить"}</h2>
        {confirmed == "CONFIRMED" && <CheckCircleTwoToneIcon fontSize="large" sx={{color: "#386641", alignSelf: "center", fontSize: 70, margin: '2px'}}/>}
        {success === "false" ? <HighlightOffTwoToneIcon sx={{ fontSize: 70, color: "#d62828", alignSelf: "center" }}/> : null}
        {success === "true" ? 
        <div>
          <div className='confirmation'>
           {confirmed !== "CONFIRMED" && 
            <> 
              <p> Идёт подтверждение, подождите... </p>
              <CircularProgress sx={{color: "#0E1F4D", alignSelf: "center", fontSize: 70}} />
            </>
          }

            {
              confirmed == "CONFIRMED" && balance ?
              <> 

                <div>
                     <h4> Ваш баланс: {balance.balance} договоров</h4>
                    <hr style={{width: "100%"}}/>
                    <div>
                      <p style={{fontWeight: 'bold'}}>Информация о покупке</p>
                      <p >Цена: {balance.price/100} рублей</p>

                      <p>Вы приобрели: {balance.added_amount} договоров</p>
                    </div>
                
                     
                </div>
                
              </> : null
            }
          </div>
        </div>: null}

        {success === "false" ?
          <div className='confirmation'>
            <p>Не удалось произвести оплату, попробуйте ещё раз</p> 
          </div>
        : null}

      <button onClick={() => {setPaymentNotification(false); $router.router.navigate(`profile`)}}>
         Вернуться в профиль
      </button>
      </div>


    </div>
  </div>
  )
}


export default PaymentStatus


