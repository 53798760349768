import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useRoute} from "react-router5";
import {connect} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {clientRoleTitle, clientRoles} from "../../../Auth/UserForms/ChooseAccountTypeFrom";
import {ReactComponent as CheckboxIcon} from "pages/Icons/size24/Checkbox.svg"
import {ReactComponent as CheckboxChecked} from "pages/Icons/size24/CheckboxChecked.svg"
import {ReactComponent as CheckboxDisabledChecked} from "pages/Icons/size24/CheckboxDisabledChecked.svg"
import {contractSystemEntity} from "../../../../methods/systemEntityValueByKeyword";
import {
    clientPassportNumber,
    customerAllEntities,
    customerPersonalEntities,
    entrepreneurAllEntities,
    entrepreneurPersonalEntities
} from "../../../../methods/SystemEntity";

let ChooseTargetUserRoleView: any = function ChooseTargetUserAccountTypeView(props: any) {
    const contract = props.contract
    const entity = props.entity

    function checkboxDisabled(role: string) {
        if (entity.editable) {
            return false
        }

        return entity.value == role
    }
    
    function processChecked(checked: boolean, index: number) {
        if (checked) {
            let contractEntities = entrepreneurAllEntities.map(
                (entity) => {
                    let contractEntity = contractSystemEntity(contract, entity.id) ?? entity
                    return {...contractEntity, hidden: false}
                }
            ).concat([{...entity, editable: true, value: ""}])
            props.addEntities(true, contractEntities)
        } else {
            let entitiesToHide = (index == 0 ? entrepreneurAllEntities : customerPersonalEntities).map(
                (entity) => {
                    let contractEntity = contractSystemEntity(contract, entity.id) ?? entity
                    return {...contractEntity, hidden: true}
                }
            )
            let entitiesToMakeVisible = (index == 0 ? customerPersonalEntities : entrepreneurAllEntities).map(
                (entity) => {
                    let contractEntity = contractSystemEntity(contract, entity.id) ?? entity
                    return {...contractEntity, hidden: false}
                }
            )

            let entitiesToUpdate = entitiesToHide
                .concat(entitiesToMakeVisible)
                .concat([{...entity, editable: false, value: clientRoles[index == 1 ? 0 : 1]}])

            props.addEntities(true, entitiesToUpdate)
        }
    }

    return <>
        <div>
            <div className="commonLabel" style={{marginTop: "25px"}}>
                Кто может подписывать этот договор
            </div>
            {
                clientRoles.map((role: string, index) => {
                    return <div
                        className="buttonsContainer"
                        style={{marginLeft: "-10px"}}
                    >
                        <Checkbox
                            disabled={checkboxDisabled(role)}
                            icon={<CheckboxIcon className="icon"/>}
                            checkedIcon={checkboxDisabled(role) ? <CheckboxDisabledChecked className="icon"/> : <CheckboxChecked className="icon"/>}
                            checked={(entity.value == role && !entity.editable) || entity.editable}
                            onChange={({target: {checked}}) => {
                                processChecked(checked, index)
                            }}
                        />
                        <div className="commonLabel">
                            {clientRoleTitle(role)}
                        </div>
                    </div>
                })
            }
        </div>
    </>
}

ChooseTargetUserRoleView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ChooseTargetUserRoleView)

export default ChooseTargetUserRoleView
