import {nError} from "../funcs";

export function notifyUser(props: any, title: string, content: string = '') {
    props.$commitToStore({
        notification: {
            title: title,
            content: content
        }
    })
}

export function notifyAboutError(props: any, error: any) {
    const e = nError(error)
    props.$commitToStore({
        notification: {
            title: e.notification.title,
            content: e.notification.content as string
        }
    })
}

export function capitalize(str: string) {
    if (str.length == 0) {
        return str
    }
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export default notifyUser
