import {useRoute} from "react-router5";
import React, {useState} from "react";
import {Menu, TextareaAutosize, useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import "pages/Styles/TemplatesCommon.sass"
import "pages/Styles/EditContractCommon.sass"
import {ReactComponent as Close} from "../Icons/size24/Close.svg";
import {ReactComponent as Search} from "../Icons/size24/Search.svg";
import {
    commonSelect,
    commonTextField,
    MenuProps,
    TextareaStyled
} from "../Styles/CommonCSSProperties";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {TemplatesHelpPopup} from "./TemplatesHelpPopup";
import {ReactComponent as Actions} from "../Icons/size24/ActionsHorizontal.svg";
import {ReactComponent as Preview} from "../Icons/size24/Preview.svg";
import {Flow} from "../Contracts/ContractActionsButton";
import {flow} from "lodash";
import {PictureAsPdf, PostAdd, QuestionMark} from "@mui/icons-material";
import makeTemplateDownloadLink from "../../methods/makeTemplateDownloadLink";
import MenuButton from "../../methods/MenuButton";

type Props = {
    addTemplate: Function,
    search: Function,
    signPDF: Function,
}

let TemplatesHeader: any = function TemplatesHeader(props: Props) {
    const $router = useRoute();
    const {addTemplate, search, signPDF} = props

    const [showSearch, setShowSearch] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const matches = useMediaQuery("(min-width: 760px)");

    const sortType = [
        {
            title: "По дате последнего изменения",
            keyword: "updated_at",
        },
    ]
    const [selectedFilter, setSelectedFilter] = useState(sortType[0].title)

    function mobileButtonsContainer() {
        return <div className="navbar" style={{padding: "20px 15px 15px 15px"}}>
            <div>
                {
                    showSearch
                        ? <div className="buttonsContainer _wa">
                            {
                                makeSearch()
                            }
                            <button
                                className="new bigIconed"
                                onClick={() => {
                                    search("")
                                    setShowSearch(false)
                                }}
                            >
                                <Close className="icon" style={{stroke: "#75809E"}} />
                            </button>
                        </div>
                        : <div className="titleContainer _wa" >
                            <div className="buttonsContainer _wa">
                                <button
                                    className="new bigIconed"
                                    onClick={() => {
                                        setShowSearch(true)
                                    }}
                                >
                                    <div>
                                        <Search className="icon"/>
                                    </div>
                                </button>
                                <button
                                    className="new bigIconed"
                                    onClick={() => {
                                        setShowHelp(true)
                                    }}
                                >
                                    <QuestionMark className={"icon dark"}/>
                                </button>
                            </div>

                            {
                                mainButtons()
                            }
                        </div>
                }
            </div>

            {/*{makeFilter({marginTop: "15px", width: "100%"})}*/}
        </div>
    }

    function desktopButtonsContainer() {
        return <div className="titleContainer">
            {makeSearch()}

            {/*{makeFilter({ maxWidth: "300px"})}*/}

            {mainButtons()}
        </div>
    }

    function mainButtons() {
        return <div className="buttonsContainer">
            <button className="new withTitle" onClick={() => {
                signPDF()
            }}>
                {
                    matches ? "Подписать PDF" : "PDF"
                }

            </button>

            <button
                className="new withTitle"
                onClick={() => {
                    addTemplate()
                }}
            >
                Шаблоны
            </button>
        </div>
    }

    function makeSearch() {
        return <TextareaStyled
            padding={12}
            placeholder={"Поиск"}
            style={{
                maxWidth: "382px"
            }}
            onChange={({target: {value}}: any) => {
                search(value)
            }}
            onResize={undefined}
            onResizeCapture={undefined}
        />
    }
    function makeFilter(sx: SxProps<Theme>) {
        return <FormControl sx={sx}>
            <Select
                labelId="contract-type-label"
                id="contract-type"
                className="classicStyleMuiSelect"
                value={selectedFilter}
                onChange={(event: SelectChangeEvent) => {
                    const {target} = event
                    setSelectedFilter(target.value)
                }}
                MenuProps={MenuProps}
                sx={commonSelect("45px")}
            >
                {sortType.map((filter) => (
                    <MenuItem key={filter.keyword} value={filter.title}>
                        <ListItemText primary={filter.title}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    }

    return <div>
        {
            matches ? desktopButtonsContainer() : mobileButtonsContainer()
        }
        {
            showHelp &&
            <TemplatesHelpPopup onClose={() => {setShowHelp(false)}}/>
        }
    </div>
}

TemplatesHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(TemplatesHeader)

export default TemplatesHeader
