import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {connect} from "react-redux";
import ChooseWhoCanFillView from "./Views/ChooseWhoCanFillView";
import {contractSystemEntity, contractSystemEntityIsHidden} from "../../../methods/systemEntityValueByKeyword";
import {
    bulkUpdateSystemEntities, clientFIO,
    clientFirstName, customerPersonalEntities,
} from "../../../methods/SystemEntity";
import EntityCopyButton from "./Views/EntityCopyButton";
import RequestedEntityView from "./Views/RequestedEntityView";

let CustomerPersonalDataView: any = function CustomerPerosnalDataView(props: any) {
    const contract = props.contract
    function copyButton() {
        return <EntityCopyButton keyword={clientFIO.keyword}/>
    }

    function disabledEntityView(title: string, subtitle: string | null = null) {
        return <RequestedEntityView
            title={title}
            subtitle={subtitle}
            disabled={true}
            checked={true}
        />
    }

    return <>
        <div style={{marginTop: "15px"}}>
            <ChooseWhoCanFillView
                editable={(contractSystemEntity(contract, clientFirstName.id) ?? clientFirstName).editable}
                updateEditable={(editable: boolean) => {
                    let entitiesToUpdate = bulkUpdateSystemEntities(contract, customerPersonalEntities, "editable", editable)
                    props.addEntities(true, entitiesToUpdate)
                }}
            />
        </div>

        <div className={"titleContainer"}>
            {disabledEntityView("ФИО клиента", "Обязательно для заполнения")}
            {copyButton()}
        </div>
    </>
}

CustomerPersonalDataView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(CustomerPersonalDataView)

export default CustomerPersonalDataView