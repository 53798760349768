import {ReactComponent as Actions} from "../Icons/size24/ActionsHorizontal.svg";

import {connect} from "react-redux";
import React, {useState} from "react";
import {ReactComponent as Close} from "../Icons/size24/Close.svg";
import {ReactComponent as Delete} from "../Icons/size24/Delete.svg";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {ReactComponent as Copy} from "../Icons/size24/Copy.svg";
import downloadContract from "../../utils/funcs/downloadContract";
import {ReactComponent as DownloadLight} from "../Icons/size24/DownloadLight.svg";
import DeclineContractPopup from "./DeclineContractPopup";
import DeleteContractPopup from "./DeleteContractPopup";
import {Tooltip} from "@mui/material";
import {ReactComponent as DownloadDark} from "../Icons/size24/DownloadDark.svg";
import {contractStatus, ContractStatus} from "../../methods/ContractStatus";
import {contractLink} from "../../utils/funcs/getContractLink";
import MenuButton from "../../methods/MenuButton";
import {httpClient} from "../../funcs";
import {useRoute} from "react-router5";
import {Queue} from "@mui/icons-material";
import {firstSignerTitle, targetUserTitle} from "../../methods/workWithContract";

export enum Flow {
    InListSelect = 1,
    InDetailsSelect,
    InDetailsButtons
}

type Props = {
    flow: Flow
    contract: any,
    isEditing: boolean,
    reload: Function,
    $store: any,
    $commitToStore: Function,
}

export let ContractActionsButton: any = function ContractActionsButton(props: Props) {
    const $router = useRoute()

    const {flow, contract, isEditing, reload, $store, $commitToStore} = props

    const [deletePopup, setDeletePopup] = useState(false);
    const [declinePopup, setDeclinePopup] = useState(false);

    function canDelete() {
        return !isEditing &&
            contractStatus(contract) != ContractStatus.Signed &&
            contract.created_by._id == props.$store.userSession?._id
    }

    function canCancel(): boolean {
        let userSession = props.$store.userSession
        if (!contract) {
            return false
        }
        
        let status = contractStatus(contract)
        if (!userSession) {
            return status == ContractStatus.Awaiting
        }

        if (isEditing) {
            return false
        }

        switch (status) {
            case ContractStatus.Draft:
            case ContractStatus.Declined:
            case ContractStatus.Canceled:
                return false


            case ContractStatus.AwaitingConfirmation:
            case ContractStatus.Signed:
                let is_first_signer_is_me = contract.first_signer?._id == userSession._id
                let is_creator_is_me = contract.created_by?._id == userSession._id

                return is_first_signer_is_me || is_creator_is_me
            case ContractStatus.Awaiting:
                return true
        }
        return false
    }

    function canDownload() {
        if (!contract) {
            return false
        }

        if (!contract.body || contract.body.length == 0) {
            return false
        }

        let status = contractStatus(contract)
        if (!status) {
            return false
        }

        let userSession = props.$store.userSession

        if (!userSession) {
            return status == ContractStatus.Awaiting
        }

        let is_creator_is_me = contract.created_by?._id == userSession._id
        let is_first_signer_is_me = contract.first_signer?._id == userSession._id
        let is_target_user_is_me = contract.target_user?._id == userSession._id

        // @ts-ignore
        switch (status) {
            case ContractStatus.Draft:
            case ContractStatus.Declined:
            case ContractStatus.Canceled:
            case ContractStatus.AwaitingConfirmation:
                return is_first_signer_is_me || is_creator_is_me

            case ContractStatus.Signed:
                return is_first_signer_is_me || is_creator_is_me || is_target_user_is_me

            case ContractStatus.Awaiting:
                return true
        }
        return false
    }

    function canDublicate() {
        let userSession = props.$store.userSession

        if (!userSession) {
            return false
        }

        let is_creator_is_me = contract.created_by?._id == userSession._id
        if (!is_creator_is_me) {
            return false
        }

        if (contract.body_type == 'pdf') {
            return false
        }

        return true
    }

    function shouldAllowCopyLinkForFirstSigner(): boolean {
        return !(contract.first_signer && contract.first_signer._id == contract.created_by._id);
    }

    function copyTooltipButton() {
        if (shouldAllowCopyLinkForFirstSigner()) {
            return  <Tooltip arrow={true} placement={"top"} title={"Скопировать ссылку для.."}>
                {
                    makeMenuForCopyLink()
                }
            </Tooltip>
        } else {
            return <Tooltip arrow={true} title={"Скопировать ссылку на договор"}>
                <button className="new bigIconed" onClick={(e) => {
                    navigator.clipboard.writeText(contractLink(contract._id))
                        .then(() => {
                            notifyUser(props, 'Ссылка скопирована', 'Теперь можете отправить ее вашему клиенту')
                        })
                }}>
                    <Copy className="icon dark"/>
                </button>
            </Tooltip>
        }
    }

    function copyForFirstSignerMenuItem() {
        return {
            icon: <Copy className="icon light"/>,
            title: `Скопировать ссылку для ${firstSignerTitle(contract)}`,
            onTap: () => {
                notifyUser(props, 'Ссылка скопирована', 'Теперь можете отправить ее первому подписанту')
            },
            isCopyToClipboard: true,
            copyText: contractLink(contract._id, true)
        }
    }

    function copyForSecondSignerMenuItem() {
        return {
            icon: <Copy className="icon light"/>,
            title: `Скопировать ссылку для ${targetUserTitle(contract)}`,
            onTap: () => {
                notifyUser(props, 'Ссылка скопирована', `Теперь можете отправить ее ${shouldAllowCopyLinkForFirstSigner() ? 'второму подписанту' : 'вашему клиенту'}`)
            },
            isCopyToClipboard: true,
            copyText: contractLink(contract._id)
        }
    }

    function makeMenuForCopyLink() {
        return <div>
            <MenuButton
                className={flow == Flow.InListSelect ? "new smallIconed" : "new bigIconed"}
                items={[
                    copyForFirstSignerMenuItem(),
                    copyForSecondSignerMenuItem()
                ]}
            >
                <Copy className="icon dark"/>
            </MenuButton>
        </div>
    }

    function declineContractButtonTitle() {
        return contractStatus(contract) == ContractStatus.Signed
            ? "Расторгнуть договор"
            : "Отклонить договор"
    }

    async function dublicateContract() {
        try {
            let result: any = await httpClient.post(`/contract/${contract._id}`)
            $router.router.navigate(
                'contract',
                {
                    contractId: result.data.contract_id
                }
            )
            location.reload()
        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    function makeSelect() {
        return <div>
            <MenuButton
                className={flow == Flow.InListSelect ? "new smallIconed" : "new bigIconed"}
                items={[
                    canCancel() ? {
                        icon: <Close className="smallIcon" style={{stroke: "#D4D8E1"}} />,
                        title: declineContractButtonTitle(),
                        onTap: () => {
                            setDeclinePopup(true)
                        }
                    } : null,
                    canDelete() ? {
                        icon: <Delete className="icon light"/>,
                        title: "Удалить договор",
                        onTap: () => {
                            setDeletePopup(true)
                        }
                    } : null,
                    shouldAllowCopyLinkForFirstSigner() ? copyForFirstSignerMenuItem() : null,
                    copyForSecondSignerMenuItem(),
                    canDownload() ? {
                        icon: <DownloadLight className="icon"/>,
                        title: "Скачать договор",
                        onTap: () => {
                            downloadContract(contract)
                        }
                    } : null,
                    canDublicate() ? {
                        icon: <Queue className="icon light"/>,
                        title: "Дублировать договор",
                        onTap: async () => {
                            await dublicateContract()
                        }
                    } : null
                ]}
            >
                <Actions className="icon dark" />
            </MenuButton>
        </div>
    }

    function makeButtonsContainer() {
        return <div className="buttonsContainer">
            {
                canCancel() &&
                <Tooltip arrow={true} title={declineContractButtonTitle()}>
                    <button className="new bigIconed" onClick={() => {setDeclinePopup(true)}}>
                        <Close className="icon" style={{stroke: "#75809E"}} />
                    </button>
                </Tooltip>
            }

            {
                canDelete() &&
                <Tooltip arrow={true} title={"Удалить договор"}>
                    <button className="new bigIconed" onClick={() => {
                        setDeletePopup(true)
                    }}>
                        <Delete className="icon dark"/>
                    </button>
                </Tooltip>
            }

            {
                copyTooltipButton()
            }

            {
                canDownload() &&
                <Tooltip arrow={true} title={"Скачать договор"}>
                    <button
                        className="new bigIconed"
                        onClick={(e) => {
                            e.stopPropagation()
                            downloadContract(contract)
                        }}
                    >
                        <DownloadDark className="icon"/>
                    </button>
                </Tooltip>
            }
            {
                canDublicate() &&
                <Tooltip arrow={true} title={"Дублировать договор"}>
                    <button
                        className="new bigIconed"
                        onClick={(e) => {
                            e.stopPropagation()
                            dublicateContract()
                        }}
                    >
                        <Queue className="icon dark"/>
                    </button>
                </Tooltip>
            }
        </div>
    }

    function make() {
        switch (flow) {
            case Flow.InListSelect:
                return makeSelect()
            case Flow.InDetailsSelect:
                return makeSelect()
            case Flow.InDetailsButtons:
                return makeButtonsContainer()
        }
    }

    return <div>
        {
            make()
        }
        {
            declinePopup && contract._id &&
            <DeclineContractPopup
                reload={reload}
                contractStatus={contractStatus(contract)}
                contractId={contract._id}
                close={() => {
                    setDeclinePopup(false)
                }}
                $commitToStore={$commitToStore}
            />
        }

        {
            deletePopup &&
            <DeleteContractPopup
                reload={reload}
                contractId={contract._id}
                closePopup={() => {
                    setDeletePopup(false)
                }}
                $commitToStore={$commitToStore}
            />
        }
    </div>
}

ContractActionsButton = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(ContractActionsButton)