// import { useRef, useState, useEffect } from "react"
import { connect } from "react-redux"

import { EntityInput } from "../Entity/EntityInput";

import "styles/components/popup.sass"

export const ENTREPRENEUR_STAMP_KEY = "organisation_stamp"
const MAX_WIDTH_OR_HEIGHT = 300;


let UserStamp: any = function UserStamp(inputState: any) {
    const [value, setValue] = inputState.inputState;
    const imageElement = (
        <img
            style={{
                height: "auto",
                maxHeight: MAX_WIDTH_OR_HEIGHT,
                width: "auto",
                maxWidth: MAX_WIDTH_OR_HEIGHT,
                marginBottom: "15px"
            }}
            src={value}
        />
    )

    return (
        <>
            {value && imageElement}

            <EntityInput
                entity={{value, type: "Фото"}}
                updateEntity={(value: string) => {
                    setValue(value)
                }}
                setImageStringTag={false}
                maxWidthOrHeight={MAX_WIDTH_OR_HEIGHT}
            />
        </>
    )
}

UserStamp = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
      $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(UserStamp)

export default UserStamp
