import {connect} from "react-redux";
import React from "react";
import {useRoute} from "react-router5";
import {useMediaQuery} from "@mui/material";

import {ReactComponent as Back} from "pages/Icons/size24/Back.svg"

let SharedTemplateHeader: any = function SharedTemplateHeader(props: any) {
    const $router = useRoute();
    const mobile = useMediaQuery("(max-width: 760px)");

    function back() {
        window.history.back()
    }

    function buttonsContainer() {
        return <div className="titleContainer">
            <button
                className="new bigIconed"
                onClick={() => {
                    back()
                }}
            >
                <Back className="icon dark"/>
            </button>

            <button
                className="new withTitle"
                onClick={() => {
                    props.addTemplate()
                }}
            >
                Добавить себе
            </button>
        </div>
    }

    function nameContainer() {
        return <div style={{marginTop: "25px"}}>
            <div className="contractTitle">
                {props.template.name + " "}
            </div>
            <div className="Body_15 comment">
                {props.template.name}
            </div>
        </div>
    }

    return <div>
        {
            buttonsContainer()
        }
        {
            nameContainer()
        }
    </div>
}

SharedTemplateHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(SharedTemplateHeader)

export default SharedTemplateHeader
