import React, {useState} from "react";
import {connect} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard";
import notifyUser from "../../methods/notifyUser";
import {trackGoal} from "../../methods/trackGoal";
import {checkPermission, UserPermission} from "../../methods/permissionByRole";
import {useMediaQuery} from "@mui/material";
import ReferralsView from "./ReferralsView";
import {loadSelf} from "../../methods/loadSelf";

let MyPromoCodeView: any = function MyPromoCodeView(props: any) {
    const {fullScreen} = props
    const mobile = useMediaQuery('(max-width: 760px)')
    const [referralsPopup, setReferralsPopup] = useState(false);

    return <>
        <div className="commonTitle" style={{textAlign: fullScreen ? "center" : "left"}}>
            Ваш промо-код
        </div>

        <div className="commonSubtitle" style={{textAlign: "left"}}>
            Делитесь своим промо-кодом с коллегами, и он получит +30 процентов бонуса к купленному пакету!<br/>
            Ваш баланс будет пополнен на 20 процентов от того, что купил ваш коллега, или ваша подписка будет продлена на эквивалентный период.<br/>
            <br/>
            Так же, у нас действует действительно крутой бонус в виде 50 процентов кэшбека за первую покупку вашего друга! Деньгами! Прямо на вашу карту! 🔥🔥🔥
        </div>
        <div className="buttonsContainer" style={{marginTop: fullScreen ? "25px" : "10px"}}>
            <div className="commonLabel">
                {props.$store.userSession.promo_code?.code_name ?? ''}
            </div>
            <CopyToClipboard
                onCopy={() => {
                    notifyUser(props, 'Ссылка на регистрацию с промо-кодом скопирована', 'Теперь можете поделиться ей')
                }}
                text={`https://desktop.doki.online/register?promoCode=${props.$store.userSession.promo_code?.code_name ?? ''}`}
            >
                <button
                    className="new withTitle small _wa"
                    onClick={() => {
                        if (fullScreen) {
                            trackGoal('user_story_referral_share_promo')
                        }
                        trackGoal('share_link')
                        if ('share' in navigator && mobile) {
                            navigator.share({
                                title: 'Ссылка на регистраци в ОкиДоки с промо-кодом',
                                url: `https://desktop.doki.online/register?promoCode=${props.$store.userSession.promo_code?.code_name ?? ''}`
                            })
                        }}
                    }
                >
                    Поделиться ссылкой
                </button>
            </CopyToClipboard>
        </div>
        {
            checkPermission(props, UserPermission.OWNER) && !fullScreen &&
            <>
                <hr className={"newHR"}/>

                <div className="row-data">
                    <button
                        className={`new withTitle _wa ${fullScreen ? '' : 'small'}`}
                        onClick={() => {
                            trackGoal('show_referral_list')
                            setReferralsPopup(true)
                        }}
                    >
                        Посмотреть приведенных клиентов
                    </button>
                </div>
            </>
        }

        {
            referralsPopup &&
            <ReferralsView
                close={() => {setReferralsPopup(false)}}
                onCollectBonus={loadSelf}
                $store={props.$store}
                $commitToStore={props.$commitToStore}
            />
        }
    </>
}

MyPromoCodeView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(MyPromoCodeView)

export default MyPromoCodeView