import { createStore } from "redux"

const initialState: any = {
  userSession: null,
  notification: null,
  balance: null,
  chosenDirectionTypes: "Исходящие"
}

function rootReducer(state = initialState, payload: any) {
  return {
    ...state,
    ...payload,
  }
}

export default () => {
  return createStore(rootReducer)
}
