import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {ReactComponent as CheckboxIcon} from "pages/Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxChecked} from "pages/Icons/size24/CheckboxChecked.svg";
import {ReactComponent as CheckboxDisabledChecked} from "pages/Icons/size24/CheckboxDisabledChecked.svg"
import {connect} from "react-redux";
import Checkbox from "@mui/material/Checkbox";

let RequestedEntityView: any = function RequestedEntityView(props: any) {
    const {entityId, title, subtitle, disabled, checked, updateChecked} = props

    return <>
        <div className="buttonsContainer" style={{marginTop: "15px"}}>
            <Checkbox
                sx={{padding: "0px"}}
                icon={<CheckboxIcon className="icon"/>}
                disabled={disabled}
                checkedIcon={disabled ? <CheckboxDisabledChecked className="icon"/> : <CheckboxChecked className="icon"/>}
                checked={checked}
                onChange={({target: {checked}}) => {
                    updateChecked(checked, entityId)
                }}
            />
            <div>
                <div className="commonLabel">
                    {title}
                </div>
                {
                    subtitle &&
                    <div className="commonSubtitle">
                        {subtitle}
                    </div>
                }
            </div>
        </div>
    </>
}

RequestedEntityView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(RequestedEntityView)

export default RequestedEntityView
