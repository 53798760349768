import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {useRoute} from "react-router5";
import _ from "lodash";
import ContractNumberAndDateSection from "./ContractNumberAndDateSection";
import UserCardsView from "./UserCardsView";
import ContractOtherSettingsSection from "./ContractOtherSettingsSection";
import FillClientDataView from "./FillClientDataView";
import RequestedClientDataSection from "./ContractRequestedClientData/RequestedClientDataSection";
import SectionHeader from "./SectionHeader";
import {firstSignerTitle, targetUserTitle} from "../../methods/workWithContract";
import {AssignmentInd, CalendarMonth, Checklist, PsychologyAlt, Settings} from "@mui/icons-material";

type Props = {
    contract: any,

    addEntities: Function,
    deleteEntities: Function,

    updateActualUserCardId: Function,
    updateContractSettings: Function,
    $store: any,
    isTemplate: boolean,
    $commitToStore: Function,
}

type ContractInstructions = {
    pre_sign: string,
    post_sign: string
    post_sign_title: string
}

let CreateContractView: any = function SignContractPopup(props: Props) {
    const {contract} = props

    function toggleSettingsSectionExpanded(section: string) {
        let updatedSettings = {...contract.settings}
        let expanded = sectionExpanded(section)
        _.set(updatedSettings, `${section}.expanded`, !expanded)
        props.updateContractSettings(updatedSettings)
    }

    function sectionTitle(section: string) {
        switch (section) {
            case 'user_card':
                return `Ваша подпись`
            case 'contract_number_and_date':
                return "Дата подписи и номер договора"
            case 'requested_client_data':
                return `Запрашиваемые данные у ${targetUserTitle(contract)}`
            case 'fill_client_data':
                return `Заполнить данные за ${targetUserTitle(contract)}`
            case 'other_settings':
                return "Другие настройки"
            default:
                return ''
        }
    }

    function sectionHeader(sectionId: string, element: JSX.Element, icon: JSX.Element) {
        return <SectionHeader
            sectionId={sectionId}
            title={sectionTitle(sectionId)}
            expanded={sectionExpanded(sectionId)}
            toggleSectionExpanded={toggleSettingsSectionExpanded}
            icon={icon}
            body={element}
        />
    }
    function sectionExpanded(section: string) {
        return _.get(contract.settings, `${section}.expanded`, false)
    }

    function userCardSection() {
        return <div style={{marginTop: "25px"}}>
            <UserCardsView
                contract={contract}
                addEntities={props.addEntities}
                updateActualUserCardId={props.updateActualUserCardId}
                $store={props.$store}
            />
        </div>
    }

    function contractNumberAndDateSection() {
        return <ContractNumberAndDateSection
            contract={contract}
            isTemplate={props.isTemplate}
            addEntities={props.addEntities}
        />
    }

    function requestedClientDataSection() {
        return <RequestedClientDataSection
            contract={contract}
            updateContractSettings={props.updateContractSettings}
            addEntities={props.addEntities}
            deleteEntities={props.deleteEntities}
        />
    }

    function fillClientDataSection() {
        return <FillClientDataView
            contract={contract}
            addEntities={props.addEntities}
            $store={props.$store}
        />
    }


    function otherSettingsSection() {
        return <div style={{marginTop: "25px"}}>
            <ContractOtherSettingsSection
                contract={contract}
                updateContractSettings={props.updateContractSettings}
                updateInstructions={(instructions: ContractInstructions) => {
                    props.updateContractSettings({...contract.settings, instructions: instructions})
                }}
                updateFirstSignerTitle={(title: string) => {
                    props.updateContractSettings({...contract.settings, first_signer_title: title})
                }}
                updateTargetUserTitle={(title: string) => {
                    props.updateContractSettings({...contract.settings, target_user_title: title})
                }}
            />
        </div>
    }

    return <div className="sectionsContainer" >
        {sectionHeader("user_card", userCardSection(), <AssignmentInd
            sx={{fill: "#75809E"}}/>)}
        {sectionHeader("contract_number_and_date", contractNumberAndDateSection(), <CalendarMonth sx={{fill: "#75809E"}}/>)}
        {sectionHeader("requested_client_data", requestedClientDataSection(), <PsychologyAlt sx={{fill: "#75809E"}}/>)}
        {sectionHeader("fill_client_data", fillClientDataSection(), <Checklist sx={{fill: "#75809E"}}/>)}
        {sectionHeader("other_settings", otherSettingsSection(), <Settings sx={{fill: "#75809E"}}/>)}
    </div>
}

CreateContractView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(CreateContractView)

export default CreateContractView
