import {httpClient} from "../funcs";
import {notifyAboutError} from "./notifyUser";

export async function sendVerificationCode(props: any, phone_number: string,  code_channel: string) {
    try {
        await httpClient.post('/verification-code', {
            phone_number: phone_number,
            channel: code_channel
        })

        return true
    }
    catch (error) {
        notifyAboutError(props, error)
        throw error
    }
}